import {sort} from "@/constants";

export default {
    tableCode: 'vt_routesbyzone',
    tableName: 'Перечень маршрутов,<br>входящих в зоны действия ВПБ',
    isDoubleLine: true,
    indexField: [
        'zone_id',
		'route'
    ],
    defaultOrder: {
        zone_id: sort.ASC,
    },
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор зоны действия',
            key: 'zone_id',
            column_hidden: () => true,
            skip_input: true,
        },
        {
           type: BigInt,
            name: 'Маршрут',
            key: 'route',
        }, {
            type: String,
            name: 'Примечание',
            key: 'note',
        },
    ],
}
