import {sort} from "@/constants";

export default {
    tableCode: 'vt_errorcode',
    tableName: 'Коды ошибок',
    indexField: [
        'errorcode'
    ],
    defaultOrder: {
        errorcode: sort.ASC,
    },
    viewField: 'errortext',
    folderName: 'vpb_control',
    tableDescription: [
        {
            key: 'errorcode',
            name: 'Код ошибки',
            type: BigInt,
        },
        {
            key: 'errortext',
            name: 'Описание',
            type: String,
        }
    ]
}
