import {sort} from "@/constants";

export default {
    tableCode: 'lcl_closuretypes',
    tableName: 'Типы гашения',
    viewField: 'name',
    indexField: [
        'id',
    ],
    defaultOrder: {
        id: sort.ASC,
    },
    local: true,
    tableDescription: [
        {
            key: 'id',
            type: BigInt,
        },
        {
            key: 'name',
            type: String,
        }
    ],
    tableContent: [
        { id: 1, name: 'Offline' },
        { id: 0, name: 'Online' },
    ]
}
