<template lang="pug">
  el-drawer(
    :title="headerText"
    :visible="opened"
    v-on:update:visible="v => handleModalOpen(v)"
    direction="rtl"
    :before-close="beforeClose"
    custom-class="demo-drawer"
    ref="drawer"
    size='40%'
    v-on:open="modalOpened"
  )

    .demo-drawer__content.content-modal
      el-form
        h3.header__tablename_content {{ tableName }}
        template(v-for="item in structureFiltered")
          template(v-if="item.relation")
            el-form-item(
              :label="item.name + ':'"
              :class="{ small_label: item.small_label, label_padding: true }"
              v-show="!item.input_hidden || item.input_hidden(item, item.key) !== true"
            )
              el-select(
                :value="selectValue(item.key, item)"
                v-on:input="value => updateSelect(item.key, value, item)"
                filterable
                clearable
                :disabled='ro || checkDisable(item)'
                :multiple="item.multiple"
                :placeholder="item.placeholder_input || item.name"
              )
                el-option(
                  v-for="item in selectOptions(item.relation.table, item.relation.filter_by, item.relation.depends_on, item)"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                )
          template(v-else-if="item.type === Array")
            el-form-item(
              :label="item.name + ':'"
              :class="{ small_label: item.small_label, label_padding: true }"
              v-show="!item.input_hidden || item.input_hidden(item, item.key) !== true"
            )
              el-select(
                :value="multipleSelectValue(item.key, item)"
                v-on:input="value => updateMultipleSelect(item.key, value)"
                filterable
                clearable
                :disabled='ro || checkDisable(item)'
                :multiple="typeof item.multiple === 'undefined' ? true : item.multiple"
                :placeholder="item.name"
              )
                el-option(
                  v-for="item in multipleSelectData(item.data_from.table)"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                )
          template(v-else-if="item.type === BigInt")
            el-form-item(
              :label="item.name + ':'"
              :class="{ small_label: item.small_label, label_padding: true }"
              v-show="!item.input_hidden || item.input_hidden(item, item.key) !== true"
            )
              el-input(
                type="string"
                :placeholder="item.name"
                v-on:input="val => insertBigNumber(val, item.key)"
                :value="bigintInputValue(item.key, item)"
                :disabled="ro || checkDisable(item)"
              )

          template(v-else-if="item.type === Number")
            el-form-item(:label="item.name + ':'" :class="{ small_label: item.small_label, label_padding: true }")
              el-input(
                type="number"
                :placeholder="item.name"
                v-model="targetData[item.key]"
                :disabled="ro || checkDisable(item)"
              )

          template(v-else-if="item.type === Date")
            el-form-item.date_input(:label="item.name + ':'" :class="{ small_label: item.small_label, label_padding: true }")
              el-date-picker(
                :value='dateValue(item.key, item)'
                v-on:input='val => insertDate(item.key, val)'
                type="datetime"
                :disabled="ro || checkDisable(item)"
                :picker-options="datePickerOptions(item)"
              )

          template(v-else-if="item.type === String")
            //span.password(v-if="item.password && mode === 'edit'") Оставьте поле пустым чтобы не менять пароль
            el-form-item(:label="item.name + ':'" :class="{ small_label: item.small_label, label_padding: true }")
              el-button.generator__btn(
                v-if="item.generated"
                v-on:click="generatorButtonClick(item.key, item.generator)"
                type="success"
                size="mini"
              ) Генерировать

              el-input(
                :type='item.input_type'
                :placeholder="item.name"
                v-model="targetData[item.key]"
                :disabled="ro || checkDisable(item)"
              )

          template(v-else-if="item.type === Boolean")
            el-form-item(
              :label="item.name + ':'", :class="{ small_label: item.small_label, label_padding: true }"
              v-show="!item.input_hidden || item.input_hidden(item, item.key) !== true"
            )
              el-switch(
                :disabled="ro || checkDisable(item)"
                v-model="targetData[item.key]"
                :active-value="true"
                :inactive-value="false"
              )

          template(v-else-if="item.type === 'Header'")
            el-alert.header_type(
              :title="item.name"
              :description='item.description'
              :type="item.subtype || 'success'"
              :show-icon='item.icon ?? true'
              :closable="false")

        el-row.buttons__block
          el-button(v-on:click="save" type="primary" v-if="!ro") {{ !ro ? 'Сохранить' : 'Закрыть' }}
          el-button(type="warning" v-on:click="close" plain) Отмена

</template>

<script>
import * as R from 'ramda';
import {mapGetters} from "vuex";
import tables, {preparedTables} from "@/tables";
import formatDate from "@/utils/formatDate";
import getViewFieldForRow from "@/utils/getViewFieldForRow";

export default {
  name: 'ContentModal',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      validate: (item) => ['edit', 'create', 'view'].includes(item),
    },
    structure: {
      type: Array,
    },
    values: {
      type: Object,
    },
    tableName: {
      type: String,
    },
    tableCode: {
      type: String,
      required: false,
    }
  },
  data: () => ({
    buffer: {},
    targetData: {},
    BigInt,
    allSelectActivations: {},
    defaultValueInit: {},
    previousState: {},
    successClose: false,
  }),
  computed: {
    headerText() {
      if (this.mode === 'view') {
        return 'Просмотр ряда';
      }
      return this.mode === 'edit' ? 'Редактирование ряда' : 'Добавление ряда';
    },
    ro() {
      return this.mode === 'view';
    },
    structureFiltered() {
      return this.structure.filter(item => item.skip_input !== true && !this.restrictions[item.key]);
    },
    ...mapGetters({
      getTable: 'unitable/getTable',
      restrictions: 'restrictions',
    }),
  },
  watch: {
    opened(current, prev) {
      if (current === true && prev === false) {
        console.log('MODAL OPENED')
        if (this.values && this.values.source) {
          this.previousState = JSON.parse(JSON.stringify(this.values.source));
        }
      }
    }
  },
  methods: {
    bigintInputValue(key, item) {
      if (!this.targetData[key] && item.default_value) {

        this.targetData[key] = typeof item.default_value === 'function' ?
            item.default_value() : item.default_value;
        this.targetData = {...this.targetData};
      }
      return this.targetData[key];
    },
    generatorButtonClick(key, gen) {
      this.targetData[key] = gen();
      this.targetData = { ...this.targetData };
    },
    datePickerOptions(item) {
      const data = {
        firstDayOfWeek: 1,
      };
      if (item.validator) {
        data.disabledDate = (date) => item.validator(date, this.targetData)
      }
      return data;
    },
    handleModalOpen(v) {
      // console.log('modal opened', v, JSON.stringify(this.targetData));
      this.$emit('update:opened', v)
    },
    close() {
      this.$refs.drawer.closeDrawer();
    },
    checkDisable(item) {
      if (item.enabled_if) {
        console.log('disabled status', !item.enabled_if(this.targetData, item.key));
        return !item.enabled_if(this.targetData, item.key);
      }

      if (item.allow_edit === false && this.mode === 'edit') {
        return true;
      }

      if (!item.depends_on) return false;

      return !item.depends_on.every(key => (this.targetData[key] || []).length);
    },
    multipleSelectValue(key, item) {
      if (!this.targetData[key]) {
        this.targetData[key] = [];
        return [];
      }
      if (!item.subtype) {
        return this.targetData[key];
      }
      return this.targetData[key].map(item.subtype);
    },
    updateMultipleSelect(key, value) {
      this.targetData[key] = value;
      this.targetData = {...this.targetData};

    },
    selectValue(key, item) {

      if (!this.targetData[key] && item.default_value) {
        this.targetData[key] = typeof item.default_value === 'function' ?
            item.default_value() : item.default_value;
        return this.targetData[key];
      }

      if (typeof this.targetData[key] === 'undefined') return;

      if (!item.multiple) {
        return this.targetData[key] ? this.targetData[key].toString() : null;
      }


      if (Array.isArray(this.targetData[key])) {

        const selectOptions = this.selectOptions(
            item.relation.table, item.relation.filter_by, item.relation.depends_on, item
        ).map(i => i.value);

        const optionsWithoutAll = selectOptions.slice(1);

        if (this.targetData[key].length && R.symmetricDifference(optionsWithoutAll, this.targetData[key]).length === 0) {
          this.targetData[key] = R.prepend(-1, this.targetData[key]);
          this.allSelectActivations[key] = true;
        }

        return this.targetData[key];
      }

      return this.targetData[key] ? [...new Set(this.targetData[key].split(','))] : [];
    },
    dateValue(key, item) {
      if (this.targetData[key] || !item.default_value) {
        return this.targetData[key];
      }

      if (!this.defaultValueInit[key]) {
        this.defaultValueInit[key] = true;
        this.targetData[key] = item.default_value();
      }

      return this.targetData[key];
    },
    updateSelect(key, value, item) {

      const selectOptions = item.relation ? this.selectOptions(
          item.relation.table, item.relation.filter_by, item.relation.depends_on, item
      ).map(i => i.value) : this.multipleSelectData(item.data_from.table);

      // const optionsWithoutAll = selectOptions.slice(1);

      if (value.includes(-1) && !this.allSelectActivations[key]) {
        this.allSelectActivations[key] = true;
        this.targetData[key] = selectOptions;
      } else if (!value.includes(-1) && this.allSelectActivations[key]) {
        this.allSelectActivations[key] = false;
        this.targetData[key] = [];
      } else if (value.includes(-1) && this.allSelectActivations[key]) {
        // all select turned on
        // console.log(R.symmetricDifference(selectOptions, value));
        if (R.symmetricDifference(selectOptions, value).length !== 0) {
          this.allSelectActivations[key] = false;
          this.targetData[key] = value.slice(1); // remove "all" active
        }
      } else {
        this.targetData[key] = value;
      }

      if (item.must_clear) {
        item.must_clear.forEach(i => this.targetData[i] = []);
        item.must_clear.forEach(i => this.allSelectActivations[i] = false);
      }

      this.targetData = {...this.targetData};
    },
    modalOpened() {
      this.buffer = {...this.values};
      this.buffer.source = this.buffer.source ? {...this.buffer.source} : {};
      this.targetData = this.buffer.source ? {...this.buffer.source} : {};
      Object.keys(this.targetData).forEach(key => {
        const structureItem = this.getItem(key);

        if (!structureItem) return;

        if (structureItem.type === Date) {
          this.targetData[key] = formatDate(this.targetData[key]);
        }

        this.targetData[key] = typeof this.targetData[key] === 'string' && structureItem.multiple ?
            this.targetData[key].split(',') : this.targetData[key];
      })
      this.targetData = {...this.targetData};
      this.defaultValueInit = {};
      this.resetDateAttributes();
    },
    resetDateAttributes() {
      setTimeout(() => {
        const dateInputs = document.querySelectorAll('.date_input');

        dateInputs.forEach(item => {
          const Input = item.querySelector('input.el-input__inner');
          Input.setAttribute('readonly', 'readonly');
        });

      }, 1e1);
    },
    getItem(key) {
      return this.structure.find(item => item.key === key);
    },
    multipleSelectData(table) {
      const currentTable = this.getTable(table);
      const description = preparedTables[table];

      console.log(table, currentTable, )
      return currentTable.map(row => ({
        value: row[description.indexField[0]], name: getViewFieldForRow(description.viewField, row)
      }));
      // return currentTable;
    },
    selectOptions(table, filter_by, depends_on, item) {
      try {
        const currentTable = tables[table]();

        const { relation } = item;

        const middlewares = [];

        middlewares.push(this.getTable);


        const filterByItem = item => {
          try {
            return (this.restrictions[filter_by] || item).toString();
          } catch (e) {
            return undefined;
          }
        };

        if (filter_by) {
          middlewares.push(R.filter(item => {

            console.log('filter_by', item, filter_by, filterByItem(this.targetData[filter_by]), this.targetData);
            return item[relation.filter_by_in_join || filter_by].toString() === filterByItem(this.targetData[filter_by])
          }))
        }

        if (depends_on) {
          Object.entries(depends_on)
              .filter(([localKey]) => this.targetData[localKey])
              .filter(([localKey]) => this.targetData[localKey].length)
              .forEach(([localKey, sourceKey]) => {
                    middlewares.push(R.filter(item => this.targetData[localKey].includes(item[sourceKey].toString())))
                  }
              )
        }

        if (item.relation.limit_by) {
          Object.keys(item.relation.limit_by).forEach(localTable => {
            const limitRules = item.relation.limit_by[localTable];
            const limitTable = this.getTable(localTable);

            const localMiddlewares = [];

            localMiddlewares.push(R.filter(item => {
              // if (table === 'carriersdata') console.log(JSON.stringify(limitRules.fields));
              const result = Object.entries(limitRules.fields).every(([local, target]) => {

                    // if (table === 'carriersdata') console.log(local);
                    return (this.targetData[local] || []).includes(item[target].toString())

                    // return result;
                  }
              )

              if (result && table === 'carriersdata') {
                // console.log(item, this.targetData);
              }

              return result;

            }));


            localMiddlewares.push(R.uniqBy(R.prop(limitRules.target)));

            localMiddlewares.push(R.map(R.prop(limitRules.target)))

            const data = R.pipe(...localMiddlewares)(limitTable)

            middlewares.push(R.filter(item => {
              return data.includes(item[limitRules.compare || limitRules.target])
            }));
          })
        }

        const indexes = filter_by ?
            currentTable.indexField.filter(item => item !== filter_by) :
            [...currentTable.indexField];


        middlewares.push(R.map(item => ({
          name: getViewFieldForRow(currentTable.viewField, item),
          // name: item[currentTable.viewField],
          value: indexes.length > 1 ? indexes.map(i => `${i}:${item[i]}`).join('/') : item[indexes[0]].toString(),
        })));

        if (item.multiple) {
          middlewares.push((array) => {
            if (array.length) {
              return R.prepend({name: 'Все', value: -1})(array)
            }
            return [];
          })
        }

        middlewares.push(R.uniqBy(R.prop('value')))

        return R.pipe(...middlewares)(table);

      } catch (e) {
        console.error(e);
      }
    },
    async save() {
      const currentTable = tables[this.tableCode.toLowerCase()]();

      const payload = JSON.parse(JSON.stringify(this.targetData));

      try {
        if (currentTable.validate) {
          if (this.mode === 'edit' && currentTable.validate.edit) {
            currentTable.validate.edit(payload)
          }
          if (this.mode === 'add' && currentTable.validate.add) {
            currentTable.validate.add(payload)
          }
          if (currentTable.validate.all) {
            currentTable.validate.all(payload)
          }
        }
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Ошибка валидации',
          message: e.message,
        })
        return;
      }

      const index = currentTable.indexField;

      const primaryKey = R.pick(index, this.buffer.source);


      const keysToRemove = [];

      Object.keys(payload).forEach(key => {
        const column = this.getItem(key)

        if (column && column.password && typeof payload[key] !== 'undefined' && payload[key].trim() === '') {
          keysToRemove.push(key);
        }

        if (column && column.ignore_on_save) {
          keysToRemove.push(key);
        }

        if (Array.isArray(payload[key]) && payload[key].includes(-1)) {
          payload[key] = payload[key].slice(1);
        }
        if (this.getItem(key) && this.getItem(key).type === Date) {
          payload[key] = new Date(payload[key]);
        }
      })

      keysToRemove.forEach(key => {
        delete payload[key];
      })

      // eslint-disable-next-line
      try {
        if (this.mode === 'edit') {
          await this.$store.dispatch('unitable/editItem', {
            table_name: this.tableCode,
            primary_key: primaryKey,
            payload,
            additionalFields: currentTable.additionalFields,
          });
        } else if (this.mode === 'add') {
          await this.$store.dispatch('unitable/addItem', {
            table_name: this.tableCode,
            payload,
            additionalFields: currentTable.additionalFields,
          });
        }
        this.$notify.success({
          title: 'Данные успешно сохранены',
          // message: e.response.data.error
        });
      } catch (e) {
        this.$notify.error({
          title: 'Ошибка',
          message: e.response.data.error
        });
        return;
      }
      this.$emit('update-success');
      this.successClose = true;
      this.close();

    },
    insertDate(key, val) {
      this.targetData[key] = val;
      this.targetData = {...this.targetData};
    },
    insertBigNumber(val, key) {
      this.targetData[key] = val
          .toString()
          .split('')
          .map(Number)
          .filter(i => !Number.isNaN(i))
          .join('');
      this.targetData = {...this.targetData};
    },
    beforeClose(done) {

      if (this.successClose) {
        this.successClose = false;
        done();
        return;
      }
      if (this.mode === 'view') {
        done();
        return;
      }

      if (this.mode === 'add') {
        const currentState = JSON.parse(JSON.stringify(this.targetData));
        delete currentState.source;

        const hasNotNull = Object.values(currentState).find(item => !!item);

        if (!hasNotNull) {
          done();
          return;
        }
      }

      const currentState = JSON.parse(JSON.stringify(this.targetData));
      delete currentState.source;

      Object.values(this.structure).forEach((value) => {
        const { key } = value;
        if (value.type === Date) {
          currentState[key] = new Date(currentState[key])
          this.previousState[key] = new Date(this.previousState[key])
          currentState[key].setMilliseconds(0)
          this.previousState[key].setMilliseconds(0)
        }
      })

      let hasDifference = false;

      Object.keys(this.previousState).forEach(key => {
        const structure = this.getItem(key);

        if (!structure) return;

        if (structure.type === Date) {
          if (!this.previousState[key] && !currentState[key]) return;
          if (!this.previousState[key] || !currentState[key]) {
            hasDifference = true;
          } else if (this.previousState[key].valueOf() !== currentState[key].valueOf()) {
            hasDifference = true;
          }
        } else {
          if (this.previousState[key] !== currentState[key]) {
            hasDifference = true;
          }
        }

      })

      if (!hasDifference) {
        done();
        return;
      }

      this.$confirm('Данные не будут сохранены. Вы уверены?', 'Внимание', {
        confirmButtonText: 'Продолжить',
        cancelButtonText: 'Отмена',
        type: 'warning'
      }).then(() => {
        done();
      })
    }
  },
}
</script>

<style lang="stylus">

.header__tablename_content
  padding 0 40px
  margin 0
  margin-bottom 40px

.el-input
  width auto !important

.el-form-item__label
  line-height auto !important
  width 200px

.buttons__block
  margin-left 20px
  text-align center

.small_label .el-form-item__label
  line-height 20px !important

.el-date-picker__time-header .el-date-picker__editor-wrap:first-child
  display none !important

.content-modal form .el-form-item
  display flex
  align-items flex-start
  position relative
  label
    line-height 20px

  input,select,textarea
    width 300px

  .generator__btn
    position absolute
    left -200px
    top 20px

.password
  padding-left 60px
  font-weight bold
  font-size 12px

.header_type
  margin 20px
  width calc(100% - 40px)

.label_padding
  font-weight bold
  padding-left 40px
  .el-form-item__label
    text-align left !important

</style>
