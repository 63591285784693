import {sort} from "@/constants";

export default {
    tableCode: 'vt_zone',
    tableName: 'Зоны действия ВПБ',
    folderName: 'nsi',
    viewField: 'zone_name',
    indexField: [
        'zone_id'
    ],
    defaultOrder:
        {
            zone_id: sort.ASC,
        }
    ,
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор зоны действия ВПБ',
            key: 'zone_id',
            skip_input: true,
        },
        {
            type: String,
            name: 'Наименование зоны действия ВПБ',
            key: 'zone_name',
        },
        {
            type: Date,
            name: 'Начало действия',
            key: 'zone_begin',
            validator: (date, row) => row.zone_end < date,
        },
        {
            type: Date,
            name: 'Окончание действия',
            key: 'zone_end',
            validator: (date, row) => row.zone_begin > date,

        }
    ],
}
