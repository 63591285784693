import {sort} from "@/constants";

export default {
    tableCode: 'reasonsdata',
    tableName: 'Причины блокировки',
    indexField: ['id_reasons'],
    defaultOrder: {
        id_reasons: sort.ASC,
    },
    viewField: 'description',
    tableDescription: [
        {
            key: 'id_reasons',
            name: 'ID',
            type: BigInt,
        },
        {
            key: 'description',
            name: 'Описание',
            type: String,
        }
    ]
}
