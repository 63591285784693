import {sort} from "@/constants";

export default {
    tableCode: 'device_types',
    tableName: 'Типы оборудования',
    folderName: 'vpb_control',
    indexField: [
        'id'
    ],
    defaultOrder:
        {
            id: sort.ASC,
        }
    ,
    viewField: 'devicetype',
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор',
            key: 'id',
            skip_input: true,
            // column_hidden: true,
        },
        {
            type: String,
            name: 'Тип устройства',
            key: 'devicetype',
        },
        {
            type: String,
            name: 'Описание',
            key: 'note',
        },
    ],
}
