/*
vt_security_keys.key_id
Идентификатор ключа
auto increment
2
vt_security_keys.key_date_create
Дата-время
3
vt_security_keys.key_date_expiry
Дата-время
4
vt_security_keys.key_active_flag
Логический (активен / запрещен)
5
vt_security_keys.key_fingerprint
Битовая последовательность
6
vt_security_keys.key_version
Число
 */

import {sort} from "@/constants";

export default {
    tableCode: 'vt_security_data_view',
    tableName: 'Защитная последовательность ВПБ',
    indexField: ['key_id'],
    defaultOrder: {
      key_id: sort.ASC,
    },
    expand: true,
    folderName: 'key_security',
    readOnly: true,
    tableDescription: [
        {
            key: 'key_id',
            type: BigInt,
            name: 'ID',
        },
        {
            key: 'key_date_create',
            type: Date,
            name: 'Дата создания'
        },
        {
            key: 'key_date_expiry',
            type: Date,
            name: 'Дата окончания'
        },
        {
            key: 'key_active_flag',
            type: Boolean,
            name: 'Статус активности'
        },
        {
            key: 'key_fingerprint',
            type: String,
            name: 'Цифровой отпечаток ключа'
        },
        {
            key: 'key_version',
            type: BigInt,
            name: 'Версия'
        },
        {
            key: 'key_permissions_description',
            type: String,
            name: 'Права доступа'
        },
        {
            key: 'pairs',
            type: String,
            show_part: true,
            name: 'МБ / Номер ячейки',
        }
    ]
}
