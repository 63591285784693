<template lang="pug">
  el-row.master__container
    el-drawer#filters-drawer(
      direction="ttb"
      :visible.sync="filtersActive"
      size="50%"
      :before-close="() => $emit('update:filters-active', false)"
    ): .drawer-wrap(style="margin-left: 40px")
      el-form(:rules="validationRules" :model='localData' ref="filterForm").filter__container
        template(v-for="item in structure" v-if="!item.filter_hidden && !item.skip_filter && !(item.column_hidden && item.column_hidden())")
          template(v-if="item.relation")
            el-form-item(:prop="item.key")
              span(style="display: block") {{ item.name }}

              el-select.input__column(
                :value="selectValue(item.relation.table, item.key, item)"
                v-on:input="val => insertSelect(val, item.key, item)"
                v-on:change='search'
                filterable
                :placeholder="item.name"
                clearable
              )
                el-option(
                  v-for="item in selectOptions(item.relation.table, item.relation.filter_by, item.relation.depends_on)"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                )
          template(v-else-if="item.type === BigInt")
            el-form-item(:prop="item.key")
              span(style="display: block") {{ item.name }}
              el-input.input__column(
                type="string"
                :placeholder="item.name"
                v-on:change='search'
                v-on:input="val => insertBigNumber(val, item.key)"
                :value="localData[item.key]"
              )

          template(v-else-if="item.type === Number")
            el-form-item(:prop="item.key")
              span(style="display: block") {{ item.name }}
              el-input.input__column(
                type="number"
                :placeholder="item.name"
                v-model="localData[item.key]"
              )

          template(v-else-if="item.type === Date")
            //el-form-item(:prop="item.key"): el-date-picker.input__column(
            //  v-model="localData[item.key]"
            //  type="datetime"
            //  :placeholder="item.name")

            el-form-item.date-layout-fix(:prop="item.key")
              span {{ item.name }}
              el-date-picker.input__column(
                style='width: 200px'
                v-model="localData[item.key]"
                type="daterange"
                :picker-options="{firstDayOfWeek: 1}"
              )

          template(v-else-if="item.type === String && item.subtype === 'UUID'")
            el-form-item(:prop="item.key")
              span {{ item.name }}
              el-input.input__column(
                :placeholder="item.name"
                :value="stringValue(item.key)"
                v-on:input="val => insertString(val, item.key)"
              )

          template(v-else-if="item.type === String")
            el-form-item(:prop="item.key")
              span(style="display: block") {{ item.name }}
              el-input.input__column(
                :placeholder="item.name"
                :value="stringLikeValue(item.key)"
                v-on:input="val => insertLikeString(val, item.key)"
              )
      .buttons_group
        el-button.filter__button( icon="el-icon-search" type="primary" v-on:click="search") Искать
        el-button.filter__button.reset__button(type="danger" v-on:click="reset" icon="el-icon-close") Сбросить
        //el-button.filter__button.reset__button(type="success" plain circle v-on:click="refresh" icon="el-icon-refresh-right")
    el-form(:rules="validationRules" :model='localData' ref="filterForm").filter__container__row
      template(v-for="item in structure" v-if="!item.filter_hidden && !item.skip_filter && !(item.column_hidden && item.column_hidden())")
        template(v-if="item.relation")
          el-form-item(:prop="item.key")
            span(style="display: block") {{ item.name }}

            el-select.input__column(
              :value="selectValue(item.relation.table, item.key, item)"
              v-on:input="val => insertSelect(val, item.key, item)"
              filterable
              :placeholder="item.name"
              clearable
            )
              el-option(
                v-for="item in selectOptions(item.relation.table, item.relation.filter_by, item.relation.depends_on)"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              )
        template(v-else-if="item.type === BigInt")
          el-form-item(:prop="item.key")
            span(style="display: block") {{ item.name }}
            el-input.input__column(
              type="string"
              :placeholder="item.name"
              v-on:change="search"
              v-on:input="val => insertBigNumber(val, item.key)"
              :value="localData[item.key]"
            )

        template(v-else-if="item.type === Number")
          el-form-item(:prop="item.key")
            span(style="display: block") {{ item.name }}
            el-input.input__column(
              type="number"
              :placeholder="item.name"
              v-model="localData[item.key]"
            )

        template(v-else-if="item.type === Date")
          //el-form-item(:prop="item.key"): el-date-picker.input__column(
          //  v-model="localData[item.key]"
          //  type="datetime"
          //  :placeholder="item.name")

          el-form-item.date-layout-fix(:prop="item.key")
            span {{ item.name }}
            el-date-picker.input__column(
              style='width: 200px'
              v-model="localData[item.key]"
              type="daterange"
              :picker-options="{firstDayOfWeek: 1}"
            )

        template(v-else-if="item.type === String && item.subtype === 'UUID'")
          el-form-item(:prop="item.key")
            span(style="display: block") {{ item.name }}
            el-input.input__column(
              :placeholder="item.name"
              :value="stringValue(item.key)"
              v-on:input="val => insertString(val, item.key)"
            )

        template(v-else-if="item.type === String")
          el-form-item(:prop="item.key")
            span(style="display: block") {{ item.name }}
            el-input.input__column(
              :placeholder="item.name"
              :value="stringLikeValue(item.key)"
              v-on:input="val => insertLikeString(val, item.key)"
            )
    .buttons__row
      el-button( icon="el-icon-search" type="primary" v-on:click="search") Искать
      el-button.reset__button(type="danger" v-on:click="reset" icon="el-icon-close") Сбросить
</template>

<script>
import tables from "@/tables";
import * as R from "ramda";
import { mapGetters } from "vuex";
import getViewFieldForRow from "@/utils/getViewFieldForRow";

export default {
  name: 'UniTableFilters',
  data: () => ({
    BigInt,
    localData: {},
    drawerOpened: false,
  }),
  props: {
    structure: {
      type: Array,
    },
    filtersActive: {
      type: Boolean,
    },
    targetData: {
      type: Object,
    },
    columnsToShow: {
      type: Array,
    }
  },
  computed: {
    ...mapGetters({
      getTable: 'unitable/getTable',
    }),
    validationRules() {
      const rules = this.structure.filter(item => item.subtype === 'UUID');
      const result = {};
      rules.forEach(item => {
        result[item.key] = [
          { validator: (rule, value, callback) => {
            setTimeout(() => {
              const isValid = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/.test(value);
              if (!isValid && value.trim() !== '') {
                callback(new Error('Пожалуйста введите GUID'));
              } else {
                callback();
              }
            }, 1000)
            }, trigger: 'blur' }
        ]
      })
      return result;
    }
  },
  mounted() {
    this.localData = {};
    this.structure.forEach(item => {
      this.localData[item.key] = this.targetData[item.key];
    })
    this.localData = {...this.localData};
  },
  methods: {
    selectValue(table, key) {
      return this.localData[key];
    },
    stringLikeValue(key) {
      if (!this.localData[key]) {
        return '';
      }
      return this.localData[key].value ? this.localData[key].value.replace('\'', '') : this.localData[key].value;
    },
    insertLikeString(value, key) {
      this.localData[key] = {
        type: 'LIKE',
        value,
      }
    },
    stringValue(key) {
      if (!this.localData[key]) {
        return '';
      }
      return this.localData[key].replace('\'', '');
    },
    insertString(value, key) {
      this.localData[key] = value;
    },
    insertBigNumber(val, key) {
      this.localData[key] = val
          .toString()
          .split('')
          .map(Number)
          .filter(i => !Number.isNaN(i))
          .join('');
      window.filter = this;
      this.localData = { ...this.localData };
    },
    insertSelect(val, key, item) {
      console.log('INSERTING SELECT', val, key, item);
      this.localData[key] = val;

      this.localData = { ...this.localData };
    },
    reset() {
      this.localData = {};
      this.$emit('sort', {});
      this.$emit('update:filters-active', false);
      this.$emit('search', {});
    },
    refresh() {
      this.localData = {};
      this.$emit('sort', {});
      this.$emit('update:filters-active', false);
      this.$emit('search', {});
    },
    search() {
      this.$refs.filterForm.validate((valid) => {
        if (!valid) {
          alert('Корректно заполните форму!');
          return;
        }

        const result = R.pipe(
            R.toPairs,
            R.filter(i => {
              if (typeof i[1] === 'undefined') return false;

              return i[1] !== '';
            }),
            R.fromPairs
        )({...this.targetData, ...this.localData});

        try {
          console.log('here');
          for (const row of this.structure) {
            console.log(row.key, result, result[row.key]);
            const item = result[row.key];
            console.log(item);
            if (!item || !row.search_validate) continue;
            row.search_validate(result[row.key].value);

          }

          this.$emit('update:filters-active', false);
          this.$emit('search', result);
        } catch (e) {
          console.log(e);
          this.$notify({
            title: 'Ошибка ввода',
            message: e,
            type: 'error'
          })
        }
      });

      //alert(JSON.stringify(this.targetData));
    },

    selectOptions(table, filter_by, depends_on) {
      try {
        if (!tables[table]) return [];
        const currentTable = tables[table]();

        const middlewares = [];

        middlewares.push(this.getTable);
        middlewares.push(R.defaultTo([]));

        if (filter_by) {
          middlewares.push(R.filter(item => item[filter_by] === this.localData[filter_by]))
        }

        if (depends_on) {
          Object.entries(depends_on)
              .filter(([localKey]) => this.localData[localKey])
              .filter(([localKey]) => this.localData[localKey].length)
              .forEach(([localKey, sourceKey]) => {
                    middlewares.push(R.filter(item => this.localData[localKey].includes(item[sourceKey].toString())))
                  }
              )
        }
        const indexes = filter_by ?
            currentTable.indexField.filter(item => item !== filter_by) :
            [...currentTable.indexField];

        middlewares.push(R.map(item => ({
          name: getViewFieldForRow(currentTable.viewField, item),
          // name: item[currentTable.viewField],
          value: indexes.length > 1 ? indexes.map(i => `${i}:${item[i]}`).join('/') : item[indexes[0]],
        })));

        // middlewares.push(d => { console.log('SELECT DATA', d); return d; })
        return R.pipe(...middlewares)(table);

      } catch (e) {
        console.error(e);
      }
    },
  }
}
</script>

<style lang="stylus" scoped>

.filter__container__row
  display flex
  flex-direction row
  overflow-x scroll
  overflow-y hidden
  margin 10px 0
  .el-form-item
    min-width max-content
    margin 0 10px
  .el-form-item__content span
    font-size 12px

.filter__container
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 1fr));
  //grid-template-areas: "a a a a";
  gap: 0 10px;
  //grid-auto-columns: 250px;

.drawer-wrap
  display flex
  flex-direction row
  align-items center
  justify-content space-around


.filter__button
  margin 0 10px !important
  margin-bottom 22px !important

.reset__button
  margin-left 0 !important

.input__column
  margin-right 10px !important
  min-width 200px

.master__container
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //.el-form-item
    //margin-bottom 0px !important

.buttons_group
  display flex
  flex-direction column

.buttons__row
  display flex
  flex-direction column
  align-items self-start
  padding 0 10px
  padding-left 20px
  button
    width 100%
    margin 5px 0


</style>

<style lang="stylus">
#filters-drawer #el-drawer__title
  height 0 !important

.date-layout-fix
  .el-form-item__content
    display flex
    flex-direction column


.el-table__row td .cell
  white-space pre-wrap

</style>
