import Vuex from 'vuex';
import * as actions from './user/actions';
import * as mutations from './user/mutations';
import * as getters from './user/getters';
import admin from '@/store/admin';
import universaltable from '@/store/universaltable';

const menuHidden = localStorage.getItem('menuHidden');

const store = () => new Vuex.Store({
    state: {
        isAuthorized: false,
        user: {},
        restrictions: {},
        roles: [],
        menuHidden: menuHidden ? Boolean(Number(menuHidden)) : false,
        appVersion: ''
    },
    mutations: {
        toggleMenu: state => {
            state.menuHidden = !state.menuHidden;
            localStorage.setItem('menuHidden', String(Number(state.menuHidden)));
        },
        appVersion: (state, version) => {
            state.appVersion = version;
        },
        ...mutations,
    },
    actions,
    getters,
    modules: {
        unitable: universaltable,
        admin,
    }
})

class Store {

    constructor() {
        this.$store = null;
    }

    init() {
        this.$store = store();
        return this;
    }

    get() {
        return this.$store;
    }
}

export default new Store()
