import {sort} from "@/constants";

export default {
    tableCode: 'counterparties_users_dictionaries',
    tableName: 'Настройки ролей',
    folderName: 'users_management',
    indexField: [
        'role_id', 'dictionary'
    ],
    defaultOrder: [
        {
            key: 'role_id', type: sort.ASC,
        },
        {
            key: 'dictionary', type: sort.ASC,
        },
    ],
    viewField: 'login',
    tableDescription: [
        {
            type: BigInt,
            name: 'ID роли',
            key: 'role_id',
            relation: {
                table: 'counterparties_users_roles',
                join_rules: {
                    role_id: 'id',
                }
            }
        },
        {
            name: 'Словарь',
            key: 'dictionary',
            type: String,
            relation: {
                table: 'dictionaries_list',
                join_rules: {
                    dictionary: 'dictionary',
                }
            }
        },
        {
            name: 'Право на чтение',
            type: Boolean,
            key: 'r',
        },
        {
            name: 'Право на запись',
            type: Boolean,
            key: 'w',
        }
    ]
}
