import {sort} from "@/constants";

export default {
    tableCode: 'vt_ticketstatus',
    tableName: 'Статусы ВПБ',
    indexField: [
        'status',
    ],
    viewField: 'statustext',
    folderName: 'vpb_control',
    defaultOrder: {
        status: sort.ASC,
    },
    tableDescription: [
        {
            key: 'status',
            name: 'Статус',
            type: BigInt,
        },
        {
            key: 'statustext',
            name: 'Описание',
            type: String,
        }
    ]
}
