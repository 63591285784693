import {sort} from "@/constants";

export default {
    tableCode: 'vt_bonuses',
    tableName: 'Вознаграждения',
    indexField: [
        'id'
    ],
    defaultOrder:
        {
            id: sort.ASC,
        }
    ,
    viewField: 'id',
    tableDescription: [
        {
            type: BigInt,
            name: 'Уникальный  идентификтор правила расчета вознаграждения',
            key: 'id',
            // column_hidden: true,
            // skip_input: true,
        },
        {
            type: BigInt,
            name: 'Агентское вознаграждение',
            key: 'bonus',
        },
        {
            type: BigInt,
            name: 'Единицы измерения агентского вознаграждения (рубли/проценты)',
            key: 'units_bonus',
        },
        // {
        //     type: BigInt,
        //     name: 'Максимальный размер сервисного сбора Контрагента по реализации',
        //     key: 'max_service_fee',
        // },
        {
            type: BigInt,
            name: 'Код вида вознаграждения',
            key: 'bonus_type',
            relation: {
                table: 'bonustypes',
                join_rules: {
                    bonus_type: 'id'
                }
            }
        },
        {
            type: String,
            name: 'Единицы измерения максимального размера сервисного сбора (рубли/проценты)',
            key: 'units_max_service_fee',
        },
    ],
}
