import {sort} from "@/constants";

export default {
    tableCode: 'additional_messages',
    tableName: 'Дополнительная информация',
    folderName: 'nsi',
    indexField: [
        'id'
    ],
    defaultOrder:
        {
            id: sort.ASC,
        }
    ,
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор',
            key: 'id',
            // column_hidden: true,
            // skip_input: true,
        },
        {
            type: BigInt,
            name: 'Вид сообщения',
            key: 'message_type',
            // relation: {
            //     table: 'message_types',
            //     join_rules: {
            //         message_type: 'id'
            //     }
            // }
        },
        {
            type: Date,
            name: 'Дата добавления',
            key: 'datetime',
        },
        {
            type: String,
            name: 'Описание',
            key: 'description',
        }
    ],
}
