import {sort} from "@/constants";

export default {
    tableCode: 'logdata',
    tableName: 'Журнал обращений к АС ВПБ',
    indexField: [],
    readOnly: true,
    expand: true,
    defaultOrder: {
        operationdatetime: sort.DESC,
    },
    folderName: 'reestr',
    tableDescription: [
        {
            key: 'agentid',
            type: BigInt,
            name: 'Идентификатор контрагента',
            width: 100,
        },
        {
            key: 'deviceid',
            type: String,
            name: 'Идентификатор мобильного устройства'
        },
        {
            key: 'userid',
            type: BigInt,
            name: 'Идентификатор пользователя МП/ЛКП',
            width: 100,
        },
        {
            key: 'operationdatetime',
            type: Date,
            name: 'Дата-Время операции'
        },
        {
            key: 'signature',
            type: String,
            name: 'Подпись',
        },
        {
            key: 'method',
            type: String,
            name: 'Имя вызываемой функции(метода)'
        },
        {
            key: 'datain',
            type: String,
            name: 'Данные запроса',
            show_part: true,
        },
        {
            key: 'dataout',
            type: String,
            name: 'Данные ответа',
            show_part: true,
        },
        {
            key: 'errorcode',
            type: BigInt,
            name: 'Код ошибки',
            width: 100,
            relation: {
                table: 'vt_errorcode',
                join_rules: {
                    errorcode: 'errorcode'
                }
            }
        },
    ]
}
