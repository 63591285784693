import {sort} from "@/constants";
export default {
    tableCode: 'carriersdata',
    tableName: 'Перевозчики',
    indexField: [
        'id'
    ],
    defaultOrder: {
        id: sort.ASC,
    },
    viewField: 'carrier_name',
    tableDescription: [
        {
            type: BigInt,
            name: 'ID',
            key: 'id',
            column_hidden: () => true,
            skip_input: true,
        },
        {
            type: String,
            name: 'Наименование перевозчика',
            key: 'carrier_name',
        },
        {
            type: String,
            name: 'ФИО контактного лица',
            key: 'contacts',
        },
        {
            type: String,
            name: 'Телефон',
            key: 'phone',
        },
        {
            type: String,
            name: 'Email',
            key: 'email',
        },
    ],
}
