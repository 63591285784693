import {sort} from "@/constants";

export default {
    tableCode: 'tarifrules',
    tableName: 'Правила продаж',
    indexField: [
        'category_id',
    ],
    folderName: 'nsi',
    defaultOrder: {
        category_id: sort.ASC,
    },
    tableDescription: [
        {
            name: 'ID категории',
            key: 'category_id',
            type: BigInt,
            relation: {
                table: 'cardcategories',
                join_rules: {
                    category_id: 'category_id'
                }
            }
        },
        {
            key: 'c1',
            type: Boolean,
            name: 'Правило для категории 1'
        },
        {
            key: 'c2',
            type: Boolean,
            name: 'Правило для категории 2'
        },
        {
            key: 'c3',
            type: Boolean,
            name: 'Правило для категории 3'
        },
        {
            key: 'c4',
            type: Boolean,
            name: 'Правило для категории 4'
        },
        {
            key: 'c5',
            type: Boolean,
            name: 'Правило для категории 5'
        },
        {
            key: 'c6',
            type: Boolean,
            name: 'Правило для категории 6'
        },
        {
            key: 'c7',
            type: Boolean,
            name: 'Правило для категории 7'
        },
        {
            key: 'c8',
            type: Boolean,
            name: 'Правило для категории 8'
        },
        {
            key: 'c9',
            type: Boolean,
            name: 'Правило для категории 9'
        },
    ]
}
