import {sort} from "@/constants";

export default {
    tableCode: 'counterparties_users_roles',
    tableName: 'Роли пользователей',
    folderName: 'users_management',
    indexField: [
        'id'
    ],
    defaultOrder: [
        {
            key: 'id', type: sort.ASC,
        },
    ],
    viewField: 'name',
    tableDescription: [
        {
            type: BigInt,
            name: 'ID роли',
            key: 'id',
            skip_input: true,
            column_hidden: () => true,
        },
        {
            type: String,
            key: 'name',
            name: 'Имя роли'
        },
    ]
}
