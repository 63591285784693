import {sort} from "@/constants";
import Store from "@/store/store";


const permissionRow = {
    column_hidden: () => true,
    relation: {
        table: 'table_permission_description',
        join_rules: {
            tbl_system_users: 'permission',
        }
    },
    input_hidden: (row, key) => {
        const restrictions = Store.$store.getters.roles;
        const user = Store.$store.getters.user;

        if (!user.counterparty_id) return false;

        const current = restrictions.find(item => item.table_code === key.slice(4));
        return !current;
    },
    enabled_if: (row, key) => {
        const restrictions = Store.$store.getters.roles;
        const user = Store.$store.getters.user;

        if (!user.counterparty_id) return true;

        const current = restrictions.find(item => item.table_code === key.slice(4));
        return !!current;
    },
    default_value: 'none',
};


export default {
    tableCode: 'system_roles',
    tableForSelect: 'system_roles_enriched',
    tableName: 'Группы пользователей',
    folderName: 'users_management',
    indexField: [
        'id'
    ],
    defaultOrder: {
        id: sort.ASC,
    },
    validation: {
        remove: (row) => {
            if (+row.users_count > 0) {
                throw new Error('Для удаления в группе не должно быть пользователей!')
            }
        },
    },
    viewField: 'name',
    tableDescription: [
        {
            type: BigInt,
            key: 'id',
            name: 'ID',
            skip_input: true,
        },
        {
            type: BigInt,
            key: 'owner_id',
            name: 'Создатель группы',
            skip_column: true,
            input_hidden: () => true,
            default_value: () => Store.$store.getters.user.id,
            relation: {
                table: 'system_users',
                join_rules: {
                    owner_id: 'id',
                }
            }
        },
        {
            type: BigInt,
            key: 'users_count',
            column_hidden: () => true,
            skip_input: true,
            ignore_on_save: true,
            input_hidden: () => true,
        },
        {
            type: String,
            key: 'name',
            name: 'Название группы'
        },
        {
            type: BigInt,
            key: 'device_access',
            name: 'Авторизация оборудования',
            relation: {
                table: 'devices_access_dictionary',
                join_rules: {
                    device_access: 'id'
                }
            },
            enabled_if: (row) => {
                // on create
                console.log('device access', row, typeof row.users_count === 'undefined');
                if (typeof row.users_count === 'undefined') {
                    return true;
                }
                // on edit
                return Number(row.users_count) === 0
            },
            input_hidden: () => {
                const user = Store.$store.getters.user;


                console.log('input hidden', user);

//                if (!user.counterparty_id) return true;

                return !user.device_access;
            },
        },
        {
            type: Boolean,
            name: 'Просмотр контактной информации',
            key: 'ppa_users_view',
            enabled_if: () => {
                const user = Store.$store.getters.user;

                if (!user.counterparty_id) return true;

                return !!user.ppa_users_view;
            },
            input_hidden: () => {
                const user = Store.$store.getters.user;

                if (!user.counterparty_id) return true;

                return !user.ppa_users_view;
            },
        },
        {
            type: 'Header',
            name: 'Управление доступами НСИ',
        },
        // {
        //     name: 'Справочник "Категории НСИ"',
        //     key: 'tbl_cardcategories',
        //     ...permissionRow,
        // },
        // {
        //     name: 'Справочник "Правила продаж НСИ"',
        //     key: 'tbl_tarifrules',
        //     ...permissionRow,
        // },
        {
            name: 'Справочник "Секции ВПБ"',
            key: 'tbl_vt_section',
            ...permissionRow,
        },
        {
            name: 'Справочник "Пересадки (правила списания ЕЭБ)"',
            key: 'tbl_vt_transfer',
            ...permissionRow,
        },
        {
            name: 'Тариф списания ЕЭБ «Пересадка НГПТ-60»',
            key: 'tbl_tariffplanticket60',
            ...permissionRow,
        },
        {
            name: 'Справочник "Тарифы ВПБ"',
            key: 'tbl_tarif',
            ...permissionRow,
        },
        {
            name: 'Справочник "Виды транспорта"',
            key: 'tbl_vt_transporttyps',
            ...permissionRow,
        },
        {
            name: 'Справочник "Остановки"',
            key: 'tbl_zone_stations',
            ...permissionRow,
        },
{
            name: 'Справочник "Зональные тарифы"',
            key: 'tbl_zone_tariff',
            ...permissionRow,
        },
        {
            name: 'Справочник "Зоны"',
            key: 'tbl_zones',
            ...permissionRow,
        },
        {
            name: 'Справочник "Настройка АС ВПБ"',
            key: 'tbl_settings',
            ...permissionRow,
        },
        // {
        //     name: 'Справочник "Классы тарифов"',
        //     key: 'tbl_tarifclass',
        //     ...permissionRow,
        // },
        // {
        //     name: 'Справочник "Зоны действия"',
        //     key: 'tbl_vt_zone',
        //     ...permissionRow,
        // },
        {
            name: 'Справочник "Маршруты"',
            key: 'tbl_vt_routesdata',
            ...permissionRow,
        },
        {
            name: 'Справочник "Контрагенты"',
            key: 'tbl_vt_counterparties',
            ...permissionRow
        },
        {
            name: 'Справочник "Статусы датаграмм"',
            key: 'tbl_vt_datagrammstatus',
            ...permissionRow,
        },
        // {
        //     name: 'Справочник "Типы данных ПБ"',
        //     key: 'tbl_carddatatypes',
        //     ...permissionRow,
        // },
        {
            name: 'Справочник "Оборудование"',
            key: 'tbl_devices',
            ...permissionRow,
        },
        // {
        //     key: 'tbl_mutualpaymentstypes',
        //     name: 'Справочник "Виды взаиморасчетов"',
        //     ...permissionRow,
        // },
        // {
        //     key: 'tbl_routetariftypes',
        //     name: 'Справочник "Тарифы маршрута"',
        //     ...permissionRow,
        // },
        // {
        //     key: 'tbl_bonustypes',
        //     name: 'Справочник "Виды вознаграждения"',
        //     ...permissionRow,
        // },
        {
            key: 'tbl_vt_security_data_view',
            name: 'Справочник "Защитная последовательность ВПБ"',
            ...permissionRow,
        },
        {
            key: 'tbl_vt_security_rel_key_sam_view',
            name: 'Модули САМ',
            ...permissionRow,
        },
        // {
        //     key: 'tbl_exch_mutualpaymentstypes',
        //     name: 'Справочник "Виды взаиморасчетов для наценок"',
        //     ...permissionRow,
        // },
        {
            name: 'Справочник "Производители оборудования"',
            key: 'tbl_device_manufacturers',
            ...permissionRow,
        },
        {
            name: 'Справочник "История изменения токенов действия оборудования"',
            key: 'tbl_access_tocken_history_enriched',
            ...permissionRow,
        },
        // {
        //     name: 'Справочник "Виды транспорта"',
        //     key: 'tbl_transporttypes',
        //     ...permissionRow,
        // },
        {
            name: 'Справочник "Коды ошибок"',
            key: 'tbl_vt_errorcode',
            ...permissionRow,
        },
        {
            name: 'Справочник "Дополнительная информация"',
            key: 'tbl_addinfo_fields',
            ...permissionRow,
        },
        // {
        //     name: 'Справочник "Статусы ВПБ"',
        //     key: 'tbl_vt_ticketstatus',
        //     ...permissionRow,
        // },
        {
            name: 'Справочник "Транспорт"',
            key: 'tbl_transportdata',
            ...permissionRow,
        },
        //{
     //       name: 'Справочник "Дополнительная информация"',
         //   key: 'tbl_additional_messages',
        //    ...permissionRow,
    //    },
        // {
        //     name: 'Справочник "Статусы оборудования"',
        //     key: 'tbl_devicestatuses',
        //     ...permissionRow,
        // },
        // {
        //     name: 'Справочник "Статусы контрагентов"',
        //     key: 'tbl_counterparties_statuses',
        //     ...permissionRow,
        // },
        // {
        //     name: 'Справочник "Статусы договоров"',
        //     key: 'tbl_contracts_statuses',
        //     ...permissionRow,
        // },
        {
            name: 'Справочник "Тип ВПБ"',
            key: 'tbl_vt_cardsdata',
            ...permissionRow,
        },
        {
            name: 'Справочник "Виды сообщений"',
            key: 'tbl_routetypes',
            ...permissionRow,
        },
        {
            type: 'Header',
            name: 'Управление разделом контрагенты',
        },
        {
            name: 'Справочник "Договоры"',
            key: 'tbl_vt_contracts',
            ...permissionRow,
        },
        // {
        //     name: 'Справочник "Дополнительные соглашения"',
        //     key: 'tbl_vt_additional_agreement',
        //     ...permissionRow
        // },
        {
            name: 'Справочник "Наценки"',
            key: 'tbl_vt_extra_charges',
            ...permissionRow,
        },
        {
            name: 'Справочник "Площадки выпуска"',
            key: 'tbl_issuance_areas',
            ...permissionRow,
        },
        // {
        //     name: 'Справочник "Статусы площадок выпуска"',
        //     key: 'tbl_issuance_areastatuses',
        //     ...permissionRow,
        // },
        {
            name: 'Справочник "Типы договоров"',
            key: 'tbl_contract_types',
            ...permissionRow,
        },
        // {
        //     name: 'Справочник "Виды договоров"',
        //     key: 'tbl_contract_kinds',
        //     ...permissionRow,
        // },
        {
            type: 'Header',
            name: 'Управлениие реестрами',
        },

        {
            name: 'Виртуальные носители',
            key: 'tbl_virtualcards',
            ...permissionRow,
        },
        {
            name: 'ВПБ на срок действия',
            key: 'tbl_ticketinterval',
            ...permissionRow,
        },
        {
            name: 'История изменения ID устройств',
            key: 'tbl_deviceidhistory',
            ...permissionRow,
        },
        {
            name: 'ВПБ на количество единиц',
            key: 'tbl_ticketmony',
            ...permissionRow,
        },
        {
            name: 'ВПБ суточные',
            key: 'tbl_ticketunit',
            ...permissionRow,
        },
        {
            name: 'Датаграммы',
            key: 'tbl_datagramm',
            ...permissionRow,
        },
        {
            name: 'Транзакции о закрытии датаграмм и поездкам',
            key: 'tbl_transactionsvpb',
            ...permissionRow
        },
        {
            name: 'Транзакции пополнения/продажи ВПБ',
            key: 'tbl_sodtrans',
            ...permissionRow
        },
        {
            name: 'Транзакции контроля/гашения ВПБ',
            key: 'tbl_controlvpb',
            ...permissionRow
        },
        {
            name: 'Журнал обращений к АС ВПБ',
            key: 'tbl_logdata',
            ...permissionRow,
        },

        {
            name: 'Стоп-лист ВПБ',
            key: 'tbl_vt_blacklist',
            ...permissionRow,
        },
        {
            name: 'Стоп-лист датаграмм',
            key: 'tbl_vt_datagrammblacklist',
            ...permissionRow,
        },
        // {
        //     name: 'Токены для доступа к АС ВПБ',
        //     key: 'tbl_access_data',
        //     ...permissionRow
        // },
        {
            type: 'Header',
            name: 'Управление разделом "Тестовые ВПБ"',
        },
        {
            name: 'Тестовые ВПБ',
            key: 'tbl_testvirtualticket',
            ...permissionRow,
        },
        {
            name: 'Тестовые ВПБ содтранс',
            key: 'tbl_sodtranstest',
            ...permissionRow,
        },
        {
            name: 'Тестовые ВПБ транзакции',
            key: 'tbl_transactionsvpbtest',
            ...permissionRow,
        },
        {
            type: 'Header',
            name: 'Управление отчетами'
        },
        {
            name: 'Раздел "Отчеты"',
            type: Boolean,
            key: 'reports_general',
            enabled_if() {
                const user = Store.$store.getters.user;

                if (!user.counterparty_id) return true;

                return !!user.reports_general;
            },
            input_hidden() {
                const user = Store.$store.getters.user;

                if (!user.counterparty_id) return true;

                return !user.reports_general;
            },
        },
        {
            name: 'Реестр восстановления излишне списанного ресурса ЕЭБ',
            key: 'tbl_reparereseeb',
            ...permissionRow,
        },
        {
            name: 'Доступные отчеты',
            type: Array,
            subtype: String,
            multiple: true,
            key: 'reports_list',
            default_value: () => [],
            data_from: {
                table: 'reports_list'
            },
            enabled_if() {
                const user = Store.$store.getters.user;

                if (!user.counterparty_id) return true;

                return !!user.reports_general;
            },
            input_hidden: () => {
                const user = Store.$store.getters.user;

                if (!user.counterparty_id) return true;

                return !user.reports_general;
            },
        },
        {
            name: 'Лица, подписывающие отчеты',
            key: 'tbl_vt_signatory_l',
            ...permissionRow,
        },
        {
            type: 'Header',
            name: 'Управление разделом пользователей',
        },
        {
            name: 'Пользователи',
            key: 'tbl_system_users',
            ...permissionRow,
        },
        {
            name: 'Группы пользователей',
            key: 'tbl_system_roles',
            ...permissionRow,
        },
        {
            name: 'Действия пользователей',
            key: 'tbl_action_history',
            ...permissionRow,
        },
    ]
}
