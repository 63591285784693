import {sort} from "@/constants";
import {vt_counterparties_join} from "@/joins";

export default {
    tableCode: 'reparereseeb',
    tableName: 'Реестр восстановления<br> излишне списанного ресурса ЕЭБ',
    indexField: ['act_id'],
    defaultOrder: {
        error_date: sort.DESC,
    },
    isDoubleLine: true,
    folderName: 'reports',
    tableDescription: [
        {
            key: 'act_id',
            type: String,
            name: 'Номер акта'
        },
        {
            key: 'card_id',
            type: BigInt,
            name: 'Номер виртуального носителя заявителя'
        },
        {
            key: 'sum_repare',
            type: BigInt,
            name: 'Сумма восстановленного ресурса'
        },
        {
            key: 'error_date',
            type: Date,
            name: 'Дата излишнего списания ресурса'
        },
        {
            key: 'request_id',
            type: String,
            name: 'Номер обращения'
        },
        {
            key: 'request_date',
            name: 'Дата обращения',
            type: Date,
        },
        {
            key: 'user_name',
            type: String,
            name: 'Фамилия и инициалы заявителя'
        },
        {
            key: 'repare_date',
            type: Date,
            name: 'Дата восстановления ресурса'
        },
        vt_counterparties_join('carrier_id', 'Перевозчик')
    ]
}
