import axios from "@/utils/axios";

export const login = async ({ dispatch }, { login, password }) => {
    const { data } = await axios
        .post(`user/login`, { login, password });

    if (data.status === 'ok') {
        localStorage.setItem('authorization', data.session_id);
        window.axios = axios;
        axios.defaults.headers.common.Authorization = data.session_id;

        await dispatch('ok');
    } else {
        throw { response: { data } };
    }
};

export const logout = async ({ commit }) => {
    await axios.get(`user/logout`);
    commit('setAuth', false);
}


export const ok = async ({ commit }) => {
    const { data } = await axios.get(`user/ok`);
    const { data: roles } = await axios.get(`user/roles`);
    const { data: restrictions } = await axios.get(`user/restrictions`);
    commit('setAuth', true)
    commit('setUser', data.user)
    commit('setRoles', roles)
    commit('setRestrictions', restrictions)
}


