import {sort} from "@/constants";

export default {

    tableName: 'Дополнительная информация',
    tableCode: 'addinfo_fields',
    folderName: 'nsi',
    indexField: ['id'],
    defaultOrder: {
        id: sort.ASC,
    },
    validate: {
        all: (row) => {
            if (row.type === 'link' ) {
                if (!row.value || !row.value.startsWith('http')) {
                    throw new Error('Введите ссылку!')
                }
            }
        },
    },
    tableDescription: [
        {
            key: 'id',
            type: BigInt,
            name: 'ID',
            skip_input: true,
        },
        {
            type: String,
            key: 'type',
            name: 'Тип поля',
            relation: {
                table: 'addinfo_fieldstypes',
                join_rules: {
                    type: 'type',
                }
            },
        },
        {
            type: String,
            key: 'description',
            name: 'Описание поле',
        },
        {
            type: String,
            key: 'value',
            name: 'Значение',
            skip_decorating: true,
        }
    ]

}
