import Store from "@/store/store";
import {sort} from "@/constants";

export default {
    tableCode: 'deviceidhistory',
    tableForSelect: 'deviceidhistory_enriched',
    tableName: 'История изменения ID устройств',
    indexField: [],
    defaultOrder: {
        eventdatetime: sort.DESC,
    },
    folderName: 'reestr',
    tableDescription: [
        {
            key: 'userid',
            type: BigInt,
            name: 'ID пользователя'
        },
        {
            type: String,
            name: 'Контактная информация пользователя МП/ЛКП',
            key: 'credentials',
            column_hidden: () => !Store.$store.getters.shallSeeCredentials, // +
        },
        {
            key: 'olddeviceid',
            type: String,
            name: 'Старый ID устройства'
        },
        {
            key: 'newdeviceid',
            type: String,
            name: 'Новый ID устройства'
        },
        {
            key: 'eventdatetime',
            type: Date,
            name: 'Дата-время операции'
        },
        {
            key: 'reason',
            type: BigInt,
            name: 'Причина',
            relation: {
                table: 'deviceidhistory_reasons',
                join_rules: {
                    reason: 'id',
                }
            }
        },
        {
            key: 'agentid',
            type: BigInt,
            name: 'ID агента',
        },
    ]
}
