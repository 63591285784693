import {sort} from "@/constants";

export default {
    tableCode: 'routesbycontract',
    tableName: 'Перечень маршрутов по договору',
    indexField: [
        'contract_id',
        'route'
    ],
    defaultOrder:
        {
            contract_id: sort.ASC,
        }
    ,
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор договора',
            key: 'contract_id',
            relation: {
                table: 'vt_contracts',
                join_rules: {
                    contract_id: 'id',
                }
            }
        },
        {
            type: BigInt,
            name: 'Маршрут',
            key: 'route',
            relation: {
                table: 'vt_routesdata',
                join_rules: {
                    route: 'id',
                }
            }
        }, {
            type: String,
            name: 'Примечание',
            key: 'note',
        },
    ],
}
