import {sort} from "@/constants";

export default {
    tableCode: 'transportcustomers',
    tableName: 'Заказчики перевозок',
    indexField: [
        'id_customer'
    ],
    defaultOrder: {
        id_customer: sort.ASC,
    },
    viewField: 'name',
    tableDescription: [
        {
            type: BigInt,
            name: 'Код заказчика',
            key: 'id_customer',
            column_hidden: () => true,
            skip_input: true,
        },
        {
            type: String,
            name: 'Наименование',
            key: 'name',
        },
        {
            type: String,
            name: 'Контакты',
            key: 'contact',
        },
    ],
}
