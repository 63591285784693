export const USER_ROLES = {
    administrator: 'administrator',
    blacklist_operator: 'blacklist_operator',
    nsi_operator: 'nsi_operator',
    reports_operator: 'reports_operator',
    pb_rules_operator: 'pb_rules_operator',
};

export const sort = {
    DESC: 'DESC',
    ASC: 'ASC',
}
