import {sort} from "@/constants";
import {transporttypes_join} from "@/joins";

const viewField = function (row) {
    this.valueOf =
        (for_server = false) =>
            for_server ?
                [
                    'vt_type', 'vt_subtype',
                    'shortdescription', 'description'
                ] : `(${row.vt_type}/${row.vt_subtype}) ${row.shortdescription || row.description}`;
}

export default {
    tableCode: 'vt_cardsdata',
    tableName: 'Типы ВПБ',
    folderName: 'nsi',
    indexField: [
        'vt_cardsdata_id',
    ],
    position: 2,
    defaultOrder: {
            vt_cardsdata_id: sort.ASC,
    },
    viewField: viewField, ////  make here arrow function based on row param
    tableDescription: [
        {
            type: BigInt,
            name: 'ID',
            key: 'vt_cardsdata_id',
            skip_input: true,
            column_hidden: () => true,
        },
        {
            type: BigInt,
            name: 'Тип ВПБ',
            key: 'vt_type',
            width: 80
        },
        {
            type: BigInt,
            name: 'Вид ВПБ',
            key: 'vt_subtype',
            width: 80
        },
        {
            type: String,
            name: 'Наименование',
            key: 'description',
            width: 200
        },
        {
            type: String,
            key: 'shortdescription',
            name: 'Краткое наименование билета',
            width: 150
        },
        {
            key: 'datatype',
            name: 'Тип данных',
            type: BigInt,
            relation: {
                table: 'carddatatypes',
                join_rules: {
                    datatype: 'datatype_id',
                }
            },
        },
        {
            key: 'ticketclass',
            name: 'Класс ВПБ',
            type: BigInt,
            relation: {
                table: 'tarifclass',
                join_rules: {
                    ticketclass: 'classid',
                }
            },
            width: 100
        },
        {
            key: 'category',
            name: 'Категория',
            type: BigInt,
            relation: {
                table: 'cardcategories',
                join_rules: {
                    category: 'category_id'
                }
            }
        },
        {
            key: 'validtime',
            name: 'Срок действия в минутах',
            type: BigInt,
            enabled_if: row => +row.ticketclass === 3,
            width: 100
        },
        transporttypes_join('transporttype'),
        {
            key: 'ticketrules',
            name: 'Массив совместимости билетов',
            multiple: true,
            type: Array,
            data_from: {
                table: 'vt_cardsdata_for_select',
            }
        }
    ],
}
