import {sort} from "@/constants";

export default {
    tableCode: 'vt_extra_charges',
    tableName: 'Наценки',
    folderName: 'contragents',
    indexField: [
        'id'
    ],
    defaultOrder:
        {
            id: sort.ASC,
        }
    ,
    viewField: 'id',
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор правила наценки',
            key: 'id',
            // column_hidden: true,
            skip_input: true,
        },
        {
            type: BigInt,
            name: 'Идентификатор контрагента',
            key: 'counterparty_id',
            must_clear: ['contract_id'],
            relation: {
                table: 'vt_counterparties',
                join_rules: {
                    counterparty_id: 'counterparty_id',
                }
            }
        },
        {
            type: BigInt,
            name: 'Договор',
            key: 'contract_id',
            relation: {
                table: 'vt_contracts',
                filter_by: 'counterparty_id',
                // depends_on: 'counterparty_id',
                depends_on: [
                    'counterparty_id',
                ],
                join_rules: {
                    contract_id: 'id',
                }
            }
        },
        {
            key: 'agency_fee',
            type: BigInt,
            name: 'Агентское вознаграждение'
        },
        {
            key: 'agency_fee_unit',
            type: BigInt,
            name: 'Единица измерения агентского вознаграждения',
            relation: {
                table: 'units_list',
                join_rules: {
                    agency_fee_unit: 'id',
                }
            }
        },
        {
            type: BigInt,
            name: 'Максимальный размер сервисного сбора',
            key: 'max_service_fee',
        },
        {
            key: 'max_service_fee_unit',
            type: BigInt,
            name: 'Единица измерения максимального размера сервисного вознаграждения',
            relation: {
                table: 'units_list',
                join_rules: {
                    max_service_fee_unit: 'id',
                }
            }
        },
        {
            key: 'bonustype',
            type: BigInt,
            name: 'Вид вознаграждения',
            relation: {
                table: 'bonustypes',
                join_rules: {
                    bonustype: 'id',
                }
            }
        },
        {
            key: 'mutualpaymentstypes',
            type: BigInt,
            name: 'Вид взаиморасчетов',
            relation: {
                table: 'exch_mutualpaymentstypes',
                join_rules: {
                    bonustype: 'id',
                },
            },
        },
    ],
}
