import {sort} from "@/constants";

export default {
    tableCode: 'datagrammtypes',
    tableName: 'Типы датаграмм',
    viewField: 'name',
    indexField: [
        'id',
    ],
    defaultOrder: {
        id: sort.ASC,
    },
    local: true,
    tableDescription: [
        {
            key: 'id',
            type: BigInt,
        },
        {
            key: 'name',
            type: String,
        }
    ],
    tableContent: [
        { id: 0, name: 'NFC' },
        { id: 1, name: 'QR' },
    ]
}
