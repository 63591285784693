import {sort} from "@/constants";

export default {
    tableCode: 'zonetariffsdetais',
    tableName: 'ZoneTariffsDetais',
    indexField: [
        'id_zonetariff'
    ],
    defaultOrder:
        {
            id_zonetariff: sort.ASC,
        }
    ,
    tableDescription: [
        {
            type: BigInt,
            name: 'ID',
            key: 'id_zonetariff',
            column_hidden: () => true,
            skip_input: true,
        },
        {
            type: BigInt,
            name: 'Тип карты',
            key: 'card_type',
        },
        {
            type: BigInt,
            name: 'Тариф',
            key: 'tariff',
        },
        {
            type: BigInt,
            name: 'Зона начала',
            key: 'zone_begin',
            relation: {
                table: 'vt_zone',
                join_rules: {
                    zone_begin: 'zone_id',
                }
            }
        },
        {
            type: BigInt,
            name: 'Зона окончания',
            key: 'zone_end',
            relation: {
                table: 'vt_zone',
                join_rules: {
                    zone_end: 'zone_id',
                }
            }
        },
        {
            type: String,
            name: 'Комментарий',
            key: 'comment',
        },
    ],
}
