import {sort} from "@/constants";

export default {
    tableCode: 'access_data',
    tableName: 'Токены для <br>доступа к АС ВПБ',
    indexField: [],
    readOnly: true,
    isDoubleLine: true,
    defaultOrder: {
        id: sort.ASC,
    },
    folderName: 'reestr',
    tableDescription: [
        {
            key: 'id',
            type: String,
            name: 'Идентификатор',
        },
        {
            key: 'counterparty_id',
            type: String,
            name: 'Идентификатор контрагента',
        },
        {
            key: 'access_token',
            type: String,
            name: 'Код доступа (токен)'
        },
        {
            type: Date,
            key: 'begindate',
            name: 'Дата/время начала действия кода (токена)',
        },
        {
            type: Date,
            key: 'enddate',
            name: 'Дата/время окончания действия кода (токена)',
        },
    ]
}
