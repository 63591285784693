import {sort} from "@/constants";

export default {
    tableCode: 'settings',
    tableName: 'Параметры АС ВПБ',
    folderName: 'nsi',
    indexField: [
        'name'
    ],
    defaultOrder: {
        name: sort.ASC,
    },
    skipAdd: true,
    viewField: 'name',
    tableDescription: [
        {
            type: String,
            name: 'Параметр',
            key: 'name',
            skip_input: true,
        },
        {
            type: String,
            name: 'Примечание',
            key: 'descriptions',
        },
        {
            type: String,
            name: 'Значение параметра',
            key: 'value',
        },
    ],
}
