import {sort} from "@/constants";

export default {
    tableCode: 'zoneroute',
    tableName: 'Зональный маршрут',
    indexField: [
        'route_id'
    ],
    folderName: 'nsi',
    defaultOrder:
        {
            route_id: sort.ASC,
        }
    ,
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор маршрута',
            key: 'route_id',
        },
        {
            type: String,
            key: 'route_name',
            name: 'Наименование зонального маршрута',
        },
        {
            type: Date,
            name: 'Дата начала действия зональной тарифной политики',
            key: 'expiry_date_begin',
            validator: (date, row) => row.expiry_date_end < date,
        },
        {
            type: Date,
            name: 'Дата окончания действия зональной тарифной политики',
            key: 'expiry_date_end',
            validator: (date, row) => row.expiry_date_begin > date,
        },
        {
            type: Array,
            name: 'Зональные тарифы',
            key: 'zone_route_tariffs',
            multiple: true,
            data_from: {
                table: 'zoneroutetariffs'
            },
        },
        {
            type: String,
            name: 'Комментарий',
            key: 'note',
        },
    ],
}
