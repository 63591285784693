import {sort} from "@/constants";

export default {
    tableCode: 'arc_cardsdata',
    tableName: 'arc_cardsdata',
    indexField: [
        'id'
    ],
    defaultOrder: {
        id: sort.ASC,
    },
    tableDescription: [
        {
            type: BigInt,
            name: 'ID',
            key: 'id',
            skip_input: true,
            column_hidden: () => true,
        },
        {
            type: BigInt,
            name: 'Тип карты',
            key: 'card_type',
        },
        {
            type: BigInt,
            name: 'Форма карты',
            key: 'card_form',
        },
        {
            type: String,
            name: 'Описание карты',
            key: 'card_description',
        },
        {
            type: BigInt,
            name: 'Категория',
            key: 'category_id',
        },
        {
            type: BigInt,
            name: 'ID карты',
            key: 'cardsdata_id',
        },
        {
            type: Date,
            name: 'Дата активации',
            key: 'activate_date',
        },
        {
            type: Date,
            name: 'Дата блокировки',
            key: 'block_date',
        }
    ],
}
