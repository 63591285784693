import {sort} from "@/constants";

export default {
    tableCode: 'zoneroutetariffs',
    tableName: 'Зоны маршрута',
    indexField: [
        'zoneroutetariff_id'
    ],
    defaultOrder:
        {
            zoneroutetariff_id: sort.ASC,
        }
    ,
    folderName: 'nsi',
    viewField: 'description',
    tableDescription: [
        {
            type: BigInt,
            name: 'ID',
            key: 'zoneroutetariff_id',
            column_hidden: () => true,
            skip_input: true,
        },
        {
            key: 'description',
            type: String,
            name: 'Описание (для выпадающего списка)'
        },
        {
            key: 'fare',
            type: String,
            name: 'Стоимость проезда (коп.)'
        },
        {
            key: 'coordinat_begin',
            type: String,
            name: 'Географическая координата остановки начала зоны'
        },
        {
            key: 'coordinat_end',
            type: String,
            name: 'Географическая координата остановки окончания зоны',
        }
    ],
}
