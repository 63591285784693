import axios from '@/utils/axios';

export const getList = async ({ commit }) => {
    const { data } = await axios.get(`user`);
    commit('setUsersList', data);
}

export const removeItem = async (_, id) =>
    axios.delete(`user/${id}`)


export const editItem = async (_, payload) =>
    axios.put(`user/${payload.id}`, payload)

export const addItem = async (_, payload) =>
    axios.post(`user`, payload)
