export const mounted = (_this) => {
    console.log('ACCESS WITH',  _this.roles);

    const role = (_this.roles || []).find(r => r.table_code.toLowerCase() === _this.tableCode.toLowerCase());
    console.log(role);

    if (!role || (!role.read && !role.write)) {
        const localRole = _this.roles.find(r => r.read);
        console.log(localRole)
        if (localRole) {
            _this.$router.push(`/dictionary/${localRole.table_code}`);
            return;
        }
        if (_this.$route.path !== '/not-found') {
            _this.$router.push('/not-found');
        }
    }
};
