import {sort} from "@/constants";

export default {
    tableCode: 'addinfo_fieldstypes',
    local: true,
    indexField: ['type'],
    defaultOrder: {
        id: sort.ASC,
    },
    viewField: 'name',
    tableDescription: [
        {
            key: 'type',
            type: String,
        },
        {
            key: 'name',
            type: String,
        }
    ],
    tableContent: [
        { type: 'link', name: 'Ссылка' },
        { type: 'phone', name: 'Телефон' },
        { type: 'email', name: 'Email' },
        { type: 'timeperiod', name: 'Период времени' },
    ]
}
