import {sort} from "@/constants";

export default {
    tableCode: 'blacklist',
    tableName: 'BlackList',
    indexField: [
        'card_number'
    ],
    defaultOrder: {
        card_number: sort.ASC,
    },
    folderName: 'vpb_control',
    tableDescription: [
        {
            type: BigInt,
            name: 'Номер карты',
            key: 'card_number',
            column_hidden: () => true,
            skip_input: true,
        },
        {
            type: Date,
            name: 'Дата блокировки',
            key: 'block_date_time',
        },
        {
            type: Date,
            name: 'Дата разблокировки',
            key: 'unblock_date_time',
        },
        {
            type: BigInt,
            name: 'Причина',
            key: 'reason',
        }
    ],
}
