import {sort} from "@/constants";
import Store from "@/store/store";

export default {
    tableCode: 'controlvpb',
    tableForSelect: 'controlvpb_enriched',
    tableName: 'Транзакции контроля<br> гашения ВПБ',
    isDoubleLine: true,
    indexField: [],
    readOnly: true,
    defaultOrder: {
        operationdatetime: sort.DESC,
    },
    importForceEnabled: true,
    folderName: 'reestr',
    tableDescription: [
        {
            key: 'deviceid',
            type: String,
            name: 'Идентификатор устройства контроля'
        },
        {
            key: 'userid',
            type: BigInt,
            name: 'Идентификатор контролера'
        },
        {
            key: 'idts',
            type: BigInt,
            name: 'Идентификатор транспортного средства'
        },
        {
            type: String,
            name: 'Контактная информация пользователя МП/ЛКП',
            key: 'credentials',
            column_hidden: () => !Store.$store.getters.shallSeeCredentials, // +
        },
        {
            key: 'idroute',
            type: BigInt,
            name: 'Код маршрута'
        },
        {
            key: 'idtrip',
            type: BigInt,
            name: 'Номер рейса'
        },
        {
            key: 'cardid',
            type: BigInt,
            name: 'Идентификатор ВПБ'
        },
        {
            key: 'datagrammid',
            type: String,
            name: 'Идентификатор датаграммы'
        },
        {
            key: 'operationdatetime',
            type: Date,
            name: 'Дата-Время операции'
        },
        {
            key: 'units',
            type: BigInt,
            name: 'Количество списанных единиц'
        },
        {
            key: 'idcarrier',
            type: BigInt,
            name: 'Идентификатор Контрагента-перевозчик'
        },
        {
            key: 'closuretype',
            type: BigInt,
            name: 'Тип гашения'
        },
        {
            key: 'presentationmethod',
            type: BigInt,
            name: 'Метод предъявления '
        },
        {
            key: 'firstuse',
            type: Date,
            name: 'Дата-время активации'
        },
        {
            key: 'trippaid',
            type: BigInt,
            name: 'Проезд оплачен'
        }
    ]
}
