import {sort} from "@/constants";

export default {
    tableCode: 'metrostations',
    tableName: 'MetroStations',
    indexField: [
        'station_id'
    ],
    defaultOrder:
        {
            station_id: sort.ASC,
        }
    ,
    tableDescription: [
        {
            type: BigInt,
            name: 'Станция',
            key: 'station_id',
            column_hidden: () => true,
            skip_input: true,
        },
        {
            type: String,
            name: 'Имя',
            key: 'description',
        },
    ],
}
