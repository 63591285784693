import {sort} from '@/constants';
import {vt_cardsdata_join} from "@/joins";

export default {
    tableCode: 'vt_section',
    tableName: 'Описание ВПБ для МП',
    indexField: [
        'id',
    ],
    folderName: 'nsi',
    defaultOrder: {
        id: sort.ASC,
    },
    viewField: 'title',
    tableDescription: [
        {
            key: 'id',
            skip_input: true,
            column_hidden: () => true,
        },
        vt_cardsdata_join('vt_cardsdata_id', 'Идентификатор ВПБ'),
        {
            key: 'title',
            name: 'Заголовок секции',
            type: String,
        },
        {
            key: 'text',
            name: 'Содержание секции',
            type: String,
        },
        {
            key: 'position',
            name: 'Позиция секции',
            type: BigInt,
            relation: {
                table: 'vt_section_priority',
                join_rules: {
                    position: 'id',
                }
            }
        },
    ]
}
