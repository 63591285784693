<template lang="pug">
  i(:class="`el-icon-${name}`")
</template>

<script>
export default {
  name: 'ArmIcon',
  props: {
    name: {
      type: String,
    }
  },
}
</script>
