import {sort} from "@/constants";

export default {
    tableCode: 'cardcategories',
    tableName: 'Категории ВПБ',
    position: 1,
    indexField: [
        'category_id',
    ],
    defaultOrder: {
        category_id: sort.ASC,
    },
    viewField: 'name',
    folderName: 'nsi',
    tableDescription: [
        {
            key: 'category_id',
            type: BigInt,
            name: 'Идентификатор категории',
            skip_input: true,
        },
        {
            key: 'name',
            type: String,
            name: 'Описание категории'
        },
        {
            key: 'code',
            type: BigInt,
            name: 'Код категории'
        }
    ]

}
