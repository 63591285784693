import {sort} from "@/constants";
import {vt_counterparties_join} from "@/joins";


const viewField = function (row) {
    this.valueOf =
        (for_server = false) =>
            for_server ?
                [
                    'id', 'id_carrier', 'name',
                ] : row.name;
}

export default {
    tableCode: 'issuance_areas',
    tableStorage: 'issuance_areas_2',
    tableName: 'Площадки выпуска',
    folderName: 'contragents',
    indexField: [
        'id',
    ],
    defaultOrder:
        {
            id: sort.ASC,
        }
    ,
    viewField: viewField,
    tableDescription: [
        {
            type: BigInt,
            name: 'Уникальный идентификатор площадки выпуска',
            key: 'id',
            // column_hidden: true,
            // skip_input: true,
        },
        {
            type: String,
            name: 'Наименование',
            key: 'name',
        },
        {
            type: BigInt,
            name: 'Статус',
            key: 'status_id',
            relation: {
                table: 'issuance_areastatuses',
                join_rules: {
                    status_id: 'id',
                },
            }
        },
        {
            type: String,
            name: 'Примечание',
            key: 'note',
        },
        vt_counterparties_join('id_carrier'),
    ],
}
