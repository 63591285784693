import {sort} from "@/constants";

export default {
    tableCode: 'tariffs',
    tableName: 'Tariffs',
    indexField: [
        'id_tariff'
    ],
    defaultOrder: {
        id_tariff: sort.ASC,
    },
    tableDescription: [
        {
            type: BigInt,
            name: 'ID',
            key: 'id_tariff',
        },
        {
            type: BigInt,
            name: 'Тип карты',
            key: 'card_type',
        },
        {
            type: BigInt,
            name: 'Тариф',
            key: 'tariff',
        },
        {
            type: Date,
            name: 'Дата начала',
            key: 'begin_date_time',
        },
        {
            type: Date,
            name: 'Дата окончания',
            key: 'end_date_time',
        },
        {
            type: BigInt,
            name: 'Тип маршрута',
            key: 'route_type',
            relation: {
                table: 'routetypes',
                join_rules: {
                    route_type: 'route_type'
                }
            },
        },
        {
            type: String,
            name: 'Комментарий',
            key: 'comment',
        },
    ],
}
