export const vt_counterparties_join = (field, name) => ({
    type: BigInt,
    name: name || 'Идентификатор контрагента',
    key: field,
    relation: {
        table: 'vt_counterparties',
        join_rules: {
            [field]: 'counterparty_id',
        }
    }
});

export const transporttypes_join = (field, name, multiple = true) => ({
    key: field,
    type: Array,
    name: name || 'Вид транспорта',
    server_decorator: (input) => {
        if (Array.isArray(input)) {
            return input.reduce((a, b) => a + b);
        } else {
            return Number(input)
                .toString(2)
                .split('')
                .reverse()
                .map((i, index) => i * 2 ** index)
                .filter(i => i);
        }
    },
    multiple,
    data_from: {
        table: 'vt_transporttyps'
    },
    // relation: {
    //     table: 'vt_transporttyps',
    //     join_rules: {
    //         [field]: 'id_transport',
    //     },
    // },
})

export const transporttypes_usual_join = (field, name) => ({
    key: field,
    type: Array,
    name: name || 'Вид транспорта',
    multiple: false,
    relation: {
        table: 'vt_transporttyps',
        join_rules: {
            [field]: 'id_transport',
        }
    },
})

export const issuance_areas_join = (field, filter_by, name) => ({
    type: BigInt,
    name: name || 'Площадка выпуска',
    key: field,
    relation: {
        table: 'issuance_areas',
        filter_by: 'id_carrier',
        join_rules: {
            [field]: 'id',
            [filter_by]: 'id_carrier',
        }
    }
});

export const mutualpaymentstypes_join = (field, name) => ({
    type: BigInt,
    name: name || 'Вид взаиморасчетов с перевозчиком',
    key: field,
    relation: {
        table: 'mutualpaymentstypes',
        join_rules: {
            [field]: 'id'
        }
    }
})

export const routetariftypes_join = (field, name) => ({
    type: BigInt,
    name: name || 'Вид тарифа',
    key: field,
    relation: {
        table: 'routetariftypes',
        join_rules: {
            [field]: 'route_tarif_type'
        }
    }
});

export const routetypes_join = (field, name) => ({
    type: BigInt,
    name: name || 'Вид сообщения',
    key: field,
    relation: {
        table: 'routetypes',
        join_rules: {
            [field]: 'route_type'
        }
    },
})

export const counterpartytypes_join = (field, name) => ({
    type: BigInt,
    name: name || 'Тип контрагента',
    key: field,
    relation: {
        table: 'counterpartytypes',
        join_rules: {
            [field]: 'id',
        },
    }
});

export const counterpartyroles_join = (field, name) => ({
    type: BigInt,
    name: name || 'Роль контрагента',
    key: field,
    relation: {
        table: 'counterpartyroles',
        join_rules: {
            [field]: 'id',
        }
    }
});

export const vt_contracts_join = (field, name) => ({
    type: BigInt,
    name: name || 'Договор',
    key: field,
    relation: {
        table: 'vt_contracts',
        join_rules: {
            [field]: 'id',
        }
    }
});

export const counterparties_statuses_join = (field, name) => ({
    type: BigInt,
    name: name || 'Статус',
    key: field,
    relation: {
        table: 'counterparties_statuses',
        join_rules: {
            [field]: 'id'
        }
    }
});

export const device_types_join = (field, name, more) => ({
    type: BigInt,
    name: name || 'Тип оборудования',
    key: field,
    relation: {
        table: 'device_types',
        join_rules: {
            [field]: 'id',
        }
    },
    ...more
});

export const devicestatuses_join = (field, name) => ({
    type: BigInt,
    name: name || 'Статус оборудования',
    key: field,
    relation: {
        table: 'devicestatuses',
        join_rules: {
            [field]: 'id',
        }
    }
});

export const device_manufacturers_join = (field, name) => ({
    type: String,
    name: name || 'Наименование производителя',
    key: field,
    relation: {
        table: 'device_manufacturers',
        join_rules: {
            [field]: 'id',
        }
    }
})

export const vt_cardsdata_join = (field, name) => ({
    type: BigInt,
    key: 'vt_cardsdata_id',
    name: name || 'Название ВПБ',
    relation: {
        table: 'vt_cardsdata',
        join_rules: {
            [field]: 'vt_cardsdata_id'
        }
    }
})
