<template>
  <div>404 not found</div>
</template>

<script>

import {mounted} from "@/pages/rolesHook";
import {mapGetters} from "vuex";

export default {
  name: 'NotFound',
  computed: {
    tableCode() {
      return 'none';
    },
    ...mapGetters([
      'roles',
    ]),
  },
  mounted() {
    mounted(this);
  }
};
</script>
