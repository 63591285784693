import {sort} from "@/constants";
import {
    // mutualpaymentstypes_join,
    routetariftypes_join,
    routetypes_join,
    transporttypes_usual_join,
} from "@/joins";

const viewField = function (row) {
    this.valueOf =
        (for_server = false) =>
            for_server ?
                [
                    'route_name', 'transport_type'
                ] : `${row.route_name}`;
}

export default {
    tableCode: 'vt_routesdata',
    tableForSelect: 'vt_routesdata_enriched',
    tableForFilter: 'vt_routesdata_enriched',
    tableName: 'Маршруты',
    folderName: 'nsi',
    indexField: [
        'id'
    ],
    defaultOrder: {
        id: sort.ASC,
    },
    viewField,
    serverJoin: {
        alias: 'routes',
        joins: [
            {
                table: 'routesbycontract',
                alias: 'rtsb',
                on: 'routes.id = rtsb.route',
                select: 'contracts.mutual_payments_type, counterparties.counterparty_name',
                where: [
                    {
                        key: 'contracts.status_id',
                        operator: '=',
                        value: 2,
                    },
                    {
                        key: 'contracts.contract_datetime',
                        operator: '>=',
                        value: 'CURRENT_TIMESTAMP'
                    },
                    {
                        key: 'contracts.contract_end_datetime',
                        operator: '<=',
                        value: 'CURRENT_TIMESTAMP'
                    }
                ],
                next: {
                    table: 'vt_contracts',
                    alias: 'contracts',
                    on: 'rtsb.contract_id = contracts.id',
                    next: {
                        table: 'vt_counterparties',
                        alias: 'counterparties',
                        on: 'contracts.counterparty_id = counterparties.counterparty_id'
                    }
                }
            }
        ]
    },
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор маршрута',
            key: 'id',
            // skip_input: true,
        },
        {
            type: String,
            name: 'Номер маршрута',
            key: 'route_name',
        },
        {
            type: String,
            name: 'Наименование маршрута',
            key: 'route_full_name',
        },
        {
            type: Array,
            key: 'track',
            name: 'Трасса маршрута',
            multiple: true,
            default_value: () => [],
            data_from: {
                table: 'zone_stations',
            },
        },
        // mutualpaymentstypes_join('ordertype'),
        routetariftypes_join('route_tarif_type'),
        {
            key: 'counterparty_id',
            name: 'Перевозчик',
            type: Array,
            server_decorator: (input) => input || [],
            skip_input: true,
            // virtual_field: true,
            skip_filter: true,
            ignore_on_save: true,
            data_from: {
                table: 'vt_counterparties',
            },
            // relation: {
            //     join_index: 'counterparty_id',
            //     join_rules: {
            //         counterparty_id: 'counterparty_id',
            //     }
            // }
        },
        // vt_counterparties_join('id_carrier', 'Допущенные к работе перевозчики'),
        routetypes_join('route_type'),
        transporttypes_usual_join('id_transport_type'),
        // {
        //     type: BigInt,
        //     name: 'Заказчик перевозок',
        //     key: 'id_customer',
        //     relation: {
        //         table: 'transportcustomers',
        //         join_rules: {
        //             id_customer: 'id_customer',
        //         },
        //     }
        // },
        {
            type: String,
            key: 'note',
            name: 'Дополнительная информация',
        },
        // {
        //     type: Date,
        //     name: 'Дата активации',
        //     key: 'activate_date',
        // },
        // {
        //     type: Date,
        //     name: 'Дата блокировки',
        //     key: 'block_date',
        // },

    ],
}
