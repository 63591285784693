<template lang="pug">
  universal-table(
    :table-code="tableCode"
    :table-name="tableName"
    :index-field="indexField"
    :table-description="tableDescription"
    :default-order="defaultOrder"
    :disable-operations="localReadOnly"
    :skip-add="wrappedSkipAdd"
    :view-field="viewField"
    :expand="expandWrapped"
    :export-limit-on="_exportLimitOn"
    :import-force-enabled="_importForceEnabled"
    :import-table-name="_importTableName"
  )


</template>
<script>
import UniversalTable from "@/pages/UniversalTable";
import tables from "@/tables";
import { mapGetters } from 'vuex';
import {mounted} from "@/pages/rolesHook";

export default {
  components: {
    UniversalTable,
  },
  data() {
    const table = tables[this.$route.params.name];
    return table ? {...table()} : {}
  },
  computed: {
    _importForceEnabled() {
      return this.importForceEnabled;
    },
    _importTableName() {
      return this.importTableName;
    },
    _exportLimitOn() {
      return this.exportLimitOn || false;
    },
    expandWrapped() {
      return this.expand || false;
    },
    wrappedSkipAdd() {
      return this.skipAdd || false;
    },
    ...mapGetters([
        'roles',
    ]),
    localReadOnly() {
      try {
        if (this.readOnly) return true;
        const role = this.roles.find(r => r.table_code.toLowerCase() === this.tableCode.toLowerCase());

        return role.write === false;
      } catch (e) {
        console.log('info', e, this.tableCode);
        return false;
      }
    },
  },
  mounted() {
    mounted(this)
  }
}
</script>
