<template lang="pug">

  el-dialog#import_csv(
    :title="data.originalFilename"
    :visible="visible"
    v-on:update:visible="val => $emit('update:visible', val)"
    v-on:close="closeModal"
    width="30%"
    v-loading="loading"
    center
  )
    el-col.inline__block
      el-row
        el-button(v-if='updateAllowed' type="warning" v-on:click="confirmUpdate") Обновить
        el-button(type="success" v-on:click="addToTable") Добавить

</template>

<script>
import {sleep} from "@/utils/sleep";

export default {
  name: "ImportCSV",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
    },
    tableCode: {
      type: String,
    }
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    filepath() {
      return this.data.filepath;
    },
    updateAllowed() {
      if (this.tableCode !== 'blacklist') return true;

      return this.$store.getters.role === 'administrator';
    },
  },
  methods: {

    async confirmUpdate() {
      try {
        await this.$confirm('Текущие данные будут удалены. Продолжить?', 'Внимание', {
          confirmButtonText: 'Да!',
          cancelButtonText: 'Нет!',
          type: 'warning'
        });
      } catch (e) {
        this.$message({
          type: 'info',
          message: 'Операция отменена'
        });
        return;
      }
      await this.updateTable();
    },

    async updateTable() {
      this.loading = true;
      try {
        const result = await this.$store.dispatch('unitable/fileOperations', {
          table_name: this.tableCode,
          operation: 'update',
          filepath: this.filepath,
        });
        this.checkError(result);
        await sleep(1e3);
        this.loading = false;
        this.$emit('update:visible', false);
        this.$emit('update-success')
      } catch (e) {
        this.loading = false;
        this.$emit('update:visible', false);
      }
      //alert('update ' + this.data.filepath)
    },
    checkError(data) {
      if (data.status !== 'fail') {
        this.$notify({
          type: "success",
          message: "Данные успешно обновлены",
        });
        return;
      }

      const h = this.$createElement;
      this.$msgbox({
        title: 'Ошибка импорта!',
        message: h('p', { style: 'height: 80vh; overflow-y: scroll' }, [
          h('pre', { style: 'text-wrap: pretty' }, data.message),
          h('b', null, `Импортировано успешно рядов: ${data.success_count}`),
          h('br'),
          h('b', null, `Импортировано неуспешно рядов: ${data.fail_count}`)
        ]),
        confirmButtonText: 'OK',
      })
    },
    async addToTable() {
      try {
        this.loading = true;
        const result = await this.$store.dispatch('unitable/fileOperations', {
          table_name: this.tableCode,
          operation: 'add',
          filepath: this.filepath,
        });

        this.checkError(result);

        await sleep(1e3);
        this.loading = false;
        this.$emit('update:visible', false);
        this.$emit('update-success')
      } catch (e) {
        this.loading = false;
        this.$emit('update:visible', false);
      }
      //alert('add ' + this.data.filepath)
    },
    closeModal() {
      this.$store.dispatch('unitable/fileOperations', {
        table_name: this.tableCode,
        operation: 'remove',
        filepath: this.filepath,
      });
      //alert('close ' + this.data.filepath);
    },
  }
}
</script>

<style scoped lang="stylus">
  .inline__block
    display inline-block
</style>

<style lang="stylus">
#import_csv .el-dialog__body
  display inline-block
</style>
