import {sort} from "@/constants";

export default {
    tableCode: 'vt_counterparties_carriers',
    tableName: 'Контрагенты-Перевозчики',
    folderName: 'nsi',
    indexField: [
        'counterparty_id'
    ],
    defaultOrder: {
        counterparty_id: sort.ASC,
    },
    viewField: 'counterparty_name',
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор контрагента',
            key: 'counterparty_id',
            column_hidden: () => true,
            skip_input: true,
        },
        {
            type: String,
            name: 'Наименование контрагента',
            key: 'counterparty_name',
        },
    ],
}
