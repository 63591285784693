import {sort} from "@/constants";

export default {
    tableCode: 'exch_mutualpaymentstypes',
    tableName: 'Виды взаиморасчетов для наценок',
    folderName: 'vpb_control',
    indexField: [
        'id'
    ],
    defaultOrder:
        {
            id: sort.ASC,
        }
    ,
    viewField: 'description',
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор',
            key: 'id',
            // skip_input: true,
            // column_hidden: true,
        },
        {
            type: String,
            name: 'Вид взаиморасчета',
            key: 'description',
        },
        {
            type: String,
            name: 'Описание',
            key: 'note',
        },
    ],
}
