import { render, staticRenderFns } from "./UniversalTable.vue?vue&type=template&id=98804b74&scoped=true&lang=pug&"
import script from "./UniversalTable.vue?vue&type=script&lang=js&"
export * from "./UniversalTable.vue?vue&type=script&lang=js&"
import style0 from "./UniversalTable.vue?vue&type=style&index=0&id=98804b74&prod&lang=stylus&scoped=true&"
import style1 from "./UniversalTable.vue?vue&type=style&index=1&id=98804b74&prod&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98804b74",
  null
  
)

export default component.exports