import {sort} from "@/constants";

export default {
    tableCode: 'bonustypes',
    tableName: 'Виды вознаграждения',
    indexField: [
        'id'
    ],
    defaultOrder: {
        id: sort.ASC,
    },
    viewField: 'description',
    folderName: 'vpb_control',
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор вида вознаграждения',
            key: 'id',
            // column_hidden: true,
            // skip_input: true,
        },
        {
            type: String,
            name: 'Вид вознаграждения',
            key: 'description',
        },
    ],
}
