import {sort} from "@/constants";

export default {
    tableCode: 'devices_access_statuses',
    tableName: 'Типы датаграмм',
    viewField: 'name',
    indexField: [
        'id',
    ],
    defaultOrder: {
        id: sort.ASC,
    },
    local: true,
    tableDescription: [
        {
            key: 'id',
            type: String,
        },
        {
            key: 'name',
            type: String,
        }
    ],
    tableContent: [
        { id: 'active', name: 'Активно' },
        { id: 'blocked', name: 'Заблокировано' },
        { id: 'deleted', name: 'Удалено (ошибка)' },
        { id: 'removed', name: 'Удалено (ошибка)' },
    ]
}
