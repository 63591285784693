import {sort} from "@/constants";

export default {
    tableCode: 'vt_security_algoritms',
    tableName: 'Алгоритмы последовательностей',
    folderName: 'vpb_control',
    indexField: [
        'id'
    ],
    defaultOrder: {
        id: sort.ASC,
    },
    viewField: 'contracttype',
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор',
            key: 'id',
            skip_input: true,
            column_hidden: () => true,
        },
        {
            type: String,
            name: 'Алгоритм',
            key: 'algoritm',
        },
        {
            type: String,
            name: 'Описание',
            key: 'note',
        },
    ],
}
