import {sort} from "@/constants";
import Store from "@/store/store";

export default {
    tableCode: 'datagramm',
    tableForSelect: 'datagramm_enriched',
    tableName: 'Датаграммы',
    indexField: [
        'guidticket'
    ],
    folderName: 'reestr',
    defaultOrder: {
        createdatetime: sort.DESC,
    },
    importForceEnabled: true,
    readOnly: true,
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор виртуальной карты',
            key: 'cardid',
        },
        // {
        //     name: 'Тип ПБ',
        //     key: 'tickettype',
        //     type: BigInt,
        // },
        // {
        //     name: 'Вид ПБ',
        //     key: 'ticketsubtype',
        //     type: BigInt,
        // },
        {
            type: BigInt,
            key: 'vt_cardsdata_id',
            name: 'Идентификатор типа/вида ПБ',
            relation: {
                table: 'vt_cardsdata',
                join_rules: {
                    vt_cardsdata_id: 'vt_cardsdata_id',
                }
            }
        },
        {
            type: BigInt,
            key: 'datagrammtype',
            name: 'Тип датаграммы',
            relation: {
                table: 'datagrammtypes',
                join_rules: {
                    datagrammtype: 'id',
                },
            }
        },
        {
            type: String,
            key: 'units',
            name: 'Ресурс'
        },
        {
            type: String,
            name: 'Контактная информация пользователя МП/ЛКП',
            key: 'credentials',
            column_hidden: () => !Store.$store.getters.shallSeeCredentials, // +
        },
        {
            type: String,
            key: 'deviceid',
            name: 'Идентификатор мобильного устройства',
        },
        {
            type: String,
            key: 'guidticket',
            name: 'Идентификатор датаграммы',
            search_validate: (guidticket) => {
                if (/^[a-f\d]{8}(-[a-f\d]{4}){3}-[a-f\d]{12}$/.test(guidticket)) return true;
                throw 'Идентификатор датаграммы должен быть в формате UUID'
            }
        },
        {
            type: Date,
            name: 'Дата время создания',
            key: 'createdatetime',
        },
        {
            type: BigInt,
            name: 'Подпись',
            key: 'signature',
        },
        {
            type: BigInt,
            name: 'Статус',
            key: 'status',
            relation: {
                table: 'vt_datagrammstatus',
                join_rules: {
                    status: 'statuscode',
                }
            }
        },
        {
            type: BigInt,
            name: 'Срок жизни',
            key: 'datagrammlifetime',
        }
    ],
}
