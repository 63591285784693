export const role = state => state.user.group_type;

export const user = state => state.user;

export const roles = state => state.roles;

export const restrictions = state => state.restrictions;

export const shallSeeCredentials = state => state.user.ppa_users_view;

export const appVersion = state => state.appVersion;
