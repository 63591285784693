import {sort} from "@/constants";

const viewField = function (row) {
    this.valueOf =
        (for_server = false) =>
            for_server ?
                [
                    'vt_type', 'vt_subtype',
                    'shortdescription', 'description'
                ] : `(${row.vt_type}/${row.vt_subtype}) ${row.shortdescription || row.description}`;
}

export default {
    tableCode: 'vt_cardsdata_for_select',
    tableName: 'Типы ВПБ',
    folderName: 'nsi',
    indexField: [
        'vt_cardsdata_id',
    ],
    position: 2,
    defaultOrder: {
        vt_cardsdata_id: sort.ASC,
    },
    viewField: viewField, ////  make here arrow function based on row param
    tableDescription: [
        {
            type: BigInt,
            name: 'ID',
            key: 'vt_cardsdata_id',
            skip_input: true,
            column_hidden: () => true,
        },
        {
            type: BigInt,
            name: 'Тип ВПБ',
            key: 'vt_type',
        },
        {
            type: BigInt,
            name: 'Вид ВПБ',
            key: 'vt_subtype',
        },
        {
            type: String,
            name: 'Наименование',
            key: 'description',
        },
        {
            type: String,
            key: 'shortdescription',
            name: 'Краткое наименование билета'
        },
    ],
}
