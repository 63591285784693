import {sort} from "@/constants";
import Store from "@/store/store";

export default {
    tableCode: 'virtualcards',
    tableForSelect: 'virtualcards_enriched_restrict',
    tableName: 'Виртуальные носители',
    folderName: 'reestr',
    indexField: [
        'cardid'
    ],
    defaultOrder: {
        createdatetime: sort.DESC,
    },
    importForceEnabled: true,
    readOnly: true,
    viewField: 'cardid',
    tableDescription: [
        {
            type: BigInt,
            name: 'Номер ВПБ',
            key: 'cardid',
        },
        {
            type: BigInt,
            key: 'userid',
            name: 'Идентификатор пользователя МП/ЛКП',
        },
        {
            type: String,
            key: 'credentials',
            name: 'Контактная информация пользователя МП/ЛКП',
            column_hidden: () => !Store.$store.getters.shallSeeCredentials, // +
        },
        {
            type: Date,
            key: 'createdatetime',
            name: 'Дата/время выпуска ВПБ'
        },
        // {
        //     key: 'securitytype',
        //     type: BigInt,
        //     name: 'Идентификатор защитной последовательности'
        // },
        {
            key: 'agentid',
            type: BigInt,
            name: 'Идентификатор контрагента',
            relation: {
                table: 'vt_counterparties',
                join_rules: {
                    agentid: 'counterparty_id'
                }
            }
        },
    ],
}
