import {sort} from "@/constants";

export default {
    tableCode: 'contract_kinds',
    tableName: 'Виды договоров',
    folderName: 'vpb_control',
    indexField: [
        'id'
    ],
    defaultOrder:
        {
            id: sort.ASC,
        }
    ,
    viewField: 'contractkind',
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор',
            key: 'id',
            // skip_input: true,
            // column_hidden: true,
        },
        {
            type: String,
            name: 'Вид договора',
            key: 'contractkind',
        },
        {
            type: String,
            name: 'Описание',
            key: 'note',
        },
    ],
}
