import {sort} from "@/constants";
import {vt_counterparties_join} from "@/joins";


export default {
    tableCode: 'issuance_areas',
    tableName: 'Площадки выпуска',
    folderName: 'contragents',
    indexField: [
        'id', 'id_carrier'
    ],
    defaultOrder:
        {
            id: sort.ASC,
        }
    ,
    viewField: 'name',
    tableDescription: [
        {
            type: BigInt,
            name: 'ID',
            key: 'id',
            // column_hidden: true,
            skip_input: true,
        },
        vt_counterparties_join('id_carrier'),
        {
            type: String,
            name: 'Наименование площадки',
            key: 'name',
        },
        {
            type: BigInt,
            name: 'Статус',
            key: 'status_id',
            relation: {
                table: 'issuance_areastatuses',
                join_rules: {
                    status_id: 'id',
                },
            }
        },
        {
            type: String,
            name: 'Примечание',
            key: 'note',
        },
    ],
}
