import {sort} from "@/constants";
import Store from "@/store/store";

export default {
    tableCode: 'testvirtualticket',
    tableForSelect: 'testvirtualticket_enriched',
    tableName: 'Тестовые ВПБ',
    indexField: ['cardid'],
    defaultOrder: {
        cardid: sort.ASC,
    },
    folderName: 'test_vpb',
    validate: {
        all(row) {
            if (!row.cardid) {
                throw new Error('Укажите номер виртуального носителя')
            }
        }
    },
    tableDescription: [
        {
            key: 'cardid',
            type: BigInt,
            name: 'Номер виртуального носителя',
            relation: {
                table: 'virtualcards',
                join_rules: {
                    cardid: 'cardid',
                }
            },
        },
        {
            type: BigInt,
            key: 'userid',
            name: 'Идентификатор пользователя МП/ЛКП',
            skip_input: true,
        },
        {
            type: String,
            key: 'credentials',
            name: 'Контактная информация пользователя МП/ЛКП',
            column_hidden: () => !Store.$store.getters.shallSeeCredentials, // +
            skip_input: true,
        },
        {
            type: Date,
            key: 'createdatetime',
            name: 'Дата/время выпуска ВПБ',
            skip_input: true,
        },
        {
            key: 'agentid',
            type: BigInt,
            name: 'Идентификатор контрагента',
            skip_input: true,
            relation: {
                table: 'vt_counterparties',
                join_rules: {
                    agentid: 'counterparty_id'
                }
            }
        },
        {
            key: 'note',
            type: String,
            name: 'Примечание'
        },
    ]
}
