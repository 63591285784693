import {sort} from "@/constants";

export default {
    tableCode: 'vt_typesbycontract',
    tableName: 'Типы ВПБ, разрешенные<br> для реализации по договору',
    folderName: 'nsi',
    isDoubleLine: true,
    indexField: [
        'contract_id',
        'tickettype',
        'ticketsubtype'
    ],
    defaultOrder: {
        contract_id: sort.ASC,
    },
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор договора',
            key: 'contract_id',
            relation: {
                table: 'vt_contracts',
                join_rules: {
                    contract_id: 'id'
                },
            }
        },
        {
            type: BigInt,
            name: 'Тип ВПБ, доступного для реализации по договору',
            key: 'tickettype',
        },
        {
            type: BigInt,
            name: 'Вид ВПБ, доступного для реализации по договору',
            key: 'ticketsubtype',
        },
        {
            type: String,
            name: 'Примечание',
            key: 'note',
        },
    ],
}
