import {sort} from "@/constants";
import Store from "@/store/store";

export default {
    tableCode: 'sodtrans',
    tableForSelect: 'sodtrans_enriched',
    tableName: 'Транзакции <br>пополнения/продажи ВПБ',
    indexField: ['id_sod_trans'],
    folderName: 'reestr',
    isDoubleLine: true,
    defaultOrder: {
        reg_date_time: sort.DESC,
    },
    importForceEnabled: true,
    readOnly: true,
    tableDescription: [
        {
            key: 'id_sod_trans',
            type: BigInt,
            column_hidden: () => true,
            name: 'Номер по порядку',
        },
        {
            key: 'card_number',
            type: BigInt,
            name: 'Номер карты'
        },
        {
            key: 'vpbagentid',
            type: BigInt,
            name: 'Контрагент',
            relation: {
                table: 'vt_counterparties',
                join_rules: {
                    vpbagentid: 'counterparty_id'
                }
            }
        },
        {
            type: String,
            name: 'Контактная информация пользователя МП/ЛКП',
            key: 'credentials',
            column_hidden: () => !Store.$store.getters.shallSeeCredentials, // +
        },
        {
            type: BigInt,
            key: 'vt_cardsdata_id',
            name: 'Идентификатор типа/вида ПБ',
            relation: {
                table: 'vt_cardsdata',
                join_rules: {
                    vt_cardsdata_id: 'vt_cardsdata_id',
                }
            }
        },
        {
            key: 'card_sub_type',
            type: BigInt,
            name: 'Вид карты'
        },
        {
            key: 'priceperone',
            type: BigInt,
            name: 'Цена',
        },
        // {
        //     key: 'debit_unit',
        //     type: BigInt,
        //     name: 'Размер списания'
        // },
        {
            key: 'reg_date_time',
            type: Date,
            name: 'Дата-время операции'
        },
        {
            key: 'sum_payment',
            type: BigInt,
            name: 'Сумма операции'
        },
        {
            key: 'sum_purse',
            type: BigInt,
            name: 'Баланс'
        },
        {
            key: 'transport_type',
            type: BigInt,
            name: 'Тип транспорта'
        },
        {
            key: 'begin_date_time',
            type: Date,
            name: 'Дата начала срока действия'
        },
        {
            key: 'end_date_time',
            type: Date,
            name: 'Конец срока действия'
        },

    ],
}
