export default {
    tableCode: 'history',
    tableName: 'history',
    indexField: [],
    folderName: 'users_management',
    defaultOrder: [

    ],
    ro: true,
    tableDescription: [
        {
            type: Date,
            name: 'Дата-время операции',
            key: 'datetime',
        },
        {
            type: String,
            name: 'Имя пользователя',
            key: 'username',
        },
        {
            type: String,
            name: 'IP адрес',
            key: 'userip',
        },
        {
            type: String,
            name: 'Тип операции',
            key: 'typeoperation',
        },
        {
            type: String,
            name: 'Изменяемая таблица',
            key: 'tablename',
        },
        {
            type: String,
            name: 'Изменяемое поле',
            key: 'columnname',
        },
        {
            type: String,
            key: 'olddata',
            name: 'Старое значение',
        },
        {
            type: String,
            key: 'newdata',
            name: 'Новое значение',
        }

    ],
}
