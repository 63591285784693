import {sort} from "@/constants";

export default {
    tableCode: 'contract_types',
    tableName: 'Типы договоров',
    folderName: 'vpb_control',
    indexField: [
        'id'
    ],
    defaultOrder:
        {
            id: sort.ASC,
        }
    ,
    viewField: 'contracttype',
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор',
            key: 'id',
            column_hidden: () => true,
            skip_input: true,
        },
        // {
        //     key: 'device_types',
        //     type: Array,
        //     name: 'Типы девайсов',
        //     data_from: {
        //         table: 'device_types'
        //     }
        // },
        {
            type: String,
            name: 'Вид договоров',
            key: 'contracttype',
        },
        {
            type: String,
            name: 'Описание',
            key: 'note',
        },
    ],
}
