import {sort} from "@/constants";


const viewField = function (row) {
    this.valueOf =
        (for_server = false) =>
            for_server ?
                [
                    'station_name', 'direction'
                ] : `${row.station_name} (${row.direction})`;
}

export default {
    tableCode: 'zone_stations',
    tableName: 'Остановки',
    indexField: ['station_id'],
    defaultOrder: {
        station_id: sort.ASC,
    },
    viewField: viewField,
    folderName: 'nsi',
    tableDescription: [
        {
            key: 'station_id',
            skip_input: true,
            name: 'ID станции',
            type: BigInt,
        },
        {
            key: 'station_name',
            type: String,
            name: 'Остановка'
        },
        {
            key: 'direction',
            type: String,
            name: 'Направление',
        },
        {
            key: 'zone_id',
            type: BigInt,
            name: 'Зона',
            relation: {
                table: 'zones',
                join_rules: {
                    zone_id: 'zone_id'
                }
            }
        },
        {
            key: 'longitude',
            type: String,
            name: 'Координаты (долгота)',
        },
        {
            key: 'latitude',
            type: String,
            name: 'Координаты (широта)',
        },
    ]
}
