import ReportsList from './../../pages/Reports/reports'
import {sort} from "@/constants";

export default {
    tableCode: 'reports_list',
    indexField: [
        'key'
    ],
    viewField: 'name',
    local: true,
    defaultOrder: {
        key: sort.ASC,
    },
    tableDescription: [
        {
            key: 'key',
            type: String,
        },
        {
            key: 'name',
            type: String,
        }
    ],
    tableContent: Object.keys(ReportsList).map(key => ({
        key,
        name: ReportsList[key].name,
    }))

}
