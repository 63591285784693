import {sort} from "@/constants";

export default {
    tableCode: 'tarifclass',
    tableName: 'Классы ВПБ',
    folderName: 'nsi',
    indexField: [
        'classid'
    ],
    defaultOrder: {
        classid: sort.ASC,
    },
    viewField: 'classdescription',
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор класса',
            key: 'classid',

        },
        {
            type: String,
            name: 'Название класса',
            key: 'classdescription',
        },
    ],
}
