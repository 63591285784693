import VueRouter from "vue-router";
import NSILogin from "@/pages/Login";
import Agreements from "@/pages/Agreements";
import UniversalPage from "@/pages/UniversalPage";
import NotFound from "@/pages/NotFound";
import VT_Counterparties from "@/pages/VT_Counterparties";
import SystemUsers from "@/pages/SystemUsers";
import Action_history from "@/pages/action_history";
import logdata from "@/pages/logdata";
import Reports from "@/pages/Reports/ReportsList";
import ReportItem from "@/pages/Reports/ReportItem";
import securitydata from "@/pages/securitydata";
import Devices from "@/pages/Devices";
import vt_security_rel_key_sam_view from "@/pages/vt_security_rel_key_sam_view";


const routes = [
    { path: '/agreements', component: Agreements, name: 'Agreements' },
    { path: '/login', component: NSILogin, name: 'Login' },
    { path: '/reports', component: Reports, name: 'reports' },
    { path: '/dictionary/vt_counterparties', component: VT_Counterparties, name: 'VTCounterparties' },
    { path: '/dictionary/vt_security_data_view', component: securitydata, name: 'vt_security_data_view' },
    { path: '/dictionary/vt_security_rel_key_sam_view', component: vt_security_rel_key_sam_view, name: 'vt_security_data_view' },
    { path: '/dictionary/system_users', component: SystemUsers, name: 'SystemUsers' },
    { path: '/dictionary/action_history', component: Action_history, name: 'ActionHistory' },
    { path: '/dictionary/devices', component: Devices, name: 'ActionHistory' },
    { path: '/dictionary/logdata', component: logdata, name: 'logdata' },
    { path: '/dictionary/:name', component: UniversalPage, name: 'UniversalPage' },
    { path: '/reports/:report', component: ReportItem, name: 'ReportItem' },
    { path: '*', component: NotFound }
]

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
