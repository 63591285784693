import {sort} from "@/constants";

export default {
    tableCode: 'devicestatuses',
    tableName: 'Статусы оборудования',
    folderName: 'vpb_control',
    indexField: [
        'id'
    ],
    defaultOrder: {
            id: sort.ASC,
    },
    readOnly: true,
    viewField: 'description',
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор',
            key: 'id',
        },
		{
            type: BigInt,
            name: 'Статус',
            key: 'status',
        },
        {
            type: String,
            name: 'Описание',
            key: 'description',
        },
    ],
}
