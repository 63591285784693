import {sort} from "@/constants";

export default {
    tableCode: 'vt_additional_agreement',
    tableName: 'Дополнительные соглашения',
    folderName: 'contragents',
    indexField: [
        'id',
    ],
    viewField: 'add_agr',
    defaultOrder: {
        id: sort.ASC,
    },
    tableDescription: [
        {
            type: BigInt,
            key: 'id',
            name: 'Внутренний идентификатор доп. соглашения'
        },
        {
            type: BigInt,
            key: 'contract_id',
            name: 'Идентификатор договора, для которого заключено данное доп.соглашение',
        },
        {
            type: String,
            key: 'add_agr',
            name: 'Название доп. соглашения',
        },
        {
            type: String,
            key: 'add_agr_number',
            name: 'Номер доп. соглашения',
        },
        {
            type: BigInt,
            key: 'add_agr_type',
            name: 'Тип договора (доп. соглашения)',
        },
        {
            type: BigInt,
            key: 'add_agr_kind',
            name: 'Вид договора (доп. соглашения)',
        },
        {
            type: Date,
            key: 'add_agr_datetime',
            name: 'Дата заключения доп. соглашения',
        },
        {
            type: Date,
            key: 'add_agr_end_datetime',
            name: 'Окончание срока действия доп. соглашения',
        },
        {
            type: BigInt,
            key: 'extra_charge_id',
            name: 'Код правила наценки',
        },
        {
            type: BigInt,
            key: 'bonuses_id',
            name: 'Код правила расчета вознаграждения',
        },
        {
            type: BigInt,
            key: 'mutual_payments_type',
            name: 'Вид взаиморасчетов',
        },
        {
            type: BigInt,
            key: 'status_id',
            name: 'Статус',
        },
        {
            type: String,
            key: 'note',
            name: 'Примечание'
        }
    ],
}
