import {sort} from "@/constants";

export default {
    tableCode: 'vt_transporttyps',
    tableName: 'Виды транспорта',
    folderName: 'vpb_control',
    indexField: [
        'id_transport'
    ],
    defaultOrder: {
        id_transport: sort.ASC,
    },
    // readOnly: true,

    viewField: 'transportname',
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор',
            key: 'id_transport',
        },
        {
            type: String,
            name: 'Вид транспорта',
            key: 'transportname',
        },
        // {
        //     type: String,
        //     name: 'Тип транспорта(англ)',
        //     key: 'description_en',
        // },
    ],
}
