import {sort} from "@/constants";
import Store from "@/store/store";

export default {
    tableCode: 'ticketmony',
    tableForSelect: 'ticketmony_enriched',
    tableName: 'ВПБ на количество единиц',
    indexField: [
        'cardid'
    ],
    importForceEnabled: true,
    folderName: 'reestr',
    defaultOrder:
        {
            lastuse: sort.DESC,
        }
    ,
    readOnly: true,
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор виртуальной карты',
            key: 'cardid',
        },
        {
            type: String,
            name: 'Контактная информация пользователя МП/ЛКП',
            key: 'credentials',
            column_hidden: () => !Store.$store.getters.shallSeeCredentials, // +
        },
        {
            type: BigInt,
            key: 'datatype',
            name: 'Тип данных',
            relation: {
                table: 'carddatatypes',
                join_rules: {
                    datatype: 'datatype_id',
                },
            },
        },
        {
            type: BigInt,
            key: 'vt_cardsdata_id',
            name: 'Идентификатор типа/вида ПБ',
            relation: {
                table: 'vt_cardsdata',
                join_rules: {
                    vt_cardsdata_id: 'vt_cardsdata_id',
                }
            }
        },
        // {
        //     type: BigInt,
        //     key: 'ticketsubtype',
        //     name: 'Вид билета',
        // },
        {
            type: Date,
            key: 'lastuse',
            name: 'Дата/время последнего использования',
            decorator: (value) => value.toString() === '1970-01-01T00:00:00.000Z' ? 'decorated:Не использовался' : value,
        },
        {
            type: BigInt,
            name: 'Баланс (коп.)',
            key: 'balance',
        },
        {
            type: BigInt,
            name: 'Блокированный баланс',
            key: 'blockedbalance',
        },
        {
            type: BigInt,
            name: 'Статус',
            key: 'status',
            relation: {
                table: 'vt_ticketstatus',
                join_rules: {
                    status: 'status'
                }
            },
        },
        // {
        //     type: BigInt,
        //     key: 'dailycounter',
        //     name: 'Счетчик поездок',
        // },
        {
            type: BigInt,
            key: 'favorite',
            name: 'Признак предпочтительности карты',
        },
    ],
}
