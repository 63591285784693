<template lang="pug">
  el-container.flex
    h2 {{ reportData.name }}
    el-row(v-loading="loading" v-if="initComplete")
      el-form.reports_filter_form
        template(v-for="item in filtersData")

          template(v-if="item.type === BigInt")
            el-form-item(:prop="item.key")
              span(:style="{display: 'block', ['line-height']: item.doubleLine ? '20px' : '40px'}") {{ item.name }}
              el-input.input__column(
                type="string"
                :placeholder="item.name"
                :disabled="enabled_if(item)"
                v-on:input="val => insertBigNumber(val, item.key)"
                :value="localData[item.key]"
              )

          template(v-if="item.type === Array")
            el-form-item(:prop="item.key")
              span(:style="{display: 'block', ['line-height']: item.doubleLine ? '20px' : '40px'}") {{ item.name }}
              el-select.input__column(
                :multiple="item.multiple"
                :placeholder="item.placeholder"
                v-model="localData[item.key]"
                filterable
                clearable
              )
                el-option(
                  v-for="option in item.values()"
                  :key="option.value"
                  :label="option.name"
                  :value="option.value"
                )

          template(v-else-if="item.type === Number")
            el-form-item(:prop="item.key")
              span(:style="{display: 'block', ['line-height']: item.doubleLine ? '20px' : '40px'}") {{ item.name }}
              el-input.input__column(
                type="number"
                :placeholder="item.name"
                :disabled="enabled_if(item)"
                v-model="localData[item.key]"
              )

          template(v-else-if="item.type === Date")
            el-form-item.date-layout-fix(:prop="item.key")
              span(:style="{display: 'block', ['line-height']: item.doubleLine ? '20px' : '40px'}") {{ item.name }}
              el-date-picker.input__column(
                style='width: 250px'
                :picker-options="{firstDayOfWeek: 1}"
                :disabled="enabled_if(item)"
                v-model="localData[item.key]"
                type="daterange"
              )

          template(v-else-if="item.type === String")
            el-form-item(:prop="item.key")
              span(:style="{display: 'block', ['line-height']: item.doubleLine ? '20px' : '40px'}") {{ item.name }}
              el-input.input__column(
                :disabled="enabled_if(item)"
                :placeholder="item.name"
                v-model="localData[item.key]"
              )

        el-form-item
          span(style="display: block") Подписанты
          el-select.input__column(
            placeholder="Подписанты"
            v-model="localData.signer"
            :multiple="true"
            filterable
            clearable
          )
            el-option(
              v-for="option in signatoryTable"
              :key="option.value"
              :label="option.name"
              :value="option.value"
            )

        template(v-if="reportData.responsibleOn")
          el-form-item
            span(style="display: block") Исполнитель
            el-select.input__column(
              placeholder="Исполнитель"
              v-model="localData.responsible"
              filterable
              clearable
            )
              el-option(
                v-for="option in signatoryTable"
                :key="option.value"
                :label="option.name"
                :value="option.value"
              )

        //template(v-if="isNew")
        //  el-form-item
        //    el-checkbox(v-model="oldWay") Включить старый режим

    el-row(style="padding-left: 10px; margin-bottom: 20px" v-show="!loading")
      el-button(type="primary" v-on:click="generate") Предпросмотр отчета

      el-button(type="success" v-on:click="() => generate(false)") Скачать отчет

      el-upload.upload-csv(
        v-if='reportData.upload'
        ref="upload-xlsx"
        :headers="{authorization: authToken()}"
        action="/api/v1/files/add"
        :before-upload="setLoading"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet\n"
        :on-success="workWithReport"
      ): el-button(
        slot="trigger"
      ) Загрузить отчет для сверки

    #xlsx-container(v-show="!loading && reportReady" v-if="!oldWay" v-html="reportContent")
    iframe#xlsx-container(v-if="oldWay" :src="reportUrl")

</template>

<script>

import axios from 'axios';
import Reports from './reports.js';
// import ExcelViewer from "excel-viewer";

const viewField = function () {
  this.valueOf = () => ['id', 'name', 'job_title'];
}

export default {
  name: 'ReportItem',
  data: () => ({
    report: undefined,
    loading: true,
    localData: {
      // card_number: 781805182925,
    },
    initComplete: false,
    BigInt, Array,
    reportReady: false,
    reportUrl: undefined,
    reportContent: '',
    oldWay: false,
  }),
  computed: {
    reportData() {
      return Reports[this.report] || {};
    },
    filtersData() {
      return this.reportData.filters || [];
    },
    isNew() {
      return this.reportData?.url?.includes('universal');
    },
    reportUrlWrapped() {
      if (this.oldWay) {
        return this.reportData.url.replace('universal/', '');
      }
      return this.reportData.url;
    },
    signatoryTable() {
      return this.$store?.getters['unitable/getTable']('vt_signatory_l')?.map(item => ({
        value: item.id,
        name: `${item.name} ${item.job_title}`
      }))
    },
    signatoryTableFull() {
      return this.$store?.getters['unitable/getTable']('vt_signatory_l');
    },
  },
  methods: {
    enabled_if(item) {
      if (!item.enabled_if) return false;

      return !item.enabled_if(this.localData);
    },
    async generate(html = true) {
      this.loading = true;


      const userInput = {
        ...this.localData,
      };

      console.log(userInput, this.filtersData);
      Object.keys(userInput).forEach(key => {
        const config = this.filtersData.find(i => i.key === key);

        if (!config || !config.send_name) return;

        console.log('values list', config.values(), userInput[key])
        userInput[config.send_name] = config.values().find(i => i.value.toString() === userInput[key].toString())?.name;
      })

      const query = {
        html: 1,
        ...userInput,
        authorization: localStorage.getItem('authorization'),
      }

      if (query.signer) {
        query.signer = query.signer.map(value => this.signatoryTableFull.find(item => +item.id === +value));
      }

      if (query.responsible) {
        query.responsible = this.signatoryTableFull.find(item => +item.id === +query.responsible);
      }

      if (!html) {
        delete query.html;
      }

      let valid = false;

      try {
        this.reportData.validate && this.reportData.validate(query);
        valid = true;
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Неправильный формат',
          message: e
        })
        this.loading = false
      }

      if (!valid) return;

      const encodeValue = (value, key) => {
        if (['string', 'number'].includes(typeof value)) {
          return encodeURIComponent(value)
        }

        if (key === 'signer') {
          return encodeValue(JSON.stringify(value));
        }

        if (key === 'responsible') {
          return encodeValue(JSON.stringify(value));
        }

        const row = this.filtersData.find(i => i.key === key);

        if (!row) return encodeURIComponent(value);

        if (row.type === Date) { // it's date array for now
          return encodeValue(value.map(item => new Date(item).valueOf()).join('-'));
        }
        if (row.type === Array) {
            return encodeValue(value.join('-'));
        }
      }

      const queryString = Object.entries(query)
          .map(([key, value]) => [key, encodeValue(value, key)])
          // eslint-disable-next-line
          .filter(([_, value]) => !!value)
          .map(array => array.join('='))
          .join('&');

      this.reportReady = true;
      this.reportUrl = `/api/v1/report/${this.reportUrlWrapped}?${queryString}`;

      if (html) {
        try {
          const {data} = await axios.get(this.reportUrl, {
            timeout: 10 * 60 * 1e3
          });

          if (!this.oldWay) {
            this.reportContent = data;
          }
          setTimeout(() => {
            document.querySelectorAll('[data-v]').forEach(item => {
              item.innerHTML = item.getAttribute('data-v')
            })
          }, 500);
        } catch (e) {
          this.$notify({
            message: e.response.data,
          })
        } finally {
          this.loading = false;
        }
      }

      else {

        window.open(this.reportUrl, '_blank');
        this.loading = false;
      }


    },
    setLoading() {
      try {
        this.reportData.validate && this.reportData.validate(this.localData);
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Неправильный формат',
          message: e
        })
      }
      this.loading = true;
    },
    authToken() {
      return localStorage.getItem('authorization');
    },
    insertBigNumber(val, key) {
      this.localData[key] = val
          .toString()
          .split('')
          .map(Number)
          .filter(i => !Number.isNaN(i))
          .join('');
      this.localData = { ...this.localData };
    },
    async workWithReport({ filepath }) {

      try {
        const idcarrier = this.localData.idcarrier || this.localData.vpbagentid;

        await this.$store.dispatch('unitable/loadReport', {filepath, idcarrier, url: this.reportData.uploadUrl})
        this.generate(true);
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Ошибка обработки сверки',
          message: e?.response?.data?.error || e.message,
        })
        this.reportContent = '';
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {
    this.report = this.$route.params.report;
    console.log(this.reportData);
    try {
      await this.$store.dispatch('unitable/fetchSourceForSelect', {
        table_name: 'vt_signatory_l',
        primaryKey: ['id'],
        viewField,
      });

      if (this.reportData.init) {
        console.log('calling init');
        await this.reportData.init();
      }
    } catch (e) {
      console.error(e);
      this.$notify({
        type: 'error',
        title: 'Неправильный формат',
        message: e.message
      })
    } finally {
      this.$nextTick(() => {
        this.initComplete = true;
      })
      this.loading = false;
    }
  }
}
</script>

<style lang="stylus">
.flex
  display flex
  flex-direction column
  padding 40px
  width calc(80% - 80px)

#xlsx-container
  width 100%
  overflow-x scroll
  height 800px
  border 0

.reports_filter_form
  display grid
  grid-auto-rows auto
  grid-template "a a a a a"
  .el-form-item__content
    padding 0 10px
    min-width 200px
    .input__column
      min-width: 200px

.upload-csv
  display inline-flex
  margin-left 10px
</style>
