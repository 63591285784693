import {sort} from "@/constants";

export default {
    tableCode: 'zones',
    tableName: 'Зоны',
    indexField: ['zone_id'],
    defaultOrder: {
        zone_id: sort.ASC,
    },
    viewField: 'zone_name',
    folderName: 'nsi',
    tableDescription: [
        {
            key: 'zone_id',
            type: BigInt,
            name: 'Наименование зоны'
        },
        {
            key: 'zone_name',
            type: String,
            name: 'Полное наименование зоны',
        },
        {
            key: 'description',
            type: String,
            name: 'Описание',
        },
    ]
}
