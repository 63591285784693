import {sort} from "@/constants";

export default {
    tableCode: 'zone_tariff',
    tableName: 'Зональные тарифы',
    indexField: ['zone_tariff_id'],
    defaultOrder: {
        zone_tariff_id: sort.ASC,
    },
    viewField: 'station_name',
    folderName: 'nsi',
    tableDescription: [
        {
            key: 'zone_tariff_id',
            skip_input: true,
            name: 'ID',
            type: BigInt,
        },
        {
            key: 'zone_id_from',
            type: BigInt,
            name: 'Зона начала поездки',
            relation: {
                table: 'zones',
                join_rules: {
                    zone_id_from: 'zone_id'
                }
            }
        },
        {
            key: 'zone_id_to',
            type: BigInt,
            name: 'Зона окончания поездки',
            relation: {
                table: 'zones',
                join_rules: {
                    zone_id_to: 'zone_id'
                }
            }
        },
        {
            key: 'price',
            name: 'Стоимость проезда, коп',
            type: BigInt,
        },
        {
            type: Date,
            name: 'Дата начала действия тарифа',
            key: 'date_begin',
            validator: (date, row) => row.expiry_date_end < date,
        },
        {
            type: Date,
            name: 'Дата окончания действия тарифа',
            key: 'date_end',
            validator: (date, row) => row.expiry_date_begin > date,
        },
    ]
}

