import {sort} from "@/constants";

export default {
    tableCode: 'vt_security_data',
    tableName: 'Шаблон защитной <br>последовательности ВПБ',
    isDoubleLine: true,
    indexField: [
        'id',
    ],
    defaultOrder: {
        id: sort.ASC,
    },
    viewField: 'algoritm_id',
    folderName: 'nsi',
    tableDescription: [
        {
            type: BigInt,
            key: 'id',
            name: 'ID',
            skip_input: true,
        },
        {
            type: BigInt,
            key: 'offset',
            name: 'Начальное смещение'
        },
        {
            type: BigInt,
            key: 'algoritm_id',
            name: 'Идентификатор алгоритма',
        },
        {
            type: String,
            key: 'key',
            name: 'Ключ'
        },
    ],
}
