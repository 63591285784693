import {sort} from "@/constants";

export default {
    tableCode: 'routetariftypes',
    tableName: 'Тарифы маршрута',
    indexField: [
        'route_tarif_type'
    ],
    defaultOrder: {
        route_tarif_type: sort.ASC,
    },
    viewField: 'description',
    folderName: 'vpb_control',
    tableDescription: [
        {
            type: BigInt,
            name: 'Код вида тарифа',
            key: 'route_tarif_type',
            // column_hidden: true,
            skip_input: true,
        },
        {
            type: String,
            name: 'Вид тарифа маршрута',
            key: 'description',
        },
    ],
}
