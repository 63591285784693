import {sort} from "@/constants";

export default {
    tableCode: 'table_permission_description',
    tableName: 'Описание доступов таблиц',
    folderName: 'users_management',
    indexField: [
        'permission'
    ],
    defaultOrder: {
        permission: sort.ASC,
    },
    viewField: 'description',
    tableDescription: [
        {
            type: BigInt,
            name: 'permission',
            key: 'permission',
        },
        {
            type: String,
            name: 'description',
            key: 'description',
        }
    ]
}
