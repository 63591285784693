import {sort} from "@/constants";

export default {
    tableName: 'Список доступных словарей',
    tableCode: 'dictionaries_list',
    folderName: 'users_management',
    indexField: [
        'dictionary',
    ],
    viewField: 'name',
    defaultOrder: {
        dictionary: sort.ASC,
    },
    tableDescription: [
        {
            key: 'dictionary',
            type: String,
            name: 'Код словаря',
        },
        {
            key: 'name',
            type: String,
            name: 'Имя словаря'
        }
    ]

}
