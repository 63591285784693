<template lang="pug">

  div(style="padding: 20px")
    h2 Отчеты

    el-container(style="flex-direction: column" )
      el-row(v-for="(report, index) in reportsList" :key="report.key" v-if="reportAllowed(report.key)")
        router-link(:to="`/reports/${report.key}`" :key="report.key")
          a.url__wrapper {{ report.index || (index + 1) }}: {{ report.name }}

</template>

<script>

import Reports from './reports';

export default {
  name: 'ReportsList',
  data: () => ({
    reportsList: Object.entries(Reports).map(([key, { name, index }]) => ({
      key, name, index
    })),
  }),
  computed: {
    reportsListAccess() {
      return this.$store.getters.user.reports_list;
    },
  },
  methods: {
    reportAllowed(key) {
      return this.reportsListAccess.includes(key);
    },
  }
}

</script>

<style lang="stylus" scoped>
  .reports-list
    padding 40px

  .url__wrapper
    padding 10px 0
    display block
</style>
