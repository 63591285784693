import {sort} from "@/constants";

export default {
    tableCode: 'zonetariffs',
    tableName: 'ZoneTariffs',
    indexField: [
        'id_zonetariff'
    ],
    defaultOrder:
        {
            id_zonetariff: sort.ASC,
        }
    ,
    tableDescription: [
        {
            type: BigInt,
            name: 'ID',
            key: 'id_zonetariff',
        },
        {
            type: Date,
            name: 'Дата начала',
            key: 'begin_date_time',
        },
        {
            type: Date,
            name: 'Дата окончания',
            key: 'end_date_time',
        },
        {
            type: BigInt,
            name: 'Тип маршрута',
            key: 'route_type',
        },
        {
            type: String,
            name: 'Комментарий',
            key: 'comment',
        },
    ],
}
