import {sort} from "@/constants";

export default {
    tableCode: 'vt_datagrammblacklistreasons',
    viewField: 'description',
    indexField: [
        'idreason'
    ],
    defaultOrder: {
        idreason: sort.ASC,
    },
    tableDescription: [
        {
            key: 'idreason',
            type: BigInt,
        },
        {
            key: 'description',
            type: String,
        }
    ]
}
