import {sort} from "@/constants";

export default {
    tableCode: 'counterpartytypes',
    tableName: 'Типы контрагентов',
    indexField: [
        'id'
    ],
    defaultOrder: {
            id: sort.ASC,
    },
    folderName: 'nsi',
    viewField: 'description',
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор типа контрагента',
            key: 'id',
            column_hidden: () => true,
            //            skip_input: true,
        },
        {
            type: String,
            name: 'Название типа',
            key: 'description',
        },
        {
            type: String,
            name: 'Примечание',
            key: 'note',
        },
    ],
}
