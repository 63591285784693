import {sort} from "@/constants";

export default {
    tableCode: 'ordersdata',
    tableName: 'ordersdata ???? Типы заказов',
    indexField: [
        'id'
    ],
    defaultOrder: {
        id: sort.ASC,
    },
    viewField: 'order_description',
    tableDescription: [
        {
            type: BigInt,
            name: 'id',
            key: 'id',
            column_hidden: () => true,
            skip_input: true,
        },
        {
            type: String,
            name: 'Описание',
            key: 'order_description',
        },
    ],
}
