import {sort} from "@/constants";

export default {
    tableCode: 'access_tocken_history_enriched',
    tableName: 'История изменения токенов <br>действия оборудования',
    indexField: ['id'],
    folderName: 'contragents',
    defaultOrder: {
        createdate: sort.DESC,
    },
    isDoubleLine: true,
    readOnly: true,
    tableDescription: [
        {
            key: 'id',
            column_hidden: () => true,
            skip_input: true
        },
        {
            key: 'device_id',
            name: 'Идентификатор оборудования',
            type: BigInt,
        },
        {
            key: 'serial_number',
            type: String,
            name: 'Серийный номер'
        },
        {
            key: 'devicetype',
            type: String,
            name: 'Тип оборудования'
        },
        {
            key: 'counterparty_name',
            type: String,
            name: 'Контрагент(владелец)'
        },
        {
            key: 'access_token',
            type: String,
            name: 'Токен авторизации'
        },
        {
            key: 'createdate',
            type: Date,
            name: 'Дата/время создания токена'
        },
        {
            key: 'enddate',
            type: Date,
            name: 'Дата/время окончания действия кода (токена)'
        },
    ]
}
