import {sort} from "@/constants";

export default {
    tableCode: 'cardsdata',
    tableName: 'cardsdata',
    indexField: [
        'card_type',
        'card_form'
    ],
    defaultOrder:
        {
            card_type: sort.ASC,
        }
    ,
    tableDescription: [
        {
            type: BigInt,
            name: 'Тип карты',
            key: 'card_type',
        },
        {
            type: String,
            name: 'Форма карты',
            key: 'card_form',
        },
        {
            type: String,
            name: 'Описание карты',
            key: 'card_description',
        },
        {
            type: BigInt,
            name: 'Категория',
            key: 'category_id',
        },
        {
            type: BigInt,
            name: 'ID карты',
            key: 'cardsdata_id',
        },
        {
            type: Date,
            name: 'Дата активации',
            key: 'activate_date',
        },
        {
            type: Date,
            name: 'Дата блокировки',
            key: 'block_date',
        }
    ],
}
