import moment from "moment-timezone";

export default function (value) {
    if (!value) return null;

    if (value.startsWith('decorated')) {
        return value.slice(10);
    }
    return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '';
}
