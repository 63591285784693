import {sort} from "@/constants";

export default {
    tableCode: 'units_list',
    viewField: 'description',
    indexField: ['id'],
    defaultOrder: {
        id: sort.ASC,
    },
    tableDescription: [
        {
            key: 'id',
            type: BigInt,
        },
        {
            key: 'description',
            type: String,
        },
    ]
}
