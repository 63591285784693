<template lang="pug">
  el-container(v-loading="loading")#app
    template(v-if="auth")
      ArmNavigation
      router-view(:key="$route.fullPath")

    NSILogin(v-else)
</template>

<script>
import ArmNavigation from "@/components/ArmNavigation";
import { mapState, mapMutations } from "vuex";
import NSILogin from "@/pages/Login";
import axios from "@/utils/axios";

export default {
  name: 'App',
  components: {
    NSILogin,
    ArmNavigation,
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapState({
        auth: state => state.isAuthorized,
    }),
  },
  async mounted() {
    try {
      const authorization = localStorage.getItem('authorization');

      if (!authorization) {
        return;
      }
      axios.defaults.headers.common.Authorization = authorization;
      this.$store.dispatch('ok')
          .then(() => this.loadVersion())
          .catch(console.error)
      setInterval(() => {
        this.$store.dispatch('ok')
            .catch(console.error)
      }, 5e3);
    } finally {
      this.loading = false;
    }

  },
  methods: {
    async loadVersion() {
      const settings = await this.$store.dispatch('unitable/fetchTable', {
        table_name: 'settings'
      })

      const row = settings.find(i => i.name === 'vpb_site_version_suffix');

      if (!row) return;

      this.$store.commit('appVersion', row.value);
    },
    ...mapMutations(['toggleMenu']),
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

* {
  font-family: "Roboto",BlinkMacSystemFont,-apple-system,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue","Helvetica","Arial",sans-serif;
}

body {
  margin: 0;
}

.el-table__cell .cell {
  word-break: inherit !important;
}

.el-input__inner {
  border: 1px solid black !important;
}

.el-message-box {
  width: 600px !important;
}
</style>
