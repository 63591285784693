import {sort} from "@/constants";

export default {
    tableCode: 'vt_routesbytransfer',
    tableName: 'Перечень маршрутов<br>действующих пересадок',
    isDoubleLine: true,
    indexField: [
        'transfer_id',
		'route'
    ],
    defaultOrder: {
        transfer_id: sort.ASC,
    },
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор пересадки',
            key: 'transfer_id',
            column_hidden: () => true,
            skip_input: true,
        },
        {
           type: BigInt,
            name: 'Маршрут',
            key: 'route',
        }, {
            type: String,
            name: 'Примечание',
            key: 'note',
        },
    ],
}
