import {sort} from "@/constants";

export default {
    tableCode: 'vt_security_rel_key_sam_view',
    tableName: 'SAM-модули',
    indexField: [
        'sam_id'
    ],
    defaultOrder: {
        sam_id: sort.ASC,
    },
    expand: true,
    folderName: 'key_security',
    readOnly: true,
    importTableName: 'vt_security_rel_key_sam',
    tableForExport: 'vt_security_rel_key_sam',
    importForceEnabled: true,
    tableDescription: [
        {
            key: 'sam_id',
            name: 'ID',
            type: String,
        },
        {
            key: 'sam_uid',
            name: 'UID SAM-модуля',
            type: String,
        },
        {
            key: 'pairs',
            type: String,
            name: 'МБ / Номер ячейки',
            show_part: true,
        },
    ]
}
