import {sort} from "@/constants";
import {transporttypes_usual_join, vt_counterparties_join} from "@/joins";

export default {
    tableCode: 'transportdata',
    tableName: 'Транспорт',
    folderName: 'contragents',
    indexField: [
        'ts_board_num',
        'id_carrier'
    ],
    defaultOrder:
        {
            ts_board_num: sort.ASC,
        }
    ,
    tableDescription: [
        {
            type: BigInt,
            name: 'Бортовой номер',
            key: 'ts_board_num',
        },
        {
            type: String,
            name: 'Гос номер',
            key: 'transport_reg_num',
        },
        vt_counterparties_join(
            'counterparty_id', 'Контрагент (владелец)'
        ),
        {
            type: BigInt,
            name: 'Площадка выпуска',
            key: 'issuance_areas_id',
            relation: {
                table: 'issuance_areas_2',
                filter_by: 'counterparty_id',
                filter_by_in_join: 'id_carrier',
                join_rules: {
                    issuance_areas_id: 'id',
                    // counterparty_id: 'id_carrier',
                }
            }
        },
        transporttypes_usual_join('id_transport_type'),
    ],
}
