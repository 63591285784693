<template lang="pug">
  universal-table(
    :table-code="tableCode"
    :table-name="tableName"
    :index-field="indexField"
    :table-description="tableDescription"
    :default-order="defaultOrder"
  )


</template>
<script>
import UniversalTable from "@/pages/UniversalTable";
import tables from "@/tables";
import {mounted} from "@/pages/rolesHook";
export default {
  components: {
    UniversalTable,
  },
  data: () => ({
    ...tables.agreements()
  }),
  mounted() {
    mounted(this)
  }

}
</script>
