import {sort} from "@/constants";

export default {
    tableCode: 'datagrammreasonsdata',
    viewField: 'description',
    indexField: [
        'id_reasons'
    ],
    defaultOrder: {
        id_reasons: sort.ASC,
    },
    tableDescription: [
        {
            key: 'id_reasons',
            type: BigInt,
        },
        {
            key: 'description',
            type: String,
        }
    ]
}
