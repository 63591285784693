<template lang="pug">

  el-header(:style="`text-align: right; font-size: 12px; position: relative; background-color: ${bckgrndColor}`")
    //el-button.menu_button(v-on:click="toggleMenu" :icon="icon") {{ !menuHidden ? 'скрыть меню' : 'раскрыть меню' }}
    slot

</template>

<script>
import ArmIcon from "@/components/ArmIcon";
import { mapMutations, mapState } from "vuex";

export default {
  name: "ArmHeader",
  components: {
    ArmIcon,
  },
  props: {
    backgroundColor: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapState(['menuHidden']),
    icon() {
      return this.menuHidden ? 'el-icon-s-unfold' : 'el-icon-s-fold';
    },
    bckgrndColor() {
      return this.backgroundColor || '#9DC5D3';
    },
  },
  methods: {
    ...mapMutations([ 'toggleMenu' ]),
  }
}
</script>

<style scoped lang="stylus">
.el-header
  color #333
  line-height 60px

.menu_button
  position absolute
  left 10px
  top 10px
  font-size 20px
  padding 9px 20px

</style>

<style lang="stylus">

.menu_button span
  font-size 15px !important
</style>
