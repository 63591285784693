import {sort} from "@/constants";

export default {
    tableCode: 'vt_datagrammblacklist',
    tableName: 'Стоп-лист датаграмм',
    defaultOrder: {
        block_date_time: sort.DESC,
    },
    indexField: [
        'guidticket'
    ],
    exportLimitOn: true,
    folderName: 'blacklist',
    tableDescription: [
        {
            type: String,
            subtype: 'UUID',
            name: 'GUID датаграммы',
            key: 'guidticket',
        },
        {
            type: Date,
            name: 'Дата и время внесения датаграммы ',
            key: 'block_date_time',
        },
        {
            type: Date,
            name: 'Дата и время разблокировки ',
            key: 'unblock_date_time',
        },
        {
            type: BigInt,
            name: 'Причина внесения датаграммы в список',
            key: 'reason',
            relation: {
                table: 'datagrammreasonsdata',
                join_rules: {
                    reason: 'id_reasons',
                }
            }
        },
    ],
}
