<template lang="pug">
  universal-table(
    :style="style"
    :table-code="tableCode"
    :table-name="tableName"
    :index-field="indexField"
    :table-description="tableDescription"
    :default-order="defaultOrder"
    :disable-operations="readOnly"
    :skip-add="wrappedSkipAdd"
    :view-field="viewField"
    :expand="wrappedField('expand')"
    :filters-disabled="filtersDisabled"
    :import-export-disabled="importExportDisabled"
    :scroll-menu-disabled="scrollMenuDisabled"
    :background-color="backgroundColor"
    :add-button-name="addButtonName"
    :show-removed-button-name="showRemovedButtonName"
    :default-filter="defaultFilter"
  )
    template(v-slot:multiple_buttons="{ selection, fetchTable }")

      el-popconfirm(
        title="Вы уверены что хотите разблокировать?"
        confirm-button-text="Да"
        cancel-button-text="Нет"
        v-on:confirm="toggleDeviceStatus(1, selection, fetchTable)"
      )
        el-button(icon="el-icon-unlock" type="danger"  plain circle slot="reference")

      el-popconfirm(
        title="Вы уверены что хотите заблокировать?"
        confirm-button-text="Да"
        cancel-button-text="Нет"
        v-on:confirm="toggleDeviceStatus(2, selection, fetchTable)"
      )
        el-button(icon="el-icon-lock" type="warning"  plain circle slot="reference")

</template>
<script>
import UniversalTable from "@/pages/UniversalTable";
import tables from "@/tables";
import { mapGetters } from 'vuex';
import {mounted} from "@/pages/rolesHook";

export default {
  components: {
    UniversalTable,
  },
  props: {
    style: {
      required: false,
    },
    filtersDisabled: {
      required: false,
    },
    importExportDisabled: {
      required: false,
    },
    scrollMenuDisabled: {
      required: false,
    },
    backgroundColor: {
      required: false,
    },
    addButtonName: {
      required: false,
    },
    showRemovedButtonName: {
      required: false,
    },
    defaultFilter: {
      required: false,
    }
  },
  data() {
    const table = tables.devices;
    return table ? {...table()} : {}
  },
  computed: {
    wrappedSkipAdd() {
      return this.skipAdd || false;
    },
    ...mapGetters([
      'roles',
    ]),
    readOnly() {
      try {
        const role = this.roles.find(r => r.table_code.toLowerCase() === this.tableCode.toLowerCase());

        return role.write === false;
      } catch (e) {
        console.log('info', e, this.tableCode);
        return false;
      }
    },
  },
  methods: {
    async toggleDeviceStatus(status_id, selection, fetchTable) {
      for (let i = 0; i < selection.length; i++) {
         await this.$store.dispatch('unitable/editItem', {
          table_name: 'devices',
          primary_key: { id: selection[i].id },
          payload: { status_id },
        })
      }
      await fetchTable();
    },
    wrappedField(name) {
      return this[name] || undefined;
    },
    async toggleUserBlock(id, payload, fetchTable) {
      if (payload.is_blocked) {
        await this.$store.dispatch('unitable/removeItem', {
          table_name: 'users_session',
          primary_key: {
            user_id: id
          }
        });
      }
      await this.$store.dispatch('unitable/editItem', {
        table_name: 'system_users',
        primary_key: { id },
        payload: payload,
      })
      fetchTable();
    },
    async toggleMassUserBlock(selection, fetchTable) {
      console.log(selection);
      for (let i = 0; i < selection.length; i++) {
        await this.toggleUserBlock(selection[i].id, {is_blocked: !selection[i].is_blocked}, () => {});
      }
      await fetchTable();
    },
  },
  mounted() {
    mounted(this)
  }
}
</script>
