export const setAuth = (state, value) => {
    state.isAuthorized = value;
}
export const setUser = (state, user) => {
    state.user = user;
}

export const setRoles = (state, roles) => {
    state.roles = roles;
}

export const setRestrictions = (state, restrictions) => {
    state.restrictions = restrictions;
}
