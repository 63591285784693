import {sort} from "@/constants";

export default {
    tableCode: 'vt_blacklist',
    // tableForSelect: 'vt_blacklist_enriched',
    tableName: 'Стоп-лист ВПБ',
    indexField: [
        'card_number'
    ],
    exportLimitOn: true,
    defaultOrder: {
        priority: sort.DESC,
        block_date_time: sort.DESC,
        card_number: sort.ASC,
    },
    tableForExport: 'vt_blacklist_for_export',
    folderName: 'blacklist',
    validate: {
        all(row) {
            if (!row.card_number) {
                throw new Error('Укажите номер виртуального носителя')
            }
        }
    },
    tableDescription: [
        {
            key: 'card_number',
            type: BigInt,
            name: 'Номер виртуального носителя',
            relation: {
                table: 'virtualcards',
                join_rules: {
                    card_number: 'cardid',
                }
            },
        },
        {
            key: 'card_type',
            type: BigInt,
            name: 'Идентификатор типа/вида ПБ',
            relation: {
                table: 'vt_cardsdata_for_blacklist',
                join_rules: {
                    card_type: 'vt_type',
                    card_sub_type: 'vt_subtype',
                }
            }
        },
        {
            type: Date,
            name: 'Дата блокировки',
            key: 'block_date_time',
        },
        {
            type: Date,
            name: 'Дата разблокировки',
            key: 'unblock_date_time',
            validator: (date, row) => row.block_date_time > date,
        },
        {
            type: BigInt,
            name: 'Причина внесения носителя в список',
            key: 'reason',
            relation: {
                table: 'reasonsdata',
                join_rules: {
                    reason: 'id_reasons',
                }
            }
        },
        {
            type: BigInt,
            name: 'Приоритет',
            key: 'priority',
            relation: {
                table: 'prioritydata',
                join_rules: {
                    priority: 'id_priority'
                }
            }
        },
        // {
        //     type: BigInt,
        //     name: 'Тип ВПБ',
        //     key: 'card_type',
        // },
    ],
}
