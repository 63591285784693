import {sort} from "@/constants";

export default {
    tableCode: 'carddatatypes',
    tableName: 'Типы данных ПБ',
    indexField: [
        'datatype_id'
    ],
    defaultOrder: {
        datatype_id: sort.ASC,
    },
    viewField: 'description',
    folderName: 'vpb_control',
    tableDescription: [
        {
            key: 'datatype_id',
            type: BigInt,
            name: 'Идентификатор типа данных билета'
        },
        {
            key: 'description',
            type: String,
            name: 'Тип данных билета'
        }
    ]
}
