<template lang="pug">
  universal-table(
    :table-code="tableCode"
    :table-name="tableName"
    :index-field="indexField"
    :table-description="tableDescription"
    :default-order="defaultOrder"
    :disable-operations="readOnly"
    :skip-add="wrappedSkipAdd"
    :view-field="viewField"
    :expand="expand"
  )
    template(v-slot:expanded_row="{ row }")
      strong(style="margin-left: 20px") Тело запроса:
      pre.code_wrap {{ row.payload }}
      strong(style="margin-left: 20px") Предыдущие значения:
      pre.code_wrap {{ row.previous_data }}
      strong(style="margin-left: 20px") Текст ошибки:
      pre.code_wrap {{ row.error_data }}
</template>
<script>
import UniversalTable from "@/pages/UniversalTable";
import tables from "@/tables";
import { mapGetters } from 'vuex';
import {mounted} from "@/pages/rolesHook";

export default {
  components: {
    UniversalTable,
  },
  data() {
    const table = tables.action_history;
    return table ? {...table()} : {}
  },
  computed: {
    usersTableData() {
      const table = tables.action_history;
      return table ? {...table()} : {}
    },
    wrappedSkipAdd() {
      return this.skipAdd || false;
    },
    ...mapGetters([
      'roles',
    ]),
    readOnly() {
      try {
        const role = this.roles.find(r => r.table_code.toLowerCase() === this.tableCode.toLowerCase());

        return role.write === false;
      } catch (e) {
        console.log('info', e, this.tableCode);
        return false;
      }
    },
  },
  mounted() {
    mounted(this)
  }

}
</script>

<style lang="stylus" scoped>
.code_wrap
  background lightgray
  padding 20px
  margin 20px
  white-space break-spaces
  overflow-wrap anywhere
  color black

</style>
