import {sort} from "@/constants";
// import {vt_cardsdata_join} from "@/joins";


const viewField = function (row) {
    this.valueOf =
        (for_server = false) =>
            for_server ?
                [
                    'id', 'contract', 'contract_number',
                    'counterparty_id'
                ] : `${row.contract}(${row.contract_number})`;
}

export default {
    tableCode: 'vt_contracts',
    tableName: 'Договоры',
    folderName: 'contragents',
    additionalFields: [
        {
            targetField: 'vt_cardsdata_id',
            sourceTable: 'vt_typesbycontract',
            indexValue: 'id',
            indexKey: 'contract_id',
            value: 'vt_cardsdata_id',
            where: {
                isdeleted: false,
            }
        },
        {
            targetField: 'route',
            sourceTable: 'routesbycontract',
            indexValue: 'id',
            indexKey: 'contract_id',
            value: 'route',
            where: {
                isdeleted: false,
            },
        }
    ],
    indexField: [
        'id'
    ],
    defaultOrder: {
            id: sort.ASC,
    },
    viewField: viewField,
    tableDescription: [
        {
            type: BigInt,
            name: 'Внутренний идентификатор договора',
            key: 'id',
            skip_input: true,
        },
        {
            type: String,
            name: 'Номер договора',
            key: 'contract_number',
        },
        {
            type: String,
            name: 'Название договора',
            key: 'contract',
        },
        {
            type: Date,
            name: 'Дата договора',
            key: 'contract_datetime',
            validator: (date, row) => row.contract_end_datetime < date,
        },
        {
            type: Date,
            name: 'Дата начала действия договора',
            key: 'contract_start_time',
            validator: (date, row) => row.contract_end_datetime < date,
        },
        {
            type: Date,
            name: 'Срок действия договора',
            key: 'contract_end_datetime',
            validator: (date, row) => row.contract_datetime > date,
        },
        //должно выводиться пользователю поле  vt_counterparties.counterparty_shortname
        {
            type: BigInt,
            name: 'Контрагент',
            key: 'counterparty_id',
            relation: {
                table: 'vt_counterparties',

                join_rules: {
                    counterparty_id: 'counterparty_id',
                }
            }
        },
        {
            type: BigInt,
            name: 'Вид договора',
            key: 'contract_kind',
            relation: {
                table: 'contract_kinds',
                join_rules: {
                    contract_kind: 'id'
                }
            }
        },
        {
            type: String,
            name: 'Номер основного договора',
            key: 'general_contract',
            enabled_if: d => Number(d.contract_kind) === 2,
        },
        {
            type: BigInt,
            name: 'Тип договора',
            key: 'contract_type',
            relation: {
                table: 'contract_types',
                join_rules: {
                    contract_type: 'id'
                }
            }
        },
        {
            type: BigInt,
            name: 'Bид взаиморасчетов',
            key: 'mutual_payments_type',
            enabled_if: d => Number(d.contract_type) === 3 || Number(d.contract_type) === 1,
            relation: {
                table: 'mutualpaymentstypes',
                join_rules: {
                    mutual_payments_type: 'id'
                },
            },
        },
        // {
        //     type: BigInt,
        //     name: 'Виды ВПБ',
        //     // enabled_if: d => true,
        //     ...vt_cardsdata_join(),
        // },
        {
            key: 'vt_cardsdata_id',
            name: 'Виды ВПБ',
            multiple: true,
            type: Array,
            data_from: {
                table: 'vt_cardsdata',
            },
            enabled_if: d => Number(d.contract_type) === 2,
        },
        {
            key: 'route',
            name: 'Маршруты',
            multiple: true,
            type: Array,
            subtype: String,
            data_from: {
                table: 'vt_routesdata',
            },
            enabled_if: d => Number(d.contract_type) === 3 || Number(d.contract_type) === 1,
        },

        //выводиться vt_bonuses.description
        // {
        //     type: BigInt,
        //     name: 'Правило расчета вознаграждения',
        //     key: 'bonuses_id',
        //     relation: {
        //         table: 'vt_bonuses',
        //         join_rules: {
        //             bonuses_id: 'id',
        //         },
        //         view: 'bonus'
        //     }
        // },

        {
            type: BigInt,
            name: 'Статус',
            key: 'status_id',
            relation: {
                table: 'contracts_statuses',
                join_rules: {
                    status_id: 'id',
                }
            }
        },
    ],
}
