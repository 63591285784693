import {sort} from "@/constants";
import {counterparties_statuses_join, vt_contracts_join} from "@/joins";

export default {
    tableCode: 'vt_counterparties',
    tableName: 'Контрагенты',
    folderName: 'contragents',
    expand: true,
    indexField: [
        'counterparty_id'
    ],
    defaultOrder: {
            counterparty_id: sort.ASC,
    },
    viewField: 'counterparty_shortname',
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор контрагента',
            key: 'counterparty_id',
            skip_input: true,
        },
        {
            type: BigInt,
            name: 'Идентификатор СЭКОП',
            key: 'sekop_id',
        },
        {
            type: String,
            name: 'Наименование контрагента',
            key: 'counterparty_name',
        },
        {
            type: String,
            name: 'Короткое наименование',
            key: 'counterparty_shortname',
        },
        {
            type: Array,
            subtype: Number,
            name: 'Тип контрагента',
            key: 'counterparty_type',
            multiple: true,
            data_from: {
                table: 'counterpartytypes'
            },
            // relation: {
            //     table: 'counterpartytypes',
            //     join_rules: {
            //         counterparty_type: 'id',
            //     },
            // }
        },
        counterparties_statuses_join('status_id'),
        // counterpartyroles_join('role'),
        {
            type: 'Header',
            name: 'Учетная карточка контрагента'
        },
        {
            type: String,
            name: 'Форма собственности',
            key: 'ownership_form',
        },
        {
            type: String,
            name: 'ОГРН/ОГРНИП',
            key: 'ogrn_ogrnip',
        },
        {
            type: String,
            name: 'ИНН',
            key: 'inn',
        },
        {
            type: String,
            name: 'КПП',
            key: 'kpp',
        },
        {
            type: String,
            name: 'Юридический адрес',
            key: 'headquartered',
        },
        {
            type: String,
            name: 'Фактический адрес',
            key: 'actual_address',
        },
        {
            type: String,
            name: 'Почтовый адрес',
            key: 'postal_address',
        },
        {
            type: 'Header',
            name: 'Реквизиты контрагента',
        },
        {
            name: 'Корсчет',
            key: 'coraccount',
            type: String,
        },
        {
            name: 'Расчетный счет',
            key: 'paymentaccount',
            type: String,
        },
        {
            name: 'БИК',
            key: 'bic',
            type: String,
        },
        {
            type: 'Header',
            name: 'Руководитель',
        },
        {
            type: String,
            name: 'ФИО руководителя',
            key: 'manager_fullname',
        },
        {
            type: String,
            name: 'Должность руководителя',
            key: 'manager_post',
        },
        {
            type: String,
            name: 'Правоустанавливающий документ',
            key: 'manager_doc',
        },
        {
            type: 'Header',
            name: 'Контакты',
        },
        {
            type: String,
            name: 'Телефон',
            key: 'phone',
        },
        {
            type: String,
            name: 'Email',
            key: 'email',
        },
        {
            type: Boolean,
            key: 'accept_missing_routes',
            name: 'Принимать транзакции от маршрутов, отсутствующих в справочнике',
        },
        {
            ...vt_contracts_join('contract'),
            skip_input: true,
            virtual_field: true,
            skip_filter: true,
            getMultipleRows: true,
            relation: {
                table: 'vt_contracts',
                join_index: 'counterparty_id',
                join_rules: {
                    counterparty_id: 'counterparty_id',
                }
            }
        },
    ],
}
