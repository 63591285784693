export default {

    users_management: {
        name: 'Управление пользователями',
        icon: 'user',
        position: 100,
    },
    nsi: {
        name: 'Управление НСИ',
        position: 0,
    },
    contragents: {
        name: 'Управление контрагентами',
        icon: 's-operation',
        position: 1,
    },
    vpb_control: {
        name: 'Общие справочники',
        icon: 's-ticket',
        position: 2,
    },
    test_vpb: {
        name: 'Тестовые ВПБ',
        icon: 'data-board',
        position: 85,
    },
    reestr: {
        name: 'Реестр',
        icon: 'folder-opened',
        position: 99,
    },
    ____: {
        name: 'Все остальное',
        icon: 's-opportunity',
        position: 87,
    },
    reports: {
        name: 'Отчеты',
        icon: 'document-copy',
        position: 108,
    },
    key_security: {
        name: 'Управление ключевой инф-цией',
        icon: 'key',
        isDoubleLine: true,
        position: 102,
    },
    blacklist: {
        name: 'Управление ЧС',
        icon: 'document-delete',
        position: 101,
    }

}
