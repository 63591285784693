import Store from "@/store/store";
import moment from "moment-timezone";


const viewField = function () {
    this.valueOf = () => ['id', 'name', 'job_title'];
}

export default {
    vpblifecyclehistory: {
        url: 'universal/vpblifecycle',
        customIframeUrl: '/Home/Update?SelectedItem=1.%20%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F%20%D0%B6%D0%B8%D0%B7%D0%BD%D0%B5%D0%BD%D0%BD%D0%BE%D0%B3%D0%BE%20%D1%86%D0%B8%D0%BA%D0%BB%D0%B0%20%D0%92%D0%9F%D0%91%20%D0%B0%D0%BF%D0%B4%20%D0%B1%D0%BB%D0%B5%D0%BA%D0%BB%D0%B8%D1%81%D1%82.frx',
        name: 'Отчет «История жизненного цикла ВПБ»',
        filters: [
            {
                key: 'card_number',
                type: BigInt,
                name: 'Номер виртуального носителя',
                doubleLine: true,
                enabled_if: filters => !filters.card_id && !filters.phone_number,
            },
            {
                key: 'card_id',
                type: String,
                name: 'Номер ВПБ',
                enabled_if: filters => !filters.card_number && !filters.phone_number,
            },
            {
                key: 'phone_number',
                type: String,
                name: 'Телефон пользователя',
                enabled_if: filters => !filters.card_number && !filters.card_id,
            },
            {
                key: 'date',
                type: Date,
                name: 'Выбор даты'
            },
            {
                key: 'EventType',
                type: Array,
                name: 'Тип события',
                values: () => [
                    {
                        value: 'Реализация (выпуск)',
                        name: 'Реализация (выпуск)',
                    },
                    {
                        value: 'Реализация',
                        name: 'Реализация',
                    },
                    {
                        value: 'Гашение',
                        name: 'Гашение',
                    },
                    {
                        value: 'Контроль',
                        name: 'Контроль',
                    },
                    {
                        value: 'Блокировка',
                        name: 'Блокировка',
                    }
                ]
            }
        ],
        validate: (filters) => {
            if (filters.card_id && filters.card_id.trim() !== '' && /^(\d+)-(\d+)\/(\d)$/.test(filters.card_id) === false) {
                throw 'Номер впб должен быть вида 11111-123/4'
            }
        },
    },
    transactionvpb: {
        name: 'Отчет «Реестр сверки для Перевозчика за период»',
        url: 'universal/transactionvpb',
        async init() {
            await Promise.all([
                Store.$store.dispatch('unitable/fetchSourceForSelect', {
                    table_name: 'vt_counterparties_realisators',
                    primaryKey: ['counterparty_id'],
                    viewField: 'counterparty_shortname'
                }),
                Store.$store.dispatch('unitable/fetchSourceForSelect', {
                    table_name: 'vt_routesdata',
                    primaryKey: ['id'],
                    viewField: 'route_name'
                })
            ]);
        },
        filters: [
            {
                key: 'operationdatetime',
                type: Date,
                name: 'Выбор даты'
            },
            {
                name: 'Перевозчик',
                type: Array,
                key: 'idcarrier',
                send_name: 'idcarriername',
                values: () => Store?.$store?.getters['unitable/getTable']('vt_counterparties_realisators')?.map(item => ({
                    value: item.counterparty_id,
                    name: item.counterparty_shortname
                }))
            },
            {
                name: 'Тип ПБ',
                type: BigInt,
                key: 'tickettype'
            },
            {
                name: 'Вид ПБ',
                type: BigInt,
                key: 'ticketsubtype'
            },
            {
                name: 'Маршрут (номер)',
                type: Array,
                key: 'idroute',
                placeholder: 'Введите номер маршрута',
                values: () => Store?.$store?.getters['unitable/getTable']('vt_routesdata')?.map(item => ({
                    value: item.id,
                    name: item.route_name
                }))
            },

            {
                name: 'Бортовой номер',
                type: BigInt,
                key: 'idts'
            }
        ]
    },
    sodtrans: {
        name: 'Отчет «Реестр сверки для Контрагента по реализации за период»',
        url: 'universal/sodtrans',
        async init() {
            await Store.$store.dispatch('unitable/fetchSourceForSelect', {
                table_name: 'vt_counterparties_carriers',
                primaryKey: ['counterparty_id'],
                viewField: 'counterparty_shortname'
            })
        },
        filters: [
            {
                key: 'reg_date_time',
                type: Date,
                name: 'Выбор даты'
            },
            {
                name: 'Контрагент',
                type: Array,
                key: 'vpbagentid',
                send_name: 'vpbagentname',
                values: () => Store?.$store?.getters['unitable/getTable']('vt_counterparties_carriers')?.map(item => ({
                    value: item.counterparty_id,
                    name: item.counterparty_shortname
                }))
            },
            {
                name: 'Тип ПБ',
                type: BigInt,
                key: 'card_type'
            },
            {
                name: 'Вид ПБ',
                type: BigInt,
                key: 'card_sub_type'
            },
        ]
    },
    sodtranslong: {
        name: 'Отчет о реализации виртуальных проездных билетов длительного пользования',
        customIframeUrl: '/Home/Update?SelectedItem=4.%20%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%BE%20%D1%80%D0%B5%D0%B0%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8%20%D0%B2%D0%B8%D1%80%D1%82%D1%83%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%BF%D1%80%D0%BE%D0%B5%D0%B7%D0%B4%D0%BD%D1%8B%D1%85%20%D0%B1%D0%B8%D0%BB%D0%B5%D1%82%D0%BE%D0%B2%20%D0%B4%D0%BB%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE%20%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F.frx',
        url: 'universal/sodtranslong',
        filters: [
            {
                key: 'begin_date_time',
                type: Date,
                name: 'Выбор даты'
            },
        ]
    },
    registeredtransactions: {
        name: 'Отчет Перевозчика о зарегистрированных транзакциях по видам проездных билетов ',
        url: 'universal/registeredtransactions',
        customIframeUrl: '/Home/Update?SelectedItem=5.%20%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%9F%D0%B5%D1%80%D0%B5%D0%B2%D0%BE%D0%B7%D1%87%D0%B8%D0%BA%D0%B0%20%D0%BE%20%D0%B7%D0%B0%D1%80%D0%B5%D0%B3%D0%B8%D1%81%D1%82%D1%80%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%BD%D1%8B%D1%85%20%D1%82%D1%80%D0%B0%D0%BD%D0%B7%D0%B0%D0%BA%D1%86%D0%B8%D1%8F%D1%85%20%D0%BF%D0%BE%20%D0%B2%D0%B8%D0%B4%D0%B0%D0%BC%20%D0%BF%D1%80%D0%BE%D0%B5%D0%B7%D0%B4%D0%BD%D1%8B%D1%85%20%D0%B1%D0%B8%D0%BB%D0%B5%D1%82%D0%BE%D0%B2.frx',
        async init() {
            await Promise.all([
                Store.$store.dispatch('unitable/fetchSourceForSelect', {
                    table_name: 'vt_counterparties_realisators',
                    primaryKey: ['counterparty_id'],
                    viewField: 'counterparty_shortname'
                }),
            ]);
        },
        filters: [
            {
                key: 'operationdatetime',
                type: Date,
                name: 'Выбор даты'
            },
            {
                name: 'Перевозчик',
                type: Array,
                key: 'idcarrier',
                send_name: 'idcarriername',
                values: () => Store?.$store?.getters['unitable/getTable']('vt_counterparties_realisators')?.map(item => ({
                    value: item.counterparty_id,
                    name: item.counterparty_shortname
                }))
            },
        ],
        validate: (filters) => {
            if (!filters.idcarrier || !filters.operationdatetime || !filters.operationdatetime[0] || !filters.operationdatetime[1]) {
                throw 'Укажите все поля'
            }
        },
    },
    sodtransrealisation: {
        name: 'Отчет «Взаиморасчеты по реализации»',
        url: 'universal/sodtransrealisation',
        customIframeUrl: '/Home/Update?SelectedItem=6.%20%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%92%D0%B7%D0%B0%D0%B8%D0%BC%D0%BE%D1%80%D0%B0%D1%81%D1%87%D0%B5%D1%82%D1%8B%20%D0%BF%D0%BE%20%D1%80%D0%B5%D0%B0%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8.frx',
        filters: [
            {
                key: 'reg_date_time',
                type: Date,
                name: 'Выбор даты'
            },
        ],
        validate: (filters) => {
            if (!filters.reg_date_time || !filters.reg_date_time[0] || !filters.reg_date_time[1]) {
                throw 'Укажите время'
            }
        },
    },
    event_types: {
        name: 'Отчет по типам событий за период',
        customIframeUrl: '/Home/Update?SelectedItem=7.%20%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%BF%D0%BE%20%D1%82%D0%B8%D0%BF%D0%B0%D0%BC%20%D1%81%D0%BE%D0%B1%D1%8B%D1%82%D0%B8%D0%B9%20%D0%B7%D0%B0%20%D0%BF%D0%B5%D1%80%D0%B8%D0%BE%D0%B4.frx',
        url: 'universal/eventtypes',
        filters: [
            {
                key: 'date',
                type: Date,
                name: 'Выбор даты'
            },
            {
                key: 'EventType',
                type: Array,
                name: 'Тип события',
                values: () => [
                    {
                        value: 'Регистрация (выпуск)',
                        name: 'Регистрация (выпуск)',
                    },
                    {
                        value: 'Реализация',
                        name: 'Реализация',
                    },
                    {
                        value: 'Гашение',
                        name: 'Гашение',
                    },
                    {
                        value: 'Контроль гашения',
                        name: 'Контроль гашения',
                    },
                    {
                        value: 'Восстановление',
                        name: 'Восстановление',
                    },
                    {
                        value: 'Блокировка',
                        name: 'Блокировка',
                    },
                    {
                        value: 'Разблокировка',
                        name: 'Разблокировка',
                    },
                ]
            }
        ],
        validate: (filters) => {
            if (!filters.date || !filters.date[0] || !filters.date[1]) {
                throw 'Укажите время'
            }
        },
    },
    transactions_correct: {
        url: 'universal/transactionscorrected',
        name: 'Отчет «Анализ корректности гашения датаграмм для ВПБ»',
        customIframeUrl: '/Home/Update?SelectedItem=8.%20%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%90%D0%BD%D0%B0%D0%BB%D0%B8%D0%B7%20%D0%BA%D0%BE%D1%80%D1%80%D0%B5%D0%BA%D1%82%D0%BD%D0%BE%D1%81%D1%82%D0%B8%20%D0%B3%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D1%8F%20%D0%B4%D0%B0%D1%82%D0%B0%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%20%D0%B4%D0%BB%D1%8F%20%D0%92%D0%9F%D0%91.frx',
        filters: [
            {
                name: 'Номер билета',
                key: 'cardid',
                type: BigInt,
            },
            {
                key: 'createdatetime',
                type: Date,
                name: 'Выбор даты'
            },
        ],
        validate: (filters) => {
            if (!filters.cardid) {
                throw 'Укажите номер билета'
            }
        },
    },
    transactions_sodtrans: {
        url: 'universal/transactionssodtrans',
        name: 'Отчет по получению детализации по ВПБ',
        customIframeUrl: '/Home/Update?SelectedItem=9.%20%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%BF%D0%BE%20%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D1%8E%20%D0%B4%D0%B5%D1%82%D0%B0%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8%20%D0%BF%D0%BE%20%D0%92%D0%9F%D0%91.frx',
        filters: [
            {
                name: 'Номер билета',
                key: 'cardid',
                type: BigInt,
            },
            {
                key: 'date',
                type: Date,
                name: 'Выбор даты'
            },
        ],
        validate: (filters) => {
            if (!filters.cardid) {
                throw 'Укажите номер билета'
            }
        },
    },
    _15m: {
        url: 'universal/15m',
        index: 15,
        name: 'Отчет о распределении выручки от реализации единых проездных билетов ',
        customIframeUrl: '/Home/Update?SelectedItem=15.%20%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%BE%20%D1%80%D0%B0%D1%81%D0%BF%D1%80%D0%B5%D0%B4%D0%B5%D0%BB%D0%B5%D0%BD%D0%B8%D0%B8%20%D0%B2%D1%8B%D1%80%D1%83%D1%87%D0%BA%D0%B8%20%D0%BE%D1%82%20%D1%80%D0%B5%D0%B0%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8%20%D0%B5%D0%B4%D0%B8%D0%BD%D1%8B%D1%85%20%D0%BF%D1%80%D0%BE%D0%B5%D0%B7%D0%B4%D0%BD%D1%8B%D1%85%20%D0%B1%D0%B8%D0%BB%D0%B5%D1%82%D0%BE%D0%B2.frx',
        filters: [
            {
                key: 'operationdatetime',
                type: Date,
                name: 'Выбор даты'
            },
        ],
        validate: (filters) => {
            if (!filters.operationdatetime || !filters.operationdatetime[0] || !filters.operationdatetime[1]) {
                throw 'Укажите время'
            }
        },
        async init() {
            await Store.$store.dispatch('unitable/fetchSourceForSelect', {
                table_name: 'vt_signatory_l',
                primaryKey: ['id'],
                viewField,
            });
        },
        signersOn: true,
        responsibleOn: true,
    },
    _16m: {
        url: 'universal/16m',
        index: 16,
        name: 'Отчет о распределении выручки от реализации проездных билетов повременных',
        filters: [
            {
                key: 'operationdatetime',
                type: Date,
                name: 'Выбор даты'
            },
        ],
        validate: (filters) => {
            if (!filters.operationdatetime || !filters.operationdatetime[0] || !filters.operationdatetime[1]) {
                throw 'Укажите время'
            }
        },
        async init() {
            await Store.$store.dispatch('unitable/fetchSourceForSelect', {
                table_name: 'vt_signatory_l',
                primaryKey: ['id'],
                viewField,
            });
        },
        signersOn: true,
        responsibleOn: true,
    },
    _17m: {
        url: 'universal/17m',
        index: 17,
        name: 'Отчет о распределении выручки от реализации ЕЭБ',
        filters: [
            {
                key: 'operationdatetime',
                type: Date,
                name: 'Выбор даты'
            },
        ],
        validate: (filters) => {
            if (!filters.operationdatetime || !filters.operationdatetime[0] || !filters.operationdatetime[1]) {
                throw 'Укажите время'
            }
        },
        async init() {
            await Store.$store.dispatch('unitable/fetchSourceForSelect', {
                table_name: 'vt_signatory_l',
                primaryKey: ['id'],
                viewField,
            });
        },
        signersOn: true,
        responsibleOn: true,
    },
    _18m: {
        url: 'universal/18m',
        index: 18,
        name: 'Отчет о распределении выручки от реализации проездных билетов на наземные виды транспорта',
        customIframeUrl: '/Home/Update?SelectedItem=18.%20%D0%9E%D1%82%D1%87%D0%B5%D1%82%20%D0%BE%20%D1%80%D0%B0%D1%81%D0%BF%D1%80%D0%B5%D0%B4%D0%B5%D0%BB%D0%B5%D0%BD%D0%B8%D0%B8%20%D0%B2%D1%8B%D1%80%D1%83%D1%87%D0%BA%D0%B8%20%D0%BE%D1%82%20%D1%80%D0%B5%D0%B0%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8%20%D0%BF%D1%80%D0%BE%D0%B5%D0%B7%D0%B4%D0%BD%D1%8B%D1%85%20%D0%B1%D0%B8%D0%BB%D0%B5%D1%82%D0%BE%D0%B2%20%D0%BD%D0%B0%20%D0%BD%D0%B0%D0%B7%D0%B5%D0%BC%D0%BD%D1%8B%D0%B5%20%D0%B2%D0%B8%D0%B4%D1%8B%20%D1%82%D1%80%D0%B0%D0%BD%D1%81%D0%BF%D0%BE%D1%80%D1%82%D0%B0.frx',
        filters: [
            {
                key: 'operationdatetime',
                type: Date,
                name: 'Выбор даты'
            },
        ],
        validate: (filters) => {
            if (!filters.operationdatetime || !filters.operationdatetime[0] || !filters.operationdatetime[1]) {
                throw 'Укажите время'
            }
        },
        async init() {
            await Store.$store.dispatch('unitable/fetchSourceForSelect', {
                table_name: 'vt_signatory_l',
                primaryKey: ['id'],
                viewField,
            });
        },
        signersOn: true,
        responsibleOn: true,
    },
    _19m: {
        url: 'universal/report19m',
        index: 19,
        name: 'Отчет об активированных проездных билетах и транзакциях, совершенных пассажирами по проездным билетам по данным автоматизированного учета',
        async init() {
            await Promise.all([
                Store.$store.dispatch('unitable/fetchSourceForSelect', {
                    table_name: 'vt_counterparties_carriers',
                    primaryKey: ['counterparty_id'],
                    viewField: 'counterparty_shortname'
                }),
                Store.$store.dispatch('unitable/fetchSourceForSelect', {
                    table_name: 'vt_signatory_l',
                    primaryKey: ['id'],
                    viewField,
                })
            ]);
        },
        signersOn: true,
        filters: [
            {
                key: 'operationdatetime',
                type: Date,
                name: 'Выбор даты'
            },
            {
                name: 'Перевозчик',
                type: Array,
                key: 'agentid',
                values: () => Store?.$store?.getters['unitable/getTable']('vt_counterparties_carriers')?.map(item => ({
                    value: item.counterparty_id,
                    name: item.counterparty_shortname
                }))
            },
        ],
        validate: (filters) => {
            if (!filters.operationdatetime || !filters.operationdatetime[0] || !filters.operationdatetime[1]) {
                throw 'Укажите время'
            }
        },
    },
    _20m: {
        url: 'universal/20m',
        index: 20,
        name: 'Отчет о транзакциях, совершенных пассажирами по проездным билетам по данным автоматизированного учета',
        filters: [
            {
                key: 'operationdatetime',
                type: Date,
                name: 'Выбор даты',
            },
        ],
        validate: (filters) => {
            if (!filters.operationdatetime || !filters.operationdatetime[0] || !filters.operationdatetime[1]) {
                throw 'Укажите время'
            }
        },
        async init() {
            await Store.$store.dispatch('unitable/fetchSourceForSelect', {
                table_name: 'vt_signatory_l',
                primaryKey: ['id'],
                viewField,
            });
        },
        signersOn: true,
    },
    reconciliation: {
        url: "/reconciliation",
        name: 'Сверка для Перевозчика',
        upload: true,
        uploadUrl: 'reports/check-reconcillation',
        async init() {
            await Promise.all([
                Store.$store.dispatch('unitable/fetchSourceForSelect', {
                    table_name: 'vt_counterparties_realisators',
                    primaryKey: ['counterparty_id'],
                    viewField: 'counterparty_shortname'
                })
            ]);
        },
        filters: [
            {
                key: 'operationdatetime',
                type: Date,
                name: 'Выбор даты',
                decorator: value => {
                    console.log(moment(value).add(3, 'hours').format('YYYY-MM-DD HH:mm:ss'));
                    return `decorated:${moment(value).add(3, 'hours').format('YYYY-MM-DD HH:mm:ss')}`
                }
            },
            {
                name: 'Перевозчик',
                type: Array,
                key: 'idcarrier',
                values: () => Store?.$store?.getters['unitable/getTable']('vt_counterparties_realisators')?.map(item => ({
                    value: item.counterparty_id,
                    name: item.counterparty_shortname
                }))
            },
        ],
        validate: (filters) => {
            if (!filters.idcarrier || !filters.operationdatetime || !filters.operationdatetime[0] || !filters.operationdatetime[1]) {
                throw 'Укажите все поля'
            }
        },
    },
    sodtrans_reconciliation: {
        name: 'Сверка с контрагентом по реализации',
        url: '/sodtrans_reconciliation',
        upload: true,
        uploadUrl: 'reports/check-sodtrans-reconcillation',
        async init() {
            await Store.$store.dispatch('unitable/fetchSourceForSelect', {
                table_name: 'vt_counterparties_carriers',
                primaryKey: ['counterparty_id'],
                viewField: 'counterparty_shortname'
            })
        },
        filters: [
            {
                key: 'reg_date_time',
                type: Date,
                name: 'Выбор даты',
                decorator: value => {
                    console.log(moment(value).add(3, 'hours').format('YYYY-MM-DD HH:mm:ss'));
                    return `decorated:${moment(value).add(3, 'hours').format('YYYY-MM-DD HH:mm:ss')}`
                }
            },
            {
                name: 'Контрагент',
                type: Array,
                key: 'vpbagentid',
                values: () => Store?.$store?.getters['unitable/getTable']('vt_counterparties_carriers')?.map(item => ({
                    value: item.counterparty_id,
                    name: item.counterparty_shortname
                }))
            },
        ],
        validate: (filters) => {
            if (!filters.vpbagentid || !filters.reg_date_time || !filters.reg_date_time[0] || !filters.reg_date_time[1]) {
                throw 'Укажите все поля'
            }
        },
    },
}
