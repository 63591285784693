<template lang="pug">
  el-container.column
    slot(name="modal")

    content-modal(
      :opened.sync="contentModal.opened"
      :mode="contentModal.mode"
      :structure="contentModal.structure"
      :values.sync="contentModal.values"
      :table-code="tableCode"
      :table-name="tableNameClean"
      v-on:update-success="updateSuccess"
    )
    import-c-s-v(
      :visible="showImportCSV"
      :data='importModal'
      :table-code='tableCode'
      v-on:set-loading='setLoading'
      v-on:update:visible="val => showImportCSV = val"
      v-on:update-success="updateSuccess"
    )

    arm-header.header(v-loading="loading" :background-color="backgroundColor")

      el-switch.right__margin(
        v-if="softDeleteSupported"
        v-model="showSoftDeleted"
        v-on:change="softDeleteChanged"
        inactive-color="#13ce66"
        active-color="#ff4949"
        :inactive-text="showRemovedButtonName")

      el-button(
        icon="el-icon-search" v-if="!filtersDisabled" v-on:click="filtersActive = true"
      ) Продвинутый поиск

      el-dropdown.left__margin(v-if='importEnabledCheck')
        el-button
          | Импорт
          i.el-icon-arrow-down.el-icon--right

        el-dropdown-menu(slot="dropdown")
          el-dropdown-item
            el-upload.upload-csv(
              ref="upload-csv"
              :headers="{authorization: authToken()}"
              action="/api/v1/files/add"
              :before-upload="setLoading"
              accept="text/csv"
              :on-success="loadCSV"
            ): el-button.no__border(
              slot="trigger"
            ) CSV
          el-dropdown-item
            el-upload.upload-csv(
              ref="upload-xlsx"
              :headers="{authorization: authToken()}"
              action="/api/v1/files/add"
              :before-upload="setLoading"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet\n"
              :on-success="loadXLSX"
            ): el-button.no__border(
              slot="trigger"
            ) XLSX

      el-dropdown.left__margin(v-on:command="type => openUrl(type)" v-if="!importExportDisabled")
        el-button
          | Экспорт
          i.el-icon-arrow-down.el-icon--right

        el-dropdown-menu(slot="dropdown")
          el-dropdown-item(command="csv") CSV
          el-dropdown-item(command="xlsx") XSLX


      slot(name="dropdown" :filters="filters" :group_by="group_by" :set-group-by="setGroupBy")


      el-button.left__margin(
        v-if='!disableOperations && !skipAdd'
        icon='el-icon-circle-plus-outline'
        type="success"
        v-on:click="openCreateModal"
      ) {{addButtonName}}

      el-dropdown.left__margin(v-on:command="toggleColumnToShow" :hide-on-click="false")
        el-button
          i.el-icon-setting
        el-dropdown-menu(slot="dropdown")
          el-dropdown-item(disabled) Отображаемые колонки
          el-dropdown-item(
            v-for="item in tableDescription"
            v-if='!(item.column_hidden && item.column_hidden()) && item.type !== "Header"'
            :key="item.key + Math.random()"
            :command='item.key'
            :icon="checkIfColumnShown(item.key) ? 'el-icon-check' : 'el-icon-close'"
          ) {{item.name}}

    el-row(style="padding: 20px; background-color: lightgoldenrodyellow")
      h4(style="margin: 0") {{ tableNameClean }} ({{ tableCode }})

    uni-table-filters(
      v-if="!loading && !filtersDisabled"
      :structure="tableDescription"
      :target-data="filters"
      v-on:search="applyFilters"
      v-on:sort="resetSort"
      :columns-to-show="columnsToShow"
      :filters-active.sync="filtersActive"
    )

    el-menu.scroll_possibility(ref="dataTable" :class="{'scroll-menu': !scrollMenuDisabled}")
      el-table(
        v-loading="loading"
        :data="enrichedTable"
        :default-sort="defaultSort"
        border
        ref="mainTable"
        v-on:sort-change="applySort"
        v-on:row-click="toggleTableRow"
        :row-class-name="isSoftDeleted"
        v-on:expand-change="expandStateChanged"
        v-on:selection-change="handleSelectionChange"
      )
        el-table-column(
          v-if="!disableOperations"
          type="selection"
          width="55")
        el-table-column(type="expand" v-if="expand")
          template(slot-scope="props")
            slot(name="expanded_row" :row="props.row")
            //p State: {{ JSON.stringify(props.row) }}

        el-table-column(
          v-for="column in tableDescriptionWrapped"
          :key="column.key"
          :prop="column.key"
          :label="column.name"
          v-if="!(column.column_hidden && column.column_hidden())"
          :width='column.width'
          min-width='150'
          :sortable="column.sortable ?? 'custom'"
        )
          template(slot-scope="data")
            template(v-if="column.type === Boolean")
              | {{ data.row[column.key] ? 'Да' : 'Нет'}}
            template(v-else-if="column.type === Array")
              span(v-html="wrapArrayString(column.key, data.row[column.key], data.row)")
            template(v-else-if="column.type !== Date")
              div.break-all( v-if="column.show_part" v-html="cutArrayString(data.row[column.key])")
              span(v-else) {{data.row[column.key]}}
            template(v-else)
              | {{ data.row[column.key] | customDate }}

        el-table-column(
          v-if="!disableOperations"
          label="Действия"
          align="center"
          header-align="center"
          width="200"
        )
          template(slot="header" slot-scope="scope")

            el-row: span Действия
            el-row(v-if='multipleSelection.length'): el-popconfirm(

              :title=" `Вы уверены что хотите продолжить?`"
              confirm-button-text="Да"
              cancel-button-text="Нет"
              v-on:confirm="removeMultipleItems"
            )
              el-button(
                slot="reference"

                type="danger" circle plain icon="el-icon-delete"
              )

            slot(name="multiple_buttons" :selection="multipleSelection" :fetch-table="fetchTable")

          template(slot-scope="data")
            el-row(style="justify-content: space-around; display: flex;" v-if="multipleSelection.length === 0")
              el-button(type="primary" v-on:click="viewItem(data.row)" plain circle icon="el-icon-view")
              el-button.null-margin-left(
                v-if="!showSoftDeleted"
                type="warning"
                v-on:click="editItem(data.row)"
                plain circle icon="el-icon-edit"
              )
              el-popconfirm(
                v-if='data.row.isdeleted'
                title="Вы уверены что хотите восстановить?"
                confirm-button-text="Да"
                cancel-button-text="Нет"
                v-on:confirm="restoreItem(data.row)"
              )
                el-button(
                  slot="reference" type="warning"
                  circle plain icon="el-icon-refresh-right")

              div(v-on:click="() => {}")
                el-popconfirm(
                  :title=" `Вы уверены что хотите${data.row.isdeleted ? ' окончательно' : ''} удалить?`"
                  confirm-button-text="Да"
                  cancel-button-text="Нет"
                  v-on:confirm="removeItem(data.row)"
                )
                  el-button(slot="reference" type="danger" circle plain icon="el-icon-delete")

              slot(name="more_buttons" :row="data.row" :fetchTable="fetchTable")


    el-pagination.uni__pagination(
      background
      layout="sizes, prev, pager, next"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSizeComputed"
      v-on:update:pageSize="pageSizeChange"
      :current-page.sync="pagination.currentPage"
      v-on:current-change="paginationPageClick"
      v-on:size-change="paginationSizeChange"
      :total="getCount(tableCode)"
    )
</template>

<script>
import * as R from 'ramda';
import {mapGetters} from "vuex";
import ArmHeader from "@/components/ArmHeader";
import {sleep} from "@/utils/sleep";
import {preparedTables} from '@/tables';
import ContentModal from "@/pages/UniversalTable/ContentModal";
import UniTableFilters from "@/pages/UniversalTable/Filters";
import ImportCSV from "@/pages/UniversalTable/ImportCSV";
import sortTransform from "@/utils/sortTransform";
import axios from "@/utils/axios";
import getViewFieldForRow from "@/utils/getViewFieldForRow";
import {wrapPayload} from "@/store/universaltable/actions";

export default {
  name: 'UniversalTable',
  components: {ImportCSV, UniTableFilters, ContentModal, ArmHeader},
  props: {
    tableCode: { // table name in pg
      type: String,
      required: true,
    },
    tableName: { // table name in pg
      type: String,
      required: true,
    },
    exportLimitOn: {
      type: Boolean,
      default: false,
    },
    addButtonName: {
      type: String,
      default: 'Добавить элемент',
    },
    showRemovedButtonName: {
      type: String,
      default: 'Показать удаленные данные'
    },
    filtersDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    defaultFilter: {
      type: Object,
      default: () => {},
    },
    importExportDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    scrollMenuDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    backgroundColor: {
      required: false,
      type: String,
    },
    expand: {
      type: Boolean,
      required: false,
      default: false,
    },
    tableDescription: { // table name in russian
      type: Array,
    },
    infiniteLength: { // table length is uncountable
      required: false,
      default: false,
      type: Boolean,
    },
    disableOperations: { // don't allow edit/remove ops on table
      required: false,
      default: false,
      type: Boolean,
    },
    indexField: { // field which should act as index on edit/remove ops
      type: Array,
      default: () => ([]),
      required: false,
    },
    viewField: {
      type: [String, Function],
      required: false,
    },
    defaultOrder: {
      type: Array,
      required: false,
    },
    skipAdd: {
      type: Boolean,
      required: false,
      default: false,
    },
    group_by: {
      type: Array,
      required: false,
      default: () => ([])
    },
    encrypt: {
      type: Boolean,
      default: false,
      required: false,
    },
    importForceEnabled: {
      type: Boolean,
      default: false,
    },
    importTableName: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    COLUMNS_TO_SHOW: 7,
    importModal: {},
    filtersActive: false,
    showSoftDeleted: false,
    contentModal: {
      opened: false,
      mode: 'edit',
      structure: [],
      values: {},
      tableCode: undefined,
    },
    showImportCSV: false,
    customSort: {},
    internalTableCode: undefined,
    filters: {},
    expandedRow: undefined,
    // group_by: [],
    loading: false,
    pagination: {
      pageSize: 20,
      currentPage: 1,
    },
    columnsToShow: [],
    multipleSelection: [],
  }),
  computed: {
    tableNameClean() {
      return this.tableName.replace('<br>', '');
    },
    tableDescriptionWrapped() {
      return this.tableDescription.filter(({ type }) => type !== 'Header').filter(({ key }) => this.columnsToShow.includes(key));
    },
    serverSort() {
      return Object.entries(this.customSort).length ? this.customSort : this.defaultOrder;
    },
    defaultSort() {
      const sort = this.serverSort;

      if (!sort || Object.keys(sort).length === 0) return {};

      if (Array.isArray(sort)) {
        return {
          prop: sort[0].key,
          order: sort[0].type === 'ASC' ? 'ascending' : 'descending',
        };
      }
      const [prop, order] = Object.entries(sort)[0];


      return {
        prop,
        order: order === 'ASC' ? 'ascending' : 'descending',
      };
    },
    ...mapGetters({
      getTable: 'unitable/getTable',
      getCount: 'unitable/getCount',
      getSoftDeleteSupport: 'unitable/getSoftDeleteSupport',
      getTableRow: 'unitable/getTableRow',
      getTableRows: 'unitable/getTableRows',
      restrictions: 'restrictions',
    }),
    softDeleteSupported() {
      return this.getSoftDeleteSupport(this.internalTableCode);
    },
    pageSizeComputed() {
      return Number(localStorage.getItem(`${this.internalTableCode}_pagesize`)) || this.pagination.pageSize;
    },
    importEnabledCheck() {
      if (this.importForceEnabled) {
        return true;
      }

      return !this.disableOperations && !this.importExportDisabled;
    },
    diff() {
      const tableData = this.getTable(this.internalTableCode) ? this.getTable(this.internalTableCode) : [];
      const structure = this.tableDescription;

      const keys = R.pipe(R.nth(0), R.keys);

      return R.difference(structure.map(s => s.key), keys(tableData)).filter(i => i);
    },
    enrichedTable() {
      const tableData = this.getTable(this.internalTableCode) ? this.getTable(this.internalTableCode) : [];

      const diff = this.diff;

      tableData.forEach(row => {
        diff.forEach(key => {
          if (!row[key]) row[key] = null;
        })
      });

      return tableData
          .map(item => {
            const anotherItem = {...item};


            Object.entries(item).forEach(([key, value]) => {
              const description = this.tableDescription.find(i => i.key === key);

              if (!description) {
                return;
              }

              if (description.decorator) {
                anotherItem[key] = description.decorator(value);
                return;
              }

              if (!description.relation) return;

              const {relation} = description;

              if (description.virtual_field) {
                value = ',';
              }

              if (!relation || typeof value === 'undefined' || value === null) {
                return;
              }


              const buffer = [];

              [...new Set((value).toString().split(','))].forEach(itemValue => {

                const indexValues = {};

                Object.entries(relation.join_rules).forEach(([thisField, targetField]) => {
                  indexValues[targetField] = thisField === key ? itemValue : item[thisField];
                });

                try {
                  let row = undefined;
                  if (description.getMultipleRows) {
                    row = this.getTableRows(relation.table, {
                      search: Object.values(relation.join_rules),
                      values: indexValues,
                    });
                  } else {
                    row = this.getTableRow(relation.table, {
                      search: Object.values(relation.join_rules),
                      values: indexValues,
                    });
                  }

                  const relationView = preparedTables[relation.table].viewField;

                  // console.log('viewFieldForJoin', getViewFieldForRow(relationView, row));
                  if (description.getMultipleRows && Array.isArray(row)) {
                    buffer.push(row.map(item => getViewFieldForRow(relationView, item)).join('\n'));
                  } else {
                    buffer.push(getViewFieldForRow(relationView, row));
                  }

                } catch (e) {
                  // console.error(e);
                  buffer.push(`${itemValue} (Отсутствует в справочнике)`);
                }
              })

              if (description.multiple_separator) {
                anotherItem[key] = buffer.join(description.multiple_separator);
              } else {
                anotherItem[key] = buffer.join('\n');
              }

            })

            anotherItem.source = {...item};

            return anotherItem;
          });
    },
    paginationForRequest() {
      return {
        offset: (this.pagination.currentPage - 1) * this.pagination.pageSize,
        limit: this.pagination.pageSize,
      };
    },
  },
  methods: {
    isSoftDeleted({row}) {
      if (row.isdeleted === true) {
        return 'error-row';
      }
      if (row.is_blocked === true) {
        return 'warning-row';
      }
      if (row.sam_update_flag === true) {
        return 'error-row';
      }

      return '';
    },
    expandStateChanged()  {
      // if (this.expandedRow) {
      //   this.$refs.mainTable.toggleRowExpansion(this.expandedRow, false);
      // }
    },
    toggleTableRow(row) {
      if (!this.expand) return;
      // this.expandStateChanged(row);
      // this.expandedRow = row;
      this.$refs.mainTable.toggleRowExpansion(this.expandedRow, false);
      this.expandedRow = row;
      this.$refs.mainTable.toggleRowExpansion(this.expandedRow, true);
    },
    wrapArrayString(key, array, row) {
      const result = this.prepareArrayString(key, array, row);

      if (result.length > 100) {
        return result.slice(0, 100) + '<b>.....</b>';
      }
      return result;
    },
    cutArrayString(string) {
      return string.length > 100 ? string.split('<br>').join(' ').slice(0, 100) + '<b>.....</b>' : string;
    },
    prepareArrayString(key, array, row) {
      console.log('array', key, array);
      const column = this.tableDescription.find(c => c.key === key);

      const table = column.data_from ? column.data_from.table : column.relation.table;

      const data = this.getTable(table);

      if (column.relation) return array;

      console.log(key, array, row);

      if (!array) return '-';

      if (!data) {
        console.log(row);
        return array.join(',');
      }

      const sourceTableDescription = preparedTables[table];

      const { indexField, viewField } = sourceTableDescription;

      const index = indexField[0];

      const dataAsObject = {};

      data.forEach(row => {
        dataAsObject[row[index]] = getViewFieldForRow(viewField, row);
      })

      return array.map(a => dataAsObject[a]).join('<br>');

    },
    pageSizeChange(size) {
      this.pagination.pageSize = size;
      localStorage.setItem(`${this.internalTableCode}_pagesize`, size)
    },
    toggleColumnToShow(key) {
      if (this.columnsToShow.includes(key)) {
        this.columnsToShow = this.columnsToShow.filter(k => k !== key);
      } else {
        this.columnsToShow = [...this.columnsToShow, key];
      }
      window.localStorage.setItem(this.tableCode, JSON.stringify(this.columnsToShow));
    },
    checkIfColumnShown(key) {
      return this.columnsToShow.includes(key);
    },
    openUrl(type) {
      if (this.exportLimitOn) {
        this.$prompt('Укажите количество рядов для экспорта. Введите 0, чтобы экспортировать все.', 'Настройки экспорта', {
          confirmButtonText: 'Экспорт',
          cancelButtonText: 'Отмена',
          inputPattern: /^\d+\.?\d*$/,
          inputErrorMessage: 'Введите целое число'
        }).then(({ value }) => {
          console.log(this.generateExportUrl(type, value));
          window.open(this.generateExportUrl(type, value), {
            target: '_blank'
          })
        })
      } else {
        console.log(this.generateExportUrl(type));
        // eslint-disable-next-line
        // return;
        // eslint-disable-next-line
        window.open(this.generateExportUrl(type), {
          target: '_blank'
        })
      }
    },
    generateExportUrl(type, limit = 0) {
      const tableCode = preparedTables[this.internalTableCode].tableForExport || this.tableCode.toLowerCase();

      const schema = JSON.stringify(preparedTables[this.tableCode.toLowerCase()]
          .tableDescription
          .reduce((accum, item) => {
            accum[item.key] = item.name;
            return accum;
          }, {}));

      console.log('EXPORT FILTERS', wrapPayload(this.filters, tableCode));

      const filters = JSON.stringify(wrapPayload(this.filters, tableCode));

      const default_order = JSON.stringify(Array.isArray(this.serverSort) ?
          this.serverSort :
          sortTransform(this.serverSort));

      if (this.encrypt) {
        return `/api/v1/storage/${tableCode}/${type}?authorization=${this.authToken()}&encrypt=true&schema=${schema}`;
      }
      return `/api/v1/storage/${tableCode}/${type}?authorization=${this.authToken()}&schema=${schema}&filters=${filters}&limit=${limit}&order=${default_order}`
    },
    setGroupBy(value) {
      this.group_by = value;
    },
    authToken() {
      return localStorage.getItem('authorization');
    },
    loadCSV(response) {
      this.loading = false
      this.showImportCSV = true;
      this.importModal = response;
    },
    async loadXLSX(response) {
      const { data } = await axios.post('/files/convert_xlsx_to_csv', response);
      this.loading = false
      this.showImportCSV = true;
      this.importModal = data;
    },
    async applySort({prop, order}) {
      this.defaultSort[prop] = 'order';
      if (!order) {
        this.customSort = {};
      } else {
        this.customSort = {
          [prop]: order === 'ascending' ? 'ASC' : 'DESC',
        }
      }

      // this.$refs.mainTable.sort(prop, order);
      await this.fetchTable();
    },
    setLoading() {

      this.loading = true;
    },
    resetSort() {
      this.customSort = {};
      this.$refs.mainTable.clearSort();
      this.$refs.mainTable.sort(this.defaultOrder[0].prop, this.defaultOrder[0].order);
    },
    async applyFilters(filters) {
      this.filters = filters;
      this.pagination = {
        ...this.pagination,
        currentPage: 1,
      }
      await this.fetchTable();
    },
    async updateSuccess() {
      await this.fetchTable();
    },
    async restoreItem(row) {
      const primary_key = R.pick(this.indexField)(row.source);

      await this.$store.dispatch('unitable/editItem', {
        table_name: this.internalTableCode,
        primary_key,
        payload: { isdeleted: false },
      })

      await this.fetchTable();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async removeMultipleItems() {
      console.log(this.multipleSelection);
      this.multipleSelection.forEach(row => {
        this.removeItem(row);
      });
    },
    async removeItem(row) {
      const table = preparedTables[this.internalTableCode];

      if (table.validation && table.validation.remove) {
        try {
          table.validation.remove(row);
        } catch (e) {
          this.$notify({
            title: 'Ошибка удаления',
            type: 'error',
            message: e.message,
          })
          return;
        }
      }


      const primary_key = R.pick(this.indexField)(row.source);

      await this.$store.dispatch('unitable/removeItem', {
        table_name: this.internalTableCode,
        primary_key,
      })

      await this.fetchTable();

      this.$message({
        type: 'success',
        message: 'Удаление успешно',
        showClose: true,
      });
    },
    editItem(scope) {
      this.contentModal = {
        opened: true,
        mode: 'edit',
        structure: this.tableDescription,
        values: scope,
        tableCode: this.tableCode,
      };
    },
    viewItem(scope) {
      this.contentModal = {
        opened: true,
        mode: 'view',
        structure: this.tableDescription,
        values: scope,
        tableCode: this.tableCode,
      };
    },
    openCreateModal() {
      this.contentModal = {
        opened: true,
        mode: 'add',
        structure: this.tableDescription,
        values: {},
        tableCode: this.tableCode,
      };
    },
    async paginationPageClick() {
      await this.fetchTable();
    },
    async paginationSizeChange() {
      this.pagination.currentPage = 1;
      await this.fetchTable();
    },
    async fetchTable() {
      this.loading = true;

      const default_order = Array.isArray(this.serverSort) ?
          this.serverSort :
          sortTransform(this.serverSort);

      const table = preparedTables[this.internalTableCode];

      await this.$store.dispatch('unitable/fetchTable', {
        table_name: this.internalTableCode,
        table_for_select: table.tableForSelect,
        infinite_length: this.infiniteLength,
        default_order,
        filters: this.filters,
        group_by: this.group_by,
        additionalFields: table.additionalFields,
        ...this.paginationForRequest,
      })

      this.rerender();
      document.querySelector('.scroll-menu').scrollTo(0, 0);
      await sleep(1e2);
      this.loading = false;
    },
    softDeleteChanged() {
      if (this.showSoftDeleted) {
        this.filters.isdeleted = true;
        this.fetchTable();
      } else {
        delete this.filters.isdeleted;
        this.fetchTable();
      }
    },
    rerender() {
      // hack for page rerender
      this.internalTableCode = null;
      this.$nextTick(() => {
        this.internalTableCode = this.tableCode;
      });
    }
  },
  async mounted() {
    this.internalTableCode = this.tableCode;
    this.filters = {...this.defaultFilter};

    this.pagination.pageSize = Number(localStorage.getItem(`${this.internalTableCode}_pagesize`)) || 20;

    await this.fetchTable();
    this.loading = true;

    const columnsToShow = JSON.parse(window.localStorage.getItem(this.tableCode));

    this.columnsToShow = columnsToShow || R.pipe(
        R.prop('tableDescription'),
        R.filter(r => (r.column_hidden && r.column_hidden()) !== true),
        R.slice(0, 8),
        R.map(R.prop('key'))
    )(this);

    const filterDescription = prop_name => R.pipe(
        R.prop('tableDescription'),
        R.map(R.prop(prop_name)),
        R.filter(R.pipe(R.not, R.not)),
        R.map(R.prop('table')),
    )(this);

    const getJoinField = tableName => R.pipe(
        R.prop('tableDescription'),
        R.find(row => row.relation && row.relation.table === tableName),
        R.prop('relation'),
        R.prop('join_index')
    )(this)

    try {
      const tables = [...filterDescription('relation'), ...filterDescription('data_from')]

      const schemas = tables.map(t => preparedTables[t]);

      const localTables = schemas.filter(s => s.local);
      const _remoteTables = schemas.filter(s => !s.local);

      const remoteTables = [...new Set(_remoteTables)];

      localTables.forEach(table => {
        console.log('local table', table, table.tableContent);
        this.$store.commit('unitable/writeToStorage', {
          data: table.tableContent,
          table_name: table.tableCode,
        });
        this.$store.commit('unitable/writeCountToStorage', {
          count: table.tableContent.length,
          table_name: table.tableCode,
        });
      })


      await Promise.all(
          remoteTables.map(table => this.$store.dispatch('unitable/fetchSourceForSelect', {
            table_name: table.tableCode,
            primaryKey: getJoinField(table.tableCode) ? [getJoinField(table.tableCode)] : table.indexField,
            viewField: table.viewField,
            table_storage: table.tableStorage,
            additionalFields: table.additionalFields,
          }))
      );
    } finally {
      this.loading = false;
    }

    this.rerender();
  },
}
</script>

<style lang="stylus" scoped>

.left__margin
  margin-left 10px

.column
  height 100vh
  overflow-y scroll

.scroll-menu
  height calc(100vh - 180px)
  overflow-y scroll

.uni__pagination
  padding-top 20px
  text-align center

.upload-csv
  display inline-block
</style>

<style lang="stylus">
.no__border
  border none
.el-loading-spinner
  top calc(50vh - 25px) !important

.el-upload-list
  display none

.column
  flex-direction column !important

.right__margin
  position absolute
  left 20px
  top 20px
  .el-switch__label--left
    color black !important

.null-margin-left
  margin-left 0 !important

.el-table .warning-row {
  background: oldlace;
}

.el-table .error-row {
  background: pink;
}

.header__tablename
  position absolute
  left 10px
  top -20px
  font-weight bold
  font-size 15px


.scroll_possibility
  overflow-x scroll


.break-all
  word-break break-all
</style>
