import {sort} from "@/constants";

export default {
    tableCode: 'carrieroffices',
    tableName: 'Филиалы перевозчиков',
    indexField: [
        'id_carrier',
        'id_carrier_office'
    ],
    defaultOrder:
        {
            id_carrier: sort.ASC,
        }
    ,
    tableDescription: [
        {
            type: BigInt,
            name: 'Код филиала перевозчика',
            key: 'id_carrier_office',
            column_hidden: () => true,
            skip_input: true,
        },
        {
            type: BigInt,
            name: 'Код перевозчика',
            key: 'id_carrier',
            relation: {
                table: 'carriersdata',
                join_rules: {
                    id_carrier: 'id',
                },
            },
        },
        {
            type: String,
            name: 'Наименование филиала',
            key: 'name',
        },
        {
            type: BigInt,
            name: 'Тип транспорта',
            key: 'transport_type',
            relation: {
                table: 'transporttypes',
                join_rules: {
                    transport_type: 'id',
                },
            },
        },
    ],
}
