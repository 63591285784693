import {sort} from "@/constants";
import Store from "@/store/store";

export default {
    tableCode: 'sodtranstest',
    tableForSelect: 'sodtranstest_enriched',
    tableName: 'Транзакции <br>пополнения/продажи тестовых ВПБ',
    indexField: ['id_sod_trans'],
    folderName: 'test_vpb',
    isDoubleLine: true,
    defaultOrder: {
        reg_date_time: sort.DESC,
    },
    readOnly: true,
    tableDescription: [
        {
            key: 'id_sod_trans',
            type: BigInt,
            column_hidden: () => true,
            name: 'Номер по порядку',
        },
        {
            key: 'card_number',
            type: BigInt,
            name: 'Номер карты'
        },
        {
            type: String,
            name: 'Контактная информация пользователя МП/ЛКП',
            key: 'credentials',
            column_hidden: () => !Store.$store.getters.shallSeeCredentials, // +
        },
        {
            key: 'card_type',
            type: BigInt,
            name: 'Идентификатор типа/вида ПБ',
            relation: {
                table: 'vt_cardsdata',
                join_rules: {
                    card_type: 'vt_type',
                    card_sub_type: 'vt_subtype'
                }
            }
        },
        {
            key: 'card_sub_type',
            type: BigInt,
            name: 'Вид карты'
        },
        // {
        //     key: 'debit_unit',
        //     type: BigInt,
        //     name: 'Размер списания'
        // },
        {
            key: 'reg_date_time',
            type: Date,
            name: 'Дата-время операции'
        },
        // {
        //     key: 'kassier_kode',
        //     type: BigInt,
        //     name: 'Идентификатор кассира'
        // },
        // {
        //     key: 'kass_kode',
        //     type: BigInt,
        //     name: 'Идентификатор кассы'
        // },
        {
            key: 'sum_payment',
            type: BigInt,
            name: 'Сумма операции'
        },
        {
            key: 'sum_purse',
            type: BigInt,
            name: 'Баланс'
        },
        // {
        //     key: 'days',
        //     type: BigInt,
        //     name: 'Кол-во дней'
        // },
        {
            key: 'transport_type',
            type: BigInt,
            name: 'Тип транспорта'
        },
        {
            key: 'begin_date_time',
            type: Date,
            name: 'Дата начала срока действия'
        },
        {
            key: 'end_date_time',
            type: Date,
            name: 'Конец срока действия'
        },
        // {
        //     key: 'validator_id',
        //     type: BigInt,
        //     name: 'Идентификатор валидатора'
        // },
        // {
        //     key: 'id_agents',
        //     type: BigInt,
        //     name: 'Идентификатор агента'
        // },
        // {
        //     key: 'id_payment',
        //     type: BigInt,
        //     name: 'Идентификатор платежа'
        // },
        // {
        //     key: 'payment_type',
        //     type: BigInt,
        //     name: 'Тип платежа'
        // },
        // {
        //     key: 'dos_flag',
        //     type: BigInt,
        //     name: 'Признак отложенного платежа'
        // },
        // {
        //     key: 'metro_payment_id',
        //     type: BigInt,
        //     name: 'Идентификатор платежа в АСКОПМ'
        // },
        // {
        //     key: 'get_signature',
        //     type: String,
        //     name: 'Подпись'
        // },
        // {
        //     key: 'id_sources',
        //     type: BigInt,
        //     name: 'Идентификатор источника'
        // },
        // {
        //     key: 'accept',
        //     type: BigInt,
        //     name: 'Признак гашения'
        // },
        // {
        //     key: 'guid_card_holder',
        //     type: String,
        //     name: 'Идентификатор льготника'
        // },
        // {
        //     key: 'id_transaction',
        //     type: String,
        //     name: 'Идентификатор транзакции'
        // },
        // {
        //     key: 'vpbagentid',
        //     type: BigInt,
        //     name: 'Идентификатор агента ВПБ'
        // },
        // {
        //     key: 'vpbuserid',
        //     type: BigInt,
        //     name: 'Идентификатор пользователя ВПБ'
        // },
        // {
        //     key: 'vpbpaymentid',
        //     type: String,
        //     name: 'Идентификатор платежа ВПБ'
        // }
    ],
}
