import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import ElementUI from 'element-ui';
import VueRouter from 'vue-router'
import './styles.scss'
import router from "@/router";
import Store from "@/store/store";
import locale from 'element-ui/lib/locale/lang/ru-RU'
import formatDate from "@/utils/formatDate";
import moment from "moment-timezone";


moment.tz.setDefault("Europe/Moscow");

Vue.use(ElementUI, { locale });
Vue.use(VueRouter)
Vue.use(Vuex);


Vue.filter('customDate', formatDate);

Vue.config.productionTip = false

const store = Store.init().get()

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')

document.title = 'АРМ Управление Контрагентами';
