export default {
    tableName: 'Прочие продажи',
    tableCode: 'extsales',
    indexField: ['id_extsales'],
    // encrypt: true,
    defaultOrder: {
        id_extsales: 'ASC',
    },
    tableDescription: [
        {
            type: BigInt,
            key: 'id_extsales',
            name: 'Идентификатор'
        },
        {
            type: String,
            key: 'name',
            name: 'Имя',
        },
        {
            type: BigInt,
            key: 'type',
            name: 'Тип',
        },
        {
            type: BigInt,
            key: 'subtype',
            name: 'Подтип',
        },
        {
            type: BigInt,
            key: 'price',
            name: 'Цена',
        },
        {
            type: BigInt,
            key: 'department',
            name: 'Отдел'
        },
        {
            type: String,
            key: 'template',
            name: 'Шаблон',
            // default_value: 'nositel',
            // relation: {
            //     table: 'extsalestemplates',
            //     join_rules: {
            //         template: 'template',
            //     },
            //     view: 'template',
            // }
        }
    ]
}
