export const writeToStorage = (store, { table_name, data }) => {
    store.storage[table_name] = data;
}

export const writeCountToStorage = (store, { table_name, count }) => {
    store.count[table_name] = count;
}

export const writeSoftDeleteToStorage = (store, { table_name, softDeleteSupported }) => {
    store.softDeleteSupport[table_name] = softDeleteSupported;
}
