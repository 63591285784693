import {sort} from "@/constants";
import moment from "moment";

export default {
    tableCode: 'system_users',
    tableName: 'Список пользователей',
    folderName: 'users_management',
    indexField: [
        'id'
    ],
    defaultOrder: {
        id: sort.ASC,
    },
    viewField: 'login',
    tableDescription: [
        {
            type: 'Header',
            name: 'Личные данные пользователя',
        },
        {
            type: BigInt,
            name: 'ID',
            key: 'id',
            skip_input: true,
        },
        {
            name: 'Контрагент',
            key: 'counterparty_id',
            type: BigInt,
            relation: {
                table: 'vt_counterparties',
                join_rules: {
                    counterparty_id: 'counterparty_id',
                }
            },
            placeholder_input: 'ОПЕРАТОР СИСТЕМЫ',
            allow_edit: false,
        },
        {
            name: 'Логин',
            key: 'login',
            type: String,
        },
        {
            name: 'ФИО',
            key: 'fio',
            type: String,
        },
        {
            name: 'Управление оборудованием',
            skip_input: true,
            ignore_on_save: true,
            filter_hidden: true,
            sortable: false,
            type: BigInt,
            key: 'device_access_status',
            relation: {
                table: 'devices_access_statuses',
                join_rules: {
                    device_access_status: 'id'
                }
            }
        },
        {
            type: 'Header',
            subtype: 'warning',
            name: '',
            description: 'Оставьте поле пустым чтобы не менять пароль',
            icon: false,
        },
        {
            key: 'password',
            name: 'Пароль',
            type: String,
            password: true,
            filter_hidden: true,
            column_hidden: () => true,
        },
        {
            key: 'last_login',
            name: 'Последний вход',
            skip_input: true,
            type: Date,
            decorator: value => {
                return `decorated:${moment(value).add(3, 'hours').format('YYYY-MM-DD HH:mm:ss')}`
            }
        },
        {
            key: 'registration_date',
            name: 'Дата регистрации',
            skip_input: true,
            type: Date,
            decorator: value => {
                return `decorated:${moment(value).add(3, 'hours').format('YYYY-MM-DD HH:mm:ss')}`
            }
        },
        {
            key: 'role_id',
            name: 'Группа пользователя',
            relation: {
                table: 'system_roles',
                join_rules: {
                    role_id: 'id',
                },
            }
        }
    ]
}
