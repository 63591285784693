import {sort} from "@/constants";

export default {
    tableCode: 'devices_access_dictionary',
    tableName: 'Типы датаграмм',
    viewField: 'name',
    indexField: [
        'id',
    ],
    defaultOrder: {
        id: sort.ASC,
    },
    local: true,
    tableDescription: [
        {
            key: 'id',
            type: BigInt,
        },
        {
            key: 'name',
            type: String,
        }
    ],
    tableContent: [
        { id: 2, name: 'Запрещена' },
        { id: 1, name: 'Разрешена' },
    ]
}
