import {sort} from "@/constants";

export default {
    tableCode: 'routetypes',
    tableName: 'Виды сообщений',
    indexField: [
        'route_type'
    ],
    folderName: 'vpb_control',
    defaultOrder: {
        route_type: sort.ASC,
    },
    viewField: 'description',
    tableDescription: [
        {
            type: BigInt,
            name: 'Код вида маршрута',
            key: 'route_type',
            skip_input: true,
            column_hidden: () => true,
        },
        {
            type: String,
            name: 'Вид маршрута',
            key: 'description',
        },
    ],
}
