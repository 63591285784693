import {sort} from "@/constants";
import {transporttypes_join} from "@/joins";

export default {
    tableCode: 'vt_transfer',
    tableName: 'Пересадки (правила списания ЕЭБ)',
    folderName: 'nsi',
    indexField: [
        'id'
    ],
    defaultOrder:
        {
            id: sort.ASC,
        }
    ,
    viewField: 'description',
    tableDescription: [
        {
            type: BigInt,
            name: 'Уникальный идентификатор пересадки',
            key: 'id',
            // column_hidden: true,
            skip_input: true,
        },
		{
            type: String,
            name: 'Наименование пересадки',
            key: 'description',
        },
        {
            type: BigInt,
            name: 'Категория билета',
            key: 'vt_cardsdata_id',
            relation: {
                table: 'cardcategories',
                join_rules: {
                    vt_cardsdata_id: 'category_id',
                }
            }
        },
        {
            type: Date,
            name: 'Дата добавления пересадки',
            key: 'reg_date_time',
            skip_input: true,
        },
        {
            type: Date,
            name: 'Начало действия',
            key: 'date_begin',
            validator: (date, row) => row.date_end < date,
        },
        {
            type: Date,
            name: 'Окончание действия',
            key: 'date_end',
            validator: (date, row) => row.date_begin > date,
        },
        {
            type: BigInt,
            name: 'Период действия пересадки (мин.)',
            key: 'transfer_interval'
        },
        {
            type: BigInt,
            name: 'Стоимость первой поездки (коп.)',
            key: 'tariff_transfer_1'
        },
        {
            type: BigInt,
            name: 'Стоимость второй поездки (коп.)',
            key: 'tariff_transfer_2'
        },
        {
            type: BigInt,
            name: 'Стоимость третьей и следующих поездок (коп.)',
            key: 'tariff_transfer_3'
        },
        {
            type: BigInt,
            name: 'Время между предъявлениями ВПБ (мин.)',
            key: 'pause',
        },
        // {
        //     type: BigInt,
        //     name: 'Количество одновременно выпускаемых датаграмм',
        //     key: 'datagram_max_count',
        // },
        // {
        //     type: BigInt,
        //     name: 'Общий реурс одновременно выпускаемых датаграмм',
        //     key: 'datagram_max_balance',
        // },
        transporttypes_join('transport_type'),
        {
            type: Array,
            key: 'routes',
            name: 'Маршруты',
            multiple: true,
            relation: {
                table: 'vt_routesdata',
                filter_by: 'transport_type',
                join_rules: {
                    routes: 'id',
                },
            },
            // data_from: {
            //     table: 'arc_routesdata',
            // }
        },
        {
            type: BigInt,
            name: 'Количество разрешенных пересадок',
            key: 'transfer_max_count',
        },
        {
            type: Boolean,
            name: 'Прерывание пересадки метро',
            key: 'metro_interrupt',
        }
    ],
}
