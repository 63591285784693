import {sort} from "@/constants";

export default {
    tableCode: 'protocol_bocot_ext',
    tableName: 'protocol_bocot_ext',
    indexField: [
        'name'
    ],
    defaultOrder:
        {
            name: sort.ASC,
        }
    ,
    tableDescription: [
        {
            type: String,
            name: 'Имя',
            key: 'name',
        },
        {
            type: String,
            name: 'Значение',
            key: 'value',
        },
        {
            type: String,
            name: 'Заметка',
            key: 'note',
        },
    ],
}
