import {sort} from "@/constants";

export default {
    tableCode: 'mutualpaymentstypes',
    folderName: 'vpb_control',
    tableName: 'Виды взаиморасчетов',
    indexField: [
        'id'
    ],
    defaultOrder: {
            id: sort.ASC,
    },
    viewField: 'description',
    tableDescription: [
        {
            type: BigInt,
            name: 'Идентификатор вида взаиморасчетов',
            key: 'id',
            // column_hidden: true,
            skip_input: true,
        },
        {
            type: String,
            name: 'Описание вида взаиморасчетов',
            key: 'description',
        }, {
            type: String,
            name: 'Примечание',
            key: 'note',
        },
    ],
}
