import {sort} from "@/constants";

export default {
    tableCode: 'vt_datagrammstatus',
    tableName: 'Статусы датаграмм',
    viewField: 'statustext',
    indexField: ['statuscode'],
    folderName: 'vpb_control',
    defaultOrder: {
        statuscode: sort.ASC,
    },
    tableDescription: [
        {
            key: 'statuscode',
            type: BigInt,
            name: 'Статус'
        },
        {
            key: 'statustext',
            type: String,
            name: 'Описание'
        }
    ]
}
