import {sort} from "@/constants";

export default {
    tableCode: 'vt_signatory_l',
    tableName: 'Лица, подписывающие отчеты',
    defaultOrder: {
        id: sort.ASC,
    },
    folderName: 'reports',
    indexField: [
        'id'
    ],
    viewField: 'name',
    tableDescription: [
        {
            key: 'id',
            name: 'ID',
            type: BigInt,
            skip_input: true,
            column_hidden: () => true,
        },
        {
            key: 'name',
            name: 'Инициалы, фамилия',
            type: String,
        },
        {
            key: 'job_title',
            name: 'Должность',
            type: String,
        },
        {
            key: 'phone_number',
            type: String,
            name: 'Номер телефона'
        },
        {
            key: 'note',
            type: String,
            name: 'Примечание'
        }
    ]

}
